/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Ref = {
    type: Ref.type;
    id: string;
};

export namespace Ref {

    export enum type {
        REF = 'ref',
    }


}

