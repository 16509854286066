/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Periodicity } from './Periodicity';
import type { RecurType } from './RecurType';

export type RecurDetails = {
    detail_type?: RecurDetails.detail_type;
    periodicity: Periodicity;
    recur_type: RecurType;
    payable_unit_id: string;
    payable_notional: number;
    coupon_percent?: number;
};

export namespace RecurDetails {

    export enum detail_type {
        RECR = 'RECR',
    }


}

