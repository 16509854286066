/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AddTransactionRequestFragment = {
    description: `Represntation of the simpler parts of a transaction
    these tend to just be copied over`,
    properties: {
        name: {
            type: 'string',
        },
        notes: {
            type: 'string',
        },
    },
} as const;
