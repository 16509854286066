import {Vue,Prop, Component}  from "nuxt-property-decorator"
import {CreateElement,VNode} from "vue"

@Component({})
export default class DetailItem extends Vue {
    @Prop(String) readonly label!:string
    render(h:CreateElement):VNode{
        return h("div",{ "class":["text-no-wrap","overflow-x-hidden",
        "text-truncate"]},
                 [h("strong",{},[this.label, " : "]),
                 ...(this.$slots.default||[])]
        )
    }
}
