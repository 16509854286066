/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AmortizationParameters } from './AmortizationParameters';
import type { InterestPaymentConvention } from './InterestPaymentConvention';
import type { Periodicity } from './Periodicity';

export type MortgageDetails = {
    detail_type?: MortgageDetails.detail_type;
    periodicity: Periodicity;
    payment_convention: InterestPaymentConvention;
    fixed_interest_rate: number;
    payable_notional?: number;
    interest_only?: boolean;
    payable_unit_id?: string;
    collateral_id?: string;
    mortgage_tax?: number;
    appraisal_fee?: number;
    credit_report_fee?: number;
    amort_schedule?: AmortizationParameters;
};

export namespace MortgageDetails {

    export enum detail_type {
        CBND = 'CBND',
    }


}

