/**
 * Rendering logic for various event types
 *
 *
 */
import {Events} from "~/models/graphql"
import {EventType} from "~/schemas/gen"
import gql from "graphql-tag"
import {CARET} from "../common/render"
import {lineItemActionToString,directionToString,typeToString} from "../transactions/render"
import {EVENT_TYPE_CHOICES} from "~/schemas/event_schemas"


export const fragCapitalCommitmentRenderable  = gql`
   fragment  RenderableCapitalCommitment  on events{
       parent_event {
           uuid
           new_transaction{
               what { uuid display_name }
           }
       }
       capital_call {
           due_date amount
           lp_entity {
               name uuid
           }
       }
   }
`;

export const fragNewTransactionRenderable = gql`
fragment RenderableNewTransaction on  events {
    new_transaction{
        price direction units
        what {
            uuid instrument_type display_name
        }
    }
}
`


export function newTransactionSegments(tgt:Partial<Events>):any[]{
    let nt = tgt.new_transaction
    let instrument = tgt.new_transaction?.what;
    let segments:(string|null|undefined)[] = []
    if(instrument) segments.push(instrument.display_name)
    if(nt) segments.push(directionToString( nt.direction))
    if(nt) segments.push(nt.units)
    if(instrument){
        segments.push(typeToString(instrument.instrument_type))
    }
    return filterSegments(segments)
}
   

/**
 * Segmentation function for capital commitments 
 */
export function capitalCommitmentSegments(e:Partial<Events>):any[]{
    let nt = e.parent_event?.new_transaction;
    let cc = e.capital_call
    let segments:(string|null|undefined)[] = [nt?.what?.display_name]
    if(cc){
        segments.push(cc.amount,cc.due_date,cc.lp_entity?.name)
    }
    return filterSegments(segments)
}

//REmove Null or undefined segments from sequence 
function filterSegments(segments:any[]):any[]{
    return segments.filter(x => x!== null && typeof x != "undefined")
}

/**
 * Produce ancilary information for event object automatically.
 */
export function autoSegmenter(x:Partial<Events>):any[]{
    switch(x.event_type){
        case EventType.CAPITAL_CALL:
            return capitalCommitmentSegments(x)
        case EventType.NEW_TRANSACTION:
            return newTransactionSegments(x)
        default:
            return []
    }
}

const EVENT_TYPES = Object.fromEntries(EVENT_TYPE_CHOICES.map(x => [
   x.const, x.title
]))

export function eventTypeToString(x:EventType):string {
    return EVENT_TYPES[x] ?? "-"
}
 
