
import {Vue,Prop, Component,Getter,Emit,Watch ,namespace,ModelSync} from "nuxt-property-decorator"
import {ApiEntityNode,fragApiEntityFields} from "~/core/entity"
import {ApiAccountNode,fragApiAccountFields} from "~/components/input/Account.vue"
import {ApiTransactionNode,fragApiTransactionFields} from "~/components/input/Transaction.vue"
import {ApiInstrumentNode,fragApiInstrumentFields} from "~/components/input/Instrument.vue"
import {transactionSearchExp} from "~/components/transaction"
import {
    transactionProspectSearchExp,fragApiTransactionProspectFields,
    ApiTransactionProspectNode
} from "~/components/prospect"
import {ApiObservableDataNode,fragApiObservableDataFields } from "~/core/observable"
import {entitySearchExp } from "~/components/entity"
import {accountSearchExp} from "~/core/account"
import {instrumentSearchExp} from "~/components/instrument"
import {describeEvent} from "~/components/input/Event.vue"
import {eventSearchExp,ApiEventNode,fragApiEventNodeFields} from "~/core/events"
import { fragApiWorkflowFields,ApiWorkflowNode} from "~/core/workflows"
import {describeTransaction,describeTransactionProspect}  from "~/components/transaction/render"
import { ApiTransaction,InputApiDocument ,
    ApiDocumentLink,InputApiDocumentLink,
    ApiDocumentLinkUpdates,
    ApiTransactionProspect,
    ApiEvent,
    ApiInstrument
} from "~/schemas/gen"
import { DataTableHeader,DataOptions } from "vuetify/types"
import TreeNode,{GenericTreeState} from "~/components/grid/TreeNode"
import gql from 'graphql-tag';
import {VTextField} from "vuetify/lib"
import {absoluteDateOnly} from "~/utils/dates"






//How long to wait before hiding dropdown
const HIDE_DELAY = 400;
export type candidate_type =
"entities"|"accounts"|"transactions"| "transaction_prospects"|"events"|"instruments"|"observable_data"|"workflows"

export interface IApiLinkTarget{
    entity?:ApiEntityNode &  { __typename:candidate_type}
    account?:ApiAccountNode & { __typename:candidate_type}
    transaction?:ApiTransactionNode & { __typename:candidate_type}
    prospect?:ApiTransactionProspectNode & { __typename:candidate_type }
    event?:ApiEventNode & { __typename:candidate_type }
    instrument?:ApiInstrumentNode  & { __typename:candidate_type }
    observable?:ApiObservableDataNode & { __typename:candidate_type }
    workflow?:ApiWorkflowNode & { __typename:candidate_type }

}
export interface ApiDocumentLinkNode  extends IApiLinkTarget{
    uuid:string
    document?:any
}


export type link_target_type= keyof IApiLinkTarget;
export type candidate_child_type = (ApiEntityNode|ApiAccountNode
                                    |ApiTransactionNode|ApiTransactionProspectNode
                                    |ApiEventNode| ApiInstrumentNode
                                    | ApiObservableDataNode|ApiWorkflowNode) & {
    __typename:candidate_type
}
export const LINK_TARGET_FIELDS:link_target_type[] =  ["entity","account","transaction","prospect",
    "event","instrument","observable","workflow"]  ;

export interface CandidateNode extends IApiLinkTarget {
    __typename: candidate_type
} 

// Test type of relationship object for Candidate node 
// The Union is needed to make the test typescript safe 
export function isEntity(c:CandidateNode|ApiEntityNode):c is ApiEntityNode {
    return (c as any).__typename == "entities"
}
export function isTransaction(c:CandidateNode|ApiTransactionNode):c is ApiTransactionNode{
    return (c as any).__typename == "transactions"
}
export function isAccount(c:CandidateNode|ApiAccountNode):c is  ApiAccountNode {
    return (c as any).__typename == "accounts"
}

export function isProspect(p:CandidateNode|ApiTransactionProspectNode):p is ApiTransactionProspectNode{
    return (p as any).__typename == "transaction_prospect"
}

export function isEvent(p:CandidateNode|ApiEventNode):p is ApiEventNode {
    return (p as any).__typename == "events"
}

export function isInstrument(p:CandidateNode|ApiInstrumentNode):p is  ApiInstrumentNode{
    return (p as any).__typename == "instruments"
}

export function isObservables(p:CandidateNode|ApiObservableDataNode):p is ApiObservableDataNode {
    return (p as any).__typename == "observable_data"
}

export function isWorkflow(p:CandidateNode|ApiWorkflowNode):p is  ApiWorkflowNode{
    return (p as any).__typename == "workflows"
}





const candidateQuery =gql`
query candidates($entityQ:entities_bool_exp!,
$accountQ:accounts_bool_exp!,
$transactionQ:transactions_bool_exp!,
$prospectQ:transaction_prospects_bool_exp!,
$eventQ:events_bool_exp!,
$instrumentQ:instruments_bool_exp!){
   entities(where:$entityQ,limit:10){
    ...ApiEntityFields
   }
   accounts(where:$accountQ,limit:10){
   ...ApiAccountFields
   }
   transactions(where:$transactionQ,limit:10){
   ...ApiTransactionFields
   }
   transaction_prospects(where:$prospectQ,limit:10){
   ...ApiTransactionProspectFields
   }
    events(where:$eventQ,limit:10){
        ...ApiEventNodeFields
    }
    instruments(where:$instrumentQ,limit:10){
        ...ApiInstrumentFields
    }
    observables:observable_data(limit:10){
    ...ApiObservableFields
    }
    workflows(limit:10){
    ...ApiWorkflowFields
    }
}
${fragApiAccountFields}
${fragApiEntityFields}
${fragApiTransactionFields}
${fragApiTransactionProspectFields}
${fragApiEventNodeFields}
${fragApiInstrumentFields}
${fragApiObservableDataFields}
${fragApiWorkflowFields}
`
interface CandidateQueryResult {
    entities?: ApiEntityNode[]
    accounts?: ApiAccountNode[]
    transactions?: ApiTransactionNode[]
    transaction_prospects?: ApiTransactionProspectNode[]
    events?: ApiEventNode[]
    instruments?:ApiInstrumentNode[]
    observables?:ApiObservableDataNode[]
    workflows?:ApiWorkflowNode[]
} 
//Mapping of relation type yo collection
const CANDIDATE_TYPE2COL:Record<keyof IApiLinkTarget,keyof CandidateQueryResult> =   {
"entity": "entities",
    "account": "accounts",
    transaction: "transactions",
    prospect: "transaction_prospects",
    "event": "events",
    "instrument":"instruments",
    "observable":"observables",
    "workflow":"workflows"
}

const CANDIDATE_COL2TYPE = Object.fromEntries(Object.entries(CANDIDATE_TYPE2COL)
                                               .map(([k,v]) => [v,k]))

type ChangeType = "add"|"del"

export interface ChangeNode {
    action:ChangeType // type of change 
    node?:ApiDocumentLinkNode // Node representing the original item
    candidate?:CandidateNode // Node representing the candidate item
    attributes?:Record<string,any> //Attributes of the relationship 
}
export interface ApiLinkEntry extends ApiDocumentLinkNode {
    change?:ChangeNode
}


function makeCandidate(collection:keyof CandidateQueryResult,node:candidate_child_type):CandidateNode {
    return {[CANDIDATE_COL2TYPE[collection]]:node,__typename:node.__typename}
}

//Given the graphql response make a single list of candidate nodes 
function mergeCandidates(data:any,):CandidateNode[] {
    if(!data) return []
    let items:CandidateNode[] = []

    for(let [key,listKey] of Object.entries(CANDIDATE_TYPE2COL)){
        let nodes = data[listKey];

        if(nodes && nodes.length > 0 ){
            for(let node of nodes){
                items.push(makeCandidate(listKey,node))
            }
        }
    }
    return items
}


export function   candidateChild(x:IApiLinkTarget):candidate_child_type{
    if(x.entity) return x.entity!;
    if(x.account) return x.account!;
    if(x.transaction) return x.transaction!;
    if(x.prospect) return x.prospect!;
    if(x.event) return x.event!;
    if(x.instrument) return x.instrument!;
    if(x.observable) return x.observable!;
    if(x.workflow) return x.workflow!;
    throw new Error("Invalid Candidate Child")
}

//Given a Link target, produce  generic showable name
export function candidateName(x:IApiLinkTarget,typename?:string):string{
    switch(typename ?? (x as any).__typename){
        case "entities":
            return x.entity!.name!
        case "accounts":
            {
                let parts = [   x.account!.name]
                if(x.account?.acct_number){
                    parts.push(x.account?.acct_number)
                }
                return parts.join(" | ")
            }
        case "transaction_prospects":
            return describeTransactionProspect(x.prospect!)
        case "transactions": 
            //Todo escape hatch for describe
            return describeTransaction( x.transaction as any)
        case "events":
            return describeEvent(x.event as any)
        case "instruments":
            return x.instrument?.display_name!
        case "observable_data":
            {
                let dt =absoluteDateOnly(x.observable!.instant!)
                return [x.observable?.type?.name, x.observable?.identifier! + " @ " + dt].join(" | ")
            }
        case "workflows":
            return [x.workflow?.type?.name,x.workflow?.document?.name].join(" | ")
    }
    return "???"
}
//GIven a link target pick and id value (usuually UUID of the target object)
export function candidateId(x:IApiLinkTarget):string {
    for(let sub of LINK_TARGET_FIELDS){
        let item = x[sub] as any
        if(item) {
            switch(sub){
                case "observable":
                    return item.id
                case "workflow":
                    return item.ulid
                default:
                return item.uuid;
            }
        }
    }
    throw new Error("Invalid Link Target (no valid child)")
}

/// Produce the tupe of an api candidate as a string
export function candidateType(x:CandidateNode):string {
    return candidateTypeToString(x.__typename)
}
export function candidateTypeToString(x:CandidateNode["__typename"]):string {
    switch(x){
        case "entities": return "Entity"
        case "accounts": return "Account"
        case "transactions": return "Transaction"
        case "transaction_prospects": return "Transaction Prospect"
        case "events":  return "Event"
        case "instruments": return "Instrument"
        case "observable_data": return "Observable"
        case "workflows": return "Workflow"
        default: return "???"
    }

}

export function linkableItemRoute(item:CandidateNode, $router:Vue['$router']):string|undefined {
    let routePrefix:string;
    switch(item.__typename){
        case "transactions":
            routePrefix = "transaction-id-view";
            break;
        case "accounts":
            routePrefix = "accounts-id-edit";
            break;
        case "entities":
            routePrefix = "entities-id-edit";
            break;
        case "instruments":
            routePrefix = "instruments-id";
            break;
        case "events":
            routePrefix = "event-id"
            break;
        case "workflows":
            routePrefix = "workflows-id"
            break;
        default:
            return;

    }
    let child:any  = candidateChild(item)
    let id = child.uuid ?? child.ulid 
    let url = $router.resolve({ name: routePrefix , params:{id}})
    return url.href
}

const userNs = namespace("user")


/**
 * Panel Renders and input field that link candidates can be selected from
 */

@Component({
           apollo:{
               rawCandidates:{
                   debounce:200,
                       query:candidateQuery,
                       variables(){
                           return this.queryVariables
                       },
                       skip(){
                           let toSkip= !this.search || this.search.length < 2
                           if(toSkip){
                               this.rawCandidates= {}
                           }
                           return toSkip
                       },
                       update(data){
                           this.resetPagination()
                           return data
                       }
               },
           },
           filters:{
               candidateName,candidateType,candidateTypeToString
           },
           components:{
               TreeNode
           }
})
export default class DocumentLinkInput extends Vue {
    @userNs.Getter("systemUserId") readonly systemUserId!:number
    @ModelSync('selected','change') readonly candidate!:CandidateNode
    @Prop({default:false}) readonly up!:boolean
    @Prop({default:400}) readonly maxHeight!:number 
    // Selected candidate nodes
    rawCandidates:CandidateQueryResult = {}
    expanded:(keyof CandidateQueryResult)[] = []
    search:string = "" 
    mousein:boolean = false
    menuin:boolean = false
    showDropdown:boolean =true
    focused:boolean = false
    page:number = 1
    perPage:number = 5 
    hideTimestamp:number|null = null
    inputNodeId:string = "document-link-input-" + Math.floor((Math.random()*100000));
    $refs!: {
        input:Vue
    }

    @Emit("candidate-click")
    onCandidateClick(){
    }


    get styling():Record<string,any> {
        return {
            maxHeight:this.maxHeight + "px"
        }
    }

    CANDIDATE_COLUMNS:DataTableHeader[] = [
        { text:"",value:"expand",width:32,sortable:false},
        { text:"Type", value:"type",width:100,sortable:false,
            cellClass:["text-no-wrap"]},
        { text:"Name", value:"name"},
        { text:"",value:"actions",align:"end",sortable:false}
    ];

    get queryVariables(){
        return {
            entityQ: entitySearchExp(this.search),
            accountQ: accountSearchExp(this.search,this.systemUserId),
            transactionQ: transactionSearchExp(this.search),
            prospectQ:transactionProspectSearchExp(this.search),
            eventQ:eventSearchExp(this.search),
            instrumentQ:instrumentSearchExp(this.search,{systemUserId:this.systemUserId,includeSystem:false})
        }
    }
    get maxPages():number{
        return  Math.ceil(this.candidates.length / this.perPage)
    }
    get hasMore(){
        return this.page < this.maxPages;
    }
    get candidates():CandidateNode[]{
        return mergeCandidates(this.rawCandidates);
    }


    get popupOpen(): boolean {
        return (this.candidates.length >0 && this.showDropdown);
    }
    set popupOpen(val:boolean){
        if(!this.menuin) {
            this.showDropdown = val
        }
    }
    show(){
        this.hideTimestamp=0
        this.showDropdown = true
    }
    @Watch("focused")
    onFocusChange(newVal:boolean){
        //Whenw e have focus the dropdown stays
        if(newVal) {
            this.show()
        }

    }
    @Watch("mousein")
    onMouseIn(newVal:boolean){
        //When th emouse comes in, if we  are visible we stay visible
        if(newVal && this.showDropdown) {
            this.show();
        }
    }
    
    resetPagination(){
        this.page =1;
    }
    clearHide(){
        this.hideTimestamp = 0;
    }
    //Check to see if we have to hide the popup
    async checkHide(){
        let {focused,mousein,menuin} =this
        if(focused || mousein || menuin) return
        //Set timeout 
        let ht = this.hideTimestamp = new Date().getTime()
        await new Promise(resolve => window.setTimeout(resolve,HIDE_DELAY))
        if(this.hideTimestamp != ht) return
        console.log("Clear Dropdown")
        this.showDropdown=false
    }
    async delayLeave(){
        this.mousein = false 
        this.checkHide()
    }
    async delayBlur(){
        this.focused = false
        this.checkHide();
    }
    async delayMenuLeave(){
        this.menuin = false;
        this.checkHide()
    }
}
