/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiWorkflowInputField = {
    properties: {
        name: {
            type: 'string',
            isRequired: true,
        },
        identifier: {
            type: 'string',
            isRequired: true,
        },
        field_type: {
            type: 'string',
            isRequired: true,
        },
        is_nullable: {
            type: 'boolean',
            isRequired: true,
        },
    },
} as const;
