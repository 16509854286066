
import { TypePolicy,TypePolicies } from "@apollo/client/cache";
import {offsetLimitPagination} from "@apollo/client/utilities";



export const SchemaPolicy:TypePolicies = {
    entities:{
        keyFields:["uuid"]
    },
    instruments:{
        keyFields:["uuid"]
    },
    events:{
        keyFields:["uuid"]
    },
    documents:{
        keyFields:["uuid"]
    },
    accounts:{
        keyFields:["uuid"]
    },
    transactions:{
        keyFields:["uuid"]
    },
    user_sourced_prices:{
        keyFields:["uuid"]
    },
    advisor_notes:{
        keyFields:["uuid"]
    },
    document_mimes:{
        keyFields:["mime"]
    },
    entity_documents:{
        keyFields:["uuid"]
    },
    entity_document_links:{
        keyFields:["uuid"]
    },observable_data:{
        keyFields:["ulid"],
    },
    workflows: {
        keyFields:["ulid"]
    },
    transaction_prospects:{
      keyFields:["uuid"]
    },
    Query:{
        fields:{
            observable_data:{
                keyArgs:["where","order_by"],
                merge(existing, incoming,opts ) {
                    let offset = opts?.args?.offset ?? 0
                    // Slicing is necessary because the existing data is
                    // immutable, and frozen in development.
                    const merged = existing ? existing.slice(0) : [];
                    if(merged.length == 0 && offset!=0) debugger
                    for (let i = 0; i < incoming.length; ++i) {
                        merged[offset + i] = incoming[i];
                    }
                    //console.log("Merged ->",offset,existing,incoming,merged)
                    return merged;
                }
            }
        }
    }
}
