/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiWorkflow = {
    properties: {
        ulid: {
            type: 'string',
            isRequired: true,
            pattern: '[0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26}',
        },
        updated_time: {
            type: 'string',
            isRequired: true,
            format: 'date-time',
        },
        creator_id: {
            type: 'string',
            isRequired: true,
            format: 'uuid',
        },
        universe_id: {
            type: 'string',
            isRequired: true,
            format: 'uuid',
        },
        status: {
            type: 'number',
            isRequired: true,
        },
        flags: {
            type: 'number',
        },
        rel_account_id: {
            type: 'string',
            format: 'uuid',
        },
        document_id: {
            type: 'string',
            format: 'uuid',
        },
        workflow_type: {
            type: 'ApiWorkflowType',
            isRequired: true,
        },
        workflow_type_id: {
            type: 'string',
            isRequired: true,
        },
        heldaway: {
            type: 'HeldAwayReconciliation',
        },
        custom_etl: {
            type: 'CustomEtlInstance',
        },
        acl: {
            type: 'ApiPermissions',
        },
    },
} as const;
