/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum AccessGrantRole {
    ADVISOR = 1,
}
