/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiDocFragmentOrigin = {
    properties: {
        pages: {
            type: 'any-of',
            contains: [{
                type: 'number',
            }, {
                type: 'array',
                contains: {
                    type: 'number',
                },
            }],
            isRequired: true,
        },
        bounding: {
            type: 'array',
            contains: {
                type: 'PagedBoundingBox',
            },
        },
    },
} as const;
