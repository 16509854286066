/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Periodicity } from './Periodicity';

export type RealEstateDetails = {
    real_estate_tax: number;
    real_estate_tax_frequency: Periodicity;
    detail_type?: RealEstateDetails.detail_type;
    entry_brokerage_fee?: number;
    exit_brokerage_fee?: number;
    other_fees?: number;
    association_fees?: number;
    bedrooms?: number;
    bathrooms?: number;
    interior_sq_ft?: number;
    exterior_sq_ft?: number;
    postal_code?: string;
    settlement_unit_id?: string;
};

export namespace RealEstateDetails {

    export enum detail_type {
        REAL = 'REAL',
    }


}

