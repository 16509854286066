/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ExtractedAnswer = {
    properties: {
        id: {
            type: 'string',
            isRequired: true,
        },
        score: {
            type: 'number',
            isRequired: true,
        },
        value: {
            type: 'any-of',
            contains: [{
                type: 'string',
            }],
            isRequired: true,
        },
        page: {
            type: 'number',
            isRequired: true,
        },
        bbox: {
            type: 'array',
            contains: {
                properties: {
                },
            },
        },
    },
} as const;
