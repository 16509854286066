/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $TotpDeviceModel = {
    properties: {
        key: {
            type: 'string',
            isRequired: true,
        },
        key_b32: {
            type: 'string',
            isRequired: true,
        },
        step: {
            type: 'number',
            isRequired: true,
        },
        t0: {
            type: 'number',
            isRequired: true,
        },
        digits: {
            type: 'number',
            isRequired: true,
        },
        config_url: {
            type: 'string',
            isRequired: true,
        },
    },
} as const;
