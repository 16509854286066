import {PartialQuery} from "../common/input"
import {fragApiEntityFields,ApiEntityNode} from "../entity"
import { fragApiAccountFields,ApiAccountNode, 
    AccountSearchQuery,accountSearchExp } from "~/core/account"
import {User_Access_Grants, User_Access_Grants_Bool_Exp} from "~/models/graphql"
import gql from "graphql-tag"
export const ADVISOR_CLIENT_FIELD = "universe.profile.entity.uuid"
import { merge } from "lodash"
import {Store} from "vuex/types/index"
import {useUser} from "../auth"


export const UserAccountsQuery = gql`query user_accounts($offset:Int!,$limit:Int!,
    $orderBy:[user_access_grants_order_by!],$query:user_access_grants_bool_exp!) {
    grants:user_access_grants(limit:$limit,offset:$offset,
    where:$query,order_by:$orderBy){
        client_identifier
        user {
            email 
            profile {
                uuid
                entity { uuid ...ApiEntityInputFields} 
            }
        }
    }
}
${fragApiEntityFields}
`


export const CLIENT_ID_UNIVERSE = "*universe*"

export type AdvisorEntityNode = ApiEntityNode & {
    client_identifier?:string
}


export const advisorClientsSource:PartialQuery = {
    get query(){
        return UserAccountsQuery
    },
    variables:function (search:string){
        let query:User_Access_Grants_Bool_Exp= {}
        if(search && search.length > 0){
            search= search + "%";
            query = { 
                user:  { 
                    _or:[
                        {profile :{ entity :{legal_name:{_ilike:search } }  } },
                        {first_name: {_ilike:search}},
                        {last_name: {_ilike:search}},
                        {email: {_ilike:search}},
                    ]
                }
            }
        }
        return { offset:0,limit:50 ,
            query
        }
    },
    update:data =>  data.grants.map( (x:User_Access_Grants) => {
        return merge({ client_identifier: x.client_identifier},x.user!.profile!.entity )
    }),
        clientId:"root"
}


export const advisorClientAccountSource:(uuid:string,systemUserId:number) => PartialQuery
= (clientUuid:string,systemUserId:number) => {
    return { 
        get query(){
            return AccountSearchQuery
        },
        variables:function (search:string){
            let query:any = {}
            if(search && search.length > 0){
                query = accountSearchExp(search,systemUserId,clientUuid)
            }else {
                query = {
                    user:{ profile: {uuid :{_eq:clientUuid } } } 
                }
            }
            return { offset:0,limit:50 ,
               conditions :query
            }
        },
        update: (data:any) =>  data.accounts,
            clientId:"root"
    }
}

export function renderLabel(e:AdvisorEntityNode|ApiEntityNode){
    const e2=e as AdvisorEntityNode
    let parts = [ e.legal_name ?? "???"]
    if(e2.client_identifier) parts.push(e2.client_identifier)
    return parts.join(" \uFE31 ")
}

type WithClientId = {
    client_identifier?:string
}

/**
 * Optional include client identifier in a request
 */
export function maybeIncludeClientId<T extends WithClientId>(model:T,$store:Store<any>):T{
        const {isAdviser} =useUser($store)
        if(!model!.client_identifier && isAdviser){
            if(!$store.$universe.isDistinct){
                model = {...model,
                    client_identifier:CLIENT_ID_UNIVERSE
                } as T
            }
        }
        return model
}
