/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum WorkflowStatus {
    STARTED = 0,
    EXPORTABLE = 1,
    GENERATED = 2,
    CONFIRMED = 3,
}
