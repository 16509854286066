/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiWorkflowInputSchema = {
    properties: {
        identifier: {
            type: 'string',
        },
        ref: {
            type: 'string',
        },
        display_name: {
            type: 'string',
        },
        fields: {
            type: 'array',
            contains: {
                type: 'ApiWorkflowInputField',
            },
            isRequired: true,
        },
        schema_data: {
            properties: {
            },
        },
    },
} as const;
