/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ExchangeRateEntry = {
    properties: {
        counter: {
            type: 'any-of',
            contains: [{
                type: 'ApiInstrument',
            }, {
                type: 'string',
                pattern: 'r#[0-9]+',
            }],
            isRequired: true,
        },
        open_rate: {
            type: 'number',
            isRequired: true,
        },
        live_rate: {
            type: 'number',
            isRequired: true,
        },
    },
} as const;
