
import {AccountRef,ApiAccount,AccountRefType ,AcctType,UniverseInfo} from "~/schemas/gen"
import {Accounts_Bool_Exp } from "~/models/graphql"
import {useUniverse} from "~/plugins/auth.universe"
import type Router from "vue-router"
import { ApiEntityNode } from "../entity"
import gql from "graphql-tag"

export type ApiAccountNode =Partial<ApiAccount> & {
    name:string
    uuid:string
    entity?:ApiEntityNode
    owner?:ApiEntityNode
    id_only?:boolean
}



export const DEFAULT_ACCOUNT_REF:AccountRef =  {
    id:"@default",
    type:AccountRefType.ID
}

export const DEFAULT_ACCOUNT:Partial<ApiAccount> =  {
    uuid:"@default",
    name:"Default Account"
}

export const DEFAULT_ACCT_TYPE  = AcctType.ASSET_MANAGEMENT;


export function renderAccount(item:Pick<ApiAccount,"name"|"acct_number">):string {
    let name = item.name;
    if(item.acct_number && name != item.acct_number){
        name = name + "\uFE31" + item.acct_number
    }

    /*
    if(isApiAccountNode(item)){
        name = item.entity?.name  + " \u25B8 " + name;
    }*/
    return name
}


const fragApiEntityFields = gql`
fragment ApiEntityFields on entities {
name legal_name uuid
}
`

export const fragApiAccountFields = gql`
fragment ApiAccountFields on accounts {
    name  uuid acct_type acct_number
    user_defined
    owner  { ...ApiEntityFields is_system_entity }
    entity { ...ApiEntityFields is_system_entity } 
}
${fragApiEntityFields}
`

export const AccountSearchQuery = gql`
query searchAccounts($conditions:accounts_bool_exp!,
                     $offset:Int!,$limit:Int!){
    accounts( where:$conditions,
             limit:$limit,offset:$offset){
        ...ApiAccountFields
    }
}
${fragApiAccountFields}
`

/// Perform search of accounts appcet the uiver optiaonl
export function accountSearchExp(search:string,systemUserId:any,
    universe?:UniverseInfo|string):Accounts_Bool_Exp {
        let   clauses:any[] = [{
            user_id:{ _neq:systemUserId }
        }];
        let universeId:string|false = false
        if(!universe){
            let $universe = useUniverse();
            const univ = $universe.current
            universeId = univ?.user?.profile?.uuid ?? false
        }else if(typeof universe == "string"){
            universeId = universe
        }else {
            universeId = universe?.user?.profile?.uuid ?? false
        }
        if(universeId){
            clauses.push({ user: { profile: {uuid:{_eq: universeId}}}})
        }

        if(search != ""){
            clauses.push({ _or:[
                { name: { _ilike:"%" + search+"%" }},
                { acct_number: { _ilike:"%" + search+"%" }},
                { entity: { name: { _ilike:"%" + search+"%" }}},
                { entity: { legal_name: { _ilike:"%" + search+"%" }}},
                {  owner:{ name: { _ilike:"%" + search+"%" }}},
                {  owner:{ legal_name: { _ilike:"%" + search+"%" }}},
            ]})
        } 
        return { _and:clauses}
    }



export function popupCreateAccount($router:Router):Promise<ApiAccount>{
    let instrument_type:string|null = null;
    let lnk= $router.resolve({
        name:"accounts-create",query:{
            popup:"true",
        }
    })
    let proxy=window.open(lnk.href,"create-popup");
    if(proxy){
        return new Promise((resolve,reject) => {
            const listener =(e:CustomEvent) => {
                resolve(e.detail["account"] as ApiAccount)
            }
            proxy!.addEventListener("account-created" as any ,listener)
        })
    }
    throw new Error("Error opening window")
}
