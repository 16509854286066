
import {
    Transaction_Legs,Transactions,
    Instruments,
    Transaction_Line_Items
} from "~/models/graphql"
import {InstrumentType} from "~/schemas/gen"

// Convert direction enum to string
export function directionToString(x:string){
    switch(x){
        case "P" : return "Pay"
        case "R" : return "Receive"
        case "B" : return "Buy"
        case "S" : return "Sell"
    }
}

// Convert direction enum to string
export function lineItemActionToString(x:string){
    switch(x){
        case "P" :  return "Sent"
        case "R" : return "Received"
        case "BROW":return "Borrow"
        case "S": return "Sold"
        case "T" : return "Transfer"
        case "B": return "Bought"
        default: return "-"
    }
}
// Convert direction enum to string
export function shortLineItemActionToString(x:string){
    switch(x){
        case "P" :  return "SENT"
        case "R" : return "RCVD"
        case "BROW":return "BRRW"
        case "S": return "SOLD"
        case "T" : return "XFR"
        case "B": return "BUY"
        default: return "-"
    }
}

const InstrumentTypeMap:Record<string,string>  = {
    CBND:"Mortgage" ,
    EQTY:"Stock / Ownership Interest",
    RECR:"Recurring Payment",
    REAL:"Real Estate",
    CPTO:"Crypto-Currency",
    [InstrumentType.SAFE]:"Standard Agreement for Future Equity",
    BOND:"Fixed Income / Bond",
    CAPC:"Capital Commitment",
    [InstrumentType.PART_UNIT]:"Partnership Unit",
    [InstrumentType.CURRENCY]:"Currency"
}


const InstrumentPluralTypeMap:Record<string,string>  = {
    CBND:"Mortgages" ,
    EQTY:"Equities & Partnerships",
    RECR:"Payment Agreements",
    REAL:"Real Estate",
    CPTO:"Crypto-Currencies",
    [InstrumentType.SAFE]:"Convertibles",
    BOND:"Fixed Income",
    CAPC:"Capital Commitments",
    [InstrumentType.PART_UNIT]:"Partnership Unit",
    [InstrumentType.CURRENCY]:"Currencies"
}

interface ColorScheme {
    bg:string
    border:string
}

const InstrumentChartingColor:Record<string,ColorScheme> = {
    CBND:{bg:"#598f35",border:"#508130"},   
    EQTY:{bg:"#58a35c",border:"#4F9353"},   
    RECR:{bg:"#5ab582",border:"#4CA874"},   
    REAL:{bg:"#64c7a6",border:"#4EBF99"},   
    CPTO:{bg:"#78d9c7",border:"#5ED2BC"},   
    [InstrumentType.SAFE]:{bg:"#93e9e5",border:"#73E3DD"},   
    BOND:{bg:"#b3f9ff",border:"#88F6FF"},   
    CAPC:{bg:"#55dffe",border:"#33D9FE"},   
    [InstrumentType.PART_UNIT]:{bg:"#00c3ff",border:"#00B0E6"},   
    [InstrumentType.CURRENCY]:{bg:"#00a4ff",border:"#0094E6"}  
}                                

                                 


export function typeToString(x:string|InstrumentType,plural:boolean=false){
    let mp= plural?InstrumentPluralTypeMap:InstrumentTypeMap
    return mp[x] || x
}

const DefaultColorScheme = {bg:"#0d14de" ,border:'#0C12C8'}

export function typeToColor(x:string):ColorScheme {
    return InstrumentChartingColor[x] ?? DefaultColorScheme
}

export function stockDesignationToString(x:string){
    switch(x){
        case "L": return "Liability"
        case "A": return "Asset"
        case "c": return "Capital"
        case "C": return "Capital"
        case "T":
        case "t": return "Total"
        case "D": return "Dynamic"
    }
}
export function accountDesignationToString(x:string):string|undefined{
    switch(x){
        case "B":return  "Beneficial";
        case "C": return  "Custodial"
        case "CM": return "Custodial Mirror"
        case "I":return "Issued"
        case "Z": return "Any"
    }
}

export function pickLegAccount(item:Partial<Transaction_Legs>,source:boolean =false){
    switch(item.direction){
        case "P":
            return source?item.src_account:item.dst_account
        case "R": 
            return source?item.dst_account:item.src_account 
        default:
            return null
    }
}
