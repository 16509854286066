/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ClientSearchRequest = {
    properties: {
        query: {
            type: 'string',
        },
        want_count: {
            type: 'boolean',
        },
        ordering: {
            type: 'array',
            contains: {
                type: 'any-of',
                contains: [{
                    type: 'string',
                }],
            },
        },
        where: {
            type: 'array',
            contains: {
                type: 'any-of',
                contains: [{
                    type: 'string',
                }],
            },
        },
    },
} as const;
