/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ClientLineItem = {
    properties: {
        client_uuid: {
            type: 'string',
            isRequired: true,
            format: 'uuid',
        },
        legal_name: {
            type: 'string',
            isRequired: true,
        },
        email: {
            type: 'string',
            isRequired: true,
        },
        username: {
            type: 'string',
            isRequired: true,
        },
        client_identifier: {
            type: 'string',
        },
        private_client: {
            type: 'boolean',
            isRequired: true,
        },
        first_name: {
            type: 'string',
        },
        mailboxes: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        num_accounts: {
            type: 'number',
        },
        num_user_accounts: {
            type: 'number',
        },
        num_workflows: {
            type: 'number',
        },
        distinct_accounts: {
            type: 'number',
        },
        confirmed_accounts: {
            type: 'number',
        },
        total_heldaway_value: {
            type: 'any-of',
            contains: [{
                type: 'number',
            }, {
                type: 'number',
            }, {
                type: 'number',
            }],
        },
        min_period_date: {
            type: 'string',
            format: 'date',
        },
        accounts: {
            type: 'array',
            contains: {
                type: 'ClientAccountLine',
            },
        },
    },
} as const;
