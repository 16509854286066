/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ThirdPartySignup = {
    properties: {
        user: {
            type: 'ApiUser',
            isRequired: true,
        },
        grant: {
            type: 'ApiUserAccessGrant',
        },
    },
} as const;
