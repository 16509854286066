/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiCustomMailbox = {
    description: `Custom Email mailbox`,
    properties: {
        inbox_address: {
            type: 'string',
            isRequired: true,
            format: 'email',
        },
        status: {
            type: 'CustomMailboxStatus',
            isRequired: true,
        },
        created_time: {
            type: 'string',
            isRequired: true,
            format: 'date-time',
        },
        updated_time: {
            type: 'string',
            isRequired: true,
            format: 'date-time',
        },
        release_date: {
            type: 'string',
            format: 'date',
        },
        last_message: {
            type: 'string',
            format: 'date-time',
        },
        num_messages: {
            type: 'number',
        },
        num_attachments: {
            type: 'number',
        },
        attachment_bytes: {
            type: 'number',
        },
    },
} as const;
