/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DocumentSearchRequest = {
    properties: {
        query: {
            type: 'string',
        },
        ordering: {
            type: 'array',
            contains: {
                type: 'any-of',
                contains: [{
                    type: 'string',
                }],
            },
        },
        accessed_time: {
            type: 'any-of',
            contains: [{
                type: 'string',
                format: 'date',
            }, {
                type: 'RangeClause',
            }],
        },
        created_time: {
            type: 'any-of',
            contains: [{
                type: 'string',
                format: 'date',
            }, {
                type: 'RangeClause',
            }],
        },
        document_types: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        links: {
            type: 'DocumentSearchLinkClause',
        },
        include_children: {
            type: 'boolean',
        },
        include_links: {
            type: 'any-of',
            contains: [{
                type: 'array',
                contains: {
                    type: 'string',
                },
            }, {
                type: 'boolean',
            }],
        },
        include_workflows: {
            type: 'any-of',
            contains: [{
                type: 'array',
                contains: {
                    type: 'string',
                },
            }, {
                type: 'boolean',
            }],
        },
        is_fragment: {
            type: 'boolean',
        },
        parent: {
            type: 'string',
            format: 'uuid',
        },
        advanced: {
            type: 'boolean',
        },
    },
} as const;
