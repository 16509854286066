/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $StartUploadItem = {
    properties: {
        doc: {
            type: 'ApiDocument',
            isRequired: true,
        },
        upload: {
            type: 'StorageUploadSpec',
            isRequired: true,
        },
    },
} as const;
