/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Periodicity } from './Periodicity';

export type EquityDetails = {
    detail_type?: EquityDetails.detail_type;
    dividend_amount?: number;
    dividend_unit_id?: string;
    periodicity?: Periodicity;
    preference_price?: number;
    preference_multiple?: number;
    participation_cap_multiple?: number;
    preference_info?: any;
    conversion_ratio?: number;
    conversion_cost_per_share?: number;
    conversion_info?: any;
};

export namespace EquityDetails {

    export enum detail_type {
        EQTY = 'EQTY',
    }


}

