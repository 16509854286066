/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CcyDetails = {
    detail_type?: CcyDetails.detail_type;
};

export namespace CcyDetails {

    export enum detail_type {
        CCY = 'CCY',
    }


}

