/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum BusinessDayConvention {
    FOLLOWING = 'F',
    MODIFIED_FOLLOWING = 'MF',
}
