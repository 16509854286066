/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiUser = {
    properties: {
        username: {
            type: 'string',
            isRequired: true,
        },
        first_name: {
            type: 'string',
        },
        last_name: {
            type: 'string',
        },
        email: {
            type: 'string',
        },
        profile: {
            type: 'ApiProfile',
        },
        is_staff: {
            type: 'boolean',
        },
    },
} as const;
