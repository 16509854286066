/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum AmortizationType {
    STRAIGHT = 'L',
    TRADITIONAL = 'M',
}
