/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ImportSuggestionResponse = {
    description: `Suggestion Import mappings`,
    properties: {
        similar: {
            type: 'array',
            contains: {
                type: 'ImportMappingIdea',
            },
            isRequired: true,
        },
        choices: {
            type: 'dictionary',
            contains: {
                type: 'array',
                contains: {
                    type: 'any-of',
                    contains: [{
                        type: 'ExpressionColumn',
                    }, {
                        type: 'ConstantColumn',
                    }, {
                        type: 'MappedColumn',
                    }],
                },
            },
        },
        filterChoices: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        fingerprintChoices: {
            type: 'array',
            contains: {
                type: 'array',
                contains: {
                    type: 'string',
                },
            },
        },
        relevant_columns: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        meta: {
            properties: {
            },
        },
    },
} as const;
