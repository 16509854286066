/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InstrumentTimeSeriesRequest } from '../models/InstrumentTimeSeriesRequest';
import type { TimeSeriesResponse } from '../models/TimeSeriesResponse';
import type { ValuationsResponse } from '../models/ValuationsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TimeseriesService {

    /**
     * Snapshot Entries
     * @returns any Successful Response
     * @throws ApiError
     */
    public static snapshotEntries(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/timeseries/snapshot',
        });
    }

    /**
     * User Sourced Instrument Series
     * Get timeseries for an instrument using user sourced prices
     * @param requestBody
     * @param host
     * @returns TimeSeriesResponse Successful Response
     * @throws ApiError
     */
    public static userSourcedInstrumentSeries(
        requestBody: InstrumentTimeSeriesRequest,
        host?: string,
    ): CancelablePromise<TimeSeriesResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/timeseries/user/instrument',
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Instrument Series
     * Get timeseries for an instrument
     * @param requestBody
     * @param host
     * @returns TimeSeriesResponse Successful Response
     * @throws ApiError
     */
    public static instrumentSeries(
        requestBody: InstrumentTimeSeriesRequest,
        host?: string,
    ): CancelablePromise<TimeSeriesResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/timeseries/instrument',
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Instrument Valuation
     * @param requestBody
     * @param host
     * @returns ValuationsResponse Successful Response
     * @throws ApiError
     */
    public static instrumentValuation(
        requestBody: InstrumentTimeSeriesRequest,
        host?: string,
    ): CancelablePromise<ValuationsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/valuation/instrument',
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Payoff Series
     * Payoff Handling
     * @param requestBody
     * @param host
     * @returns TimeSeriesResponse Successful Response
     * @throws ApiError
     */
    public static payoffSeries(
        requestBody: InstrumentTimeSeriesRequest,
        host?: string,
    ): CancelablePromise<TimeSeriesResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/timeseries/payoff',
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
