/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum CustomMailboxStatus {
    PENDING = 1,
    APPROVED = 2,
    REJECTED = 3,
    RELEASED = 4,
}
