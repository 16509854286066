/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum CouponType {
    FIXED = 'FIX',
    FLOAT_COUPON = 'FLT',
    INDEX_NOTIONAL = 'IND',
}
