/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiDocument } from '../models/ApiDocument';
import type { BatchGetRequest } from '../models/BatchGetRequest';
import type { DocumentSearchRequest } from '../models/DocumentSearchRequest';
import type { InputApiDocument } from '../models/InputApiDocument';
import type { ListResponse_ApiDocument_ } from '../models/ListResponse_ApiDocument_';
import type { SimpleDocumentContent } from '../models/SimpleDocumentContent';
import type { SimpleDocumentUrl } from '../models/SimpleDocumentUrl';
import type { StartUploadResponse } from '../models/StartUploadResponse';
import type { StatusResponse } from '../models/StatusResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DocumentsService {

    /**
     * Start Upload
     * Start a document Upload
     * If the doc has a uuid this will perform an upodate an update otherwise this will create a new document
     * @param requestBody
     * @param needRedirect
     * @param host
     * @returns StartUploadResponse Successful Response
     * @throws ApiError
     */
    public static startUpload(
        requestBody: InputApiDocument,
        needRedirect: boolean = false,
        host?: string,
    ): CancelablePromise<StartUploadResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/documents/',
            headers: {
                'host': host,
            },
            query: {
                'need_redirect': needRedirect,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Documents
     * @param requestBody
     * @param limit
     * @param offset
     * @param host
     * @returns ListResponse_ApiDocument_ Successful Response
     * @throws ApiError
     */
    public static searchDocuments(
        requestBody: DocumentSearchRequest,
        limit: number = 10,
        offset?: number,
        host?: string,
    ): CancelablePromise<ListResponse_ApiDocument_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/documents/search',
            headers: {
                'host': host,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Batch Get
     * @param requestBody
     * @param withChildren
     * @param withChildCount
     * @param host
     * @returns ListResponse_ApiDocument_ Successful Response
     * @throws ApiError
     */
    public static batchGet(
        requestBody: BatchGetRequest,
        withChildren: boolean = false,
        withChildCount: boolean = false,
        host?: string,
    ): CancelablePromise<ListResponse_ApiDocument_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/documents/bulk/',
            headers: {
                'host': host,
            },
            query: {
                'with_children': withChildren,
                'with_child_count': withChildCount,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Document
     * @param id
     * @param withChildren
     * @param withChildCount
     * @param host
     * @returns ApiDocument Successful Response
     * @throws ApiError
     */
    public static getDocument(
        id: string,
        withChildren: boolean = false,
        withChildCount: boolean = false,
        host?: string,
    ): CancelablePromise<ApiDocument> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/documents/{id}/',
            path: {
                'id': id,
            },
            headers: {
                'host': host,
            },
            query: {
                'with_children': withChildren,
                'with_child_count': withChildCount,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Document
     * @param id
     * @param requestBody
     * @param host
     * @returns ApiDocument Successful Response
     * @throws ApiError
     */
    public static updateDocument(
        id: string,
        requestBody: InputApiDocument,
        host?: string,
    ): CancelablePromise<ApiDocument> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/documents/{id}',
            path: {
                'id': id,
            },
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Document
     * @param id
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteDocument(
        id: string,
        host?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/documents/{id}',
            path: {
                'id': id,
            },
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Document Version
     * @param id
     * @param version
     * @param noRedirect
     * @param view
     * @param host
     * @returns SimpleDocumentUrl Successful Response
     * @throws ApiError
     */
    public static getDocumentVersion(
        id: string,
        version: ('latest' | number),
        noRedirect: boolean = false,
        view?: (boolean | 'auto'),
        host?: string,
    ): CancelablePromise<SimpleDocumentUrl> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/documents/{id}/file/{version}/',
            path: {
                'id': id,
                'version': version,
            },
            headers: {
                'host': host,
            },
            query: {
                'no_redirect': noRedirect,
                'view': view,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Document Content
     * Get the Content for a Document
     * @param id
     * @param version
     * @param host
     * @returns SimpleDocumentContent Successful Response
     * @throws ApiError
     */
    public static getDocumentContent(
        id: string,
        version: ('latest' | number),
        host?: string,
    ): CancelablePromise<SimpleDocumentContent> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/documents/{id}/content/{version}',
            path: {
                'id': id,
                'version': version,
            },
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upload Redirect Complete
     * @param token
     * @returns StatusResponse Successful Response
     * @throws ApiError
     */
    public static uploadRedirectComplete(
        token: string,
    ): CancelablePromise<StatusResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/documents/upload-redirect/{token}/',
            path: {
                'token': token,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upload Complete
     * @param id
     * @param version
     * @param host
     * @returns StatusResponse Successful Response
     * @throws ApiError
     */
    public static uploadComplete(
        id: string,
        version: number,
        host?: string,
    ): CancelablePromise<StatusResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/documents/{id}/uploaded/{version}/',
            path: {
                'id': id,
                'version': version,
            },
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Content
     * Get the Content for a document
     * @param id
     * @param view
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getContent(
        id: string,
        view: boolean = false,
        host?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/documents/{id}/content/',
            path: {
                'id': id,
            },
            headers: {
                'host': host,
            },
            query: {
                'view': view,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Thumbnail
     * @param id
     * @param size
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getThumbnail(
        id: string,
        size: number = 512,
        host?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/documents/{id}/thumbnail/',
            path: {
                'id': id,
            },
            headers: {
                'host': host,
            },
            query: {
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
