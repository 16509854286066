/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $BondDetails = {
    properties: {
        detail_type: {
            type: 'Enum',
        },
        notional_exchange: {
            type: 'NotionalExchange',
            isRequired: true,
        },
        payable_unit_id: {
            type: 'string',
            isRequired: true,
            format: 'uuid',
        },
        periodicity: {
            type: 'Periodicity',
            isRequired: true,
        },
        coupon_percent: {
            type: 'number',
        },
        coupon_spread: {
            type: 'number',
        },
        coupon_type: {
            type: 'all-of',
            contains: [{
                type: 'CouponType',
            }],
        },
        first_pay_date: {
            type: 'string',
            format: 'date',
        },
        last_pay_date: {
            type: 'string',
            format: 'date',
        },
        dct: {
            type: 'DayCountConvention',
        },
        bdc: {
            type: 'BusinessDayConvention',
        },
    },
} as const;
