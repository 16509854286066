/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SimpleDocumentUrl = {
    description: `A simple response containing information about a document`,
    properties: {
        url: {
            type: 'string',
            isRequired: true,
        },
        version: {
            type: 'number',
            isRequired: true,
        },
    },
} as const;
