/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiWorkflowType = {
    description: `ApiWorkflow Type`,
    properties: {
        name: {
            type: 'string',
            isRequired: true,
        },
        identifier: {
            type: 'string',
            isRequired: true,
        },
        description: {
            type: 'string',
        },
        etl_type: {
            type: 'ApiCustomEtlWorkflow',
        },
    },
} as const;
