/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SafeDetails = {
    properties: {
        detail_type: {
            type: 'Enum',
        },
        valuation_cap: {
            type: 'number',
        },
        valuation_cap_unit_id: {
            type: 'string',
            format: 'uuid',
        },
        discount: {
            type: 'number',
        },
        post_money: {
            type: 'boolean',
        },
        cc_common: {
            type: 'boolean',
        },
        cc_preferred: {
            type: 'boolean',
        },
        cc_unissued: {
            type: 'boolean',
        },
        cc_preferred_unissued: {
            type: 'boolean',
        },
        cc_converts: {
            type: 'boolean',
        },
        cc_options: {
            type: 'boolean',
        },
        cc_promised_options: {
            type: 'boolean',
        },
        cc_options_pool: {
            type: 'boolean',
        },
        cc_option_pool_increases: {
            type: 'boolean',
        },
        cc_warrants: {
            type: 'boolean',
        },
    },
} as const;
