/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListResponse_ReportType_ } from '../models/ListResponse_ReportType_';
import type { ReportGenerateRequest } from '../models/ReportGenerateRequest';
import type { ReportGenerationStatus } from '../models/ReportGenerationStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReportingService {

    /**
     * Get Report Types
     * @param pattern
     * @param rootOnly
     * @param host
     * @returns ListResponse_ReportType_ Successful Response
     * @throws ApiError
     */
    public static getReportTypes(
        pattern?: string,
        rootOnly: boolean = true,
        host?: string,
    ): CancelablePromise<ListResponse_ReportType_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/reports',
            headers: {
                'host': host,
            },
            query: {
                'pattern': pattern,
                'root_only': rootOnly,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Generate Report
     * @param requestBody
     * @param host
     * @returns ReportGenerationStatus Successful Response
     * @throws ApiError
     */
    public static generateReport(
        requestBody: ReportGenerateRequest,
        host?: string,
    ): CancelablePromise<ReportGenerationStatus> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/reports/generate',
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Download Report
     * @param reportKey
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static downloadReport(
        reportKey: string,
        host?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/reports/download/{report_key}',
            path: {
                'report_key': reportKey,
            },
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
