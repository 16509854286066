/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $EntityRefDetails = {
    properties: {
        name: {
            type: 'string',
            isRequired: true,
        },
        legal_name: {
            type: 'string',
            isRequired: true,
        },
        description: {
            type: 'string',
        },
        first_name: {
            type: 'string',
        },
        middle_name: {
            type: 'string',
        },
        last_name: {
            type: 'string',
        },
        website: {
            type: 'string',
        },
        ceo: {
            type: 'EntityRef',
        },
        manager: {
            type: 'EntityRef',
        },
    },
} as const;
