/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type NumberFormat = {
    type: NumberFormat.type;
    format: string;
};

export namespace NumberFormat {

    export enum type {
        NUMBER = 'number',
    }


}

