/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $PostmarkInboundEmail = {
    properties: {
        MessageStream: {
            type: 'string',
            isRequired: true,
        },
        From: {
            type: 'string',
            isRequired: true,
        },
        FromName: {
            type: 'string',
            isRequired: true,
        },
        FromFull: {
            type: 'PostmarkFullEmail',
            isRequired: true,
        },
        To: {
            type: 'string',
            isRequired: true,
        },
        ToFull: {
            type: 'array',
            contains: {
                type: 'PostmarkFullEmail',
            },
            isRequired: true,
        },
        OriginalRecipient: {
            type: 'string',
            isRequired: true,
        },
        Subject: {
            type: 'string',
            isRequired: true,
        },
        MessageID: {
            type: 'string',
            isRequired: true,
        },
        Date: {
            type: 'string',
            isRequired: true,
        },
        TextBody: {
            type: 'string',
            isRequired: true,
        },
        HtmlBody: {
            type: 'string',
            isRequired: true,
        },
        Headers: {
            type: 'array',
            contains: {
                type: 'PostmarkHeader',
            },
        },
        Attachments: {
            type: 'array',
            contains: {
                type: 'PostmarkAttachment',
            },
        },
        MailboxHash: {
            type: 'string',
        },
        ReplyTo: {
            type: 'string',
        },
        Cc: {
            type: 'string',
        },
        CcFull: {
            type: 'array',
            contains: {
                type: 'PostmarkFullEmail',
            },
        },
        Bcc: {
            type: 'string',
        },
        BccFull: {
            type: 'array',
            contains: {
                type: 'PostmarkFullEmail',
            },
        },
        Tag: {
            type: 'string',
        },
        StrippedTextReply: {
            type: 'string',
        },
    },
} as const;
