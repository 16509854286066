/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ComponentFormat = {
    type: ComponentFormat.type;
    name: string;
    props?: any;
    value?: string;
};

export namespace ComponentFormat {

    export enum type {
        COMPONENT = 'component',
    }


}

