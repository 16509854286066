/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SignupResponse = {
    properties: {
        info: {
            type: 'UserInfo',
            isRequired: true,
        },
        system: {
            type: 'SystemInfo',
            isRequired: true,
        },
        details: {
            type: 'SignupDetailRequest',
        },
    },
} as const;
