/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $PostmarkAttachment = {
    properties: {
        Content: {
            type: 'string',
            isRequired: true,
        },
        ContentLength: {
            type: 'number',
            isRequired: true,
        },
        Name: {
            type: 'string',
            isRequired: true,
        },
        ContentType: {
            type: 'string',
            isRequired: true,
        },
        ContentID: {
            type: 'string',
            isRequired: true,
        },
    },
} as const;
