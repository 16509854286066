/***
 * Moduel containing useful route construction functions
 */

import {Route,Location } from "vue-router"
import {encodeFilterSpec,FilterSpec} from "./common/filtering"
import {TransactionLegFilterSpec } from "./transactions/filtering"
import {EventFilterSpec} from "./events/filtering"
import { FilterKeyPrefix } from "~/core/common/filtering"
import {ApiEvent, ClientLineItem, EventsService, EventType} from "~/schemas/gen"
import {ApiEventNode} from "./events"
import {Events} from "~/models/graphql"
import {useOnetime} from "~/plugins/onetime-data.client"
import {useUniverse} from "~/plugins/auth.universe"
import {merge} from "lodash"

export type SummaryTabTypes = "summary"|"transactions"|"events"|"analytics"

export function transactionsBySecurity(id:string):Location {
    let encoded = encodeFilterSpec({"instrument.uuid":id},TransactionLegFilterSpec,FilterKeyPrefix.Transactions)
    let t:SummaryTabTypes = "transactions"
    return { name:"user",query:{t,...encoded }}
}


// Find all the events related to this event
export async function relatedEvents(uuid:string):Promise<Location> {
    let root = await EventsService.getRootEvent(uuid)
    let encoded = encodeFilterSpec({"root_event.uuid":root.id},EventFilterSpec,FilterKeyPrefix.Events)
    let t:SummaryTabTypes = "events"
    return {name:"user",query:{t,...encoded}}
}




// return the route for an instrument
export function forInstrument(uuid:string):Location {
    return {name:'instruments-id',
            params:{id:uuid}}
}


type EventInput = Partial<Events>|Partial<ApiEvent>|Partial<ApiEventNode>;

export function forEvent(e:EventInput,query:Record<string,string> = {}):Location {
    let id = (e as any).uuid||e.id;
    if(e.event_type == EventType.NEW_TRANSACTION){
        return {
            name:"transaction-id-edit",
            params:{id},
            query:{event_mode:"1",...query}
        }
    }
    return {name:'event-id',
        params:{id}}
}

export function forEvents(){
    return {name:"user",query:{t:"events"}}
}

export function forDocuments(){
    return {name:"documents"}
}

export function forAccounts(){
    return {name:"accounts"}
}


export function forAccount(uuid:string):Location {
    return {name:"accounts-id-edit",
        params:{id:uuid}}
}


export function forEntity(uuid:string):Location {
    return {name:"entities-id-edit",
        params:{id:uuid}}
}


export function forDocument(uuid:string):Location {
        return {
            name:"documents-id-edit",
            params:{id:uuid }
        }
}

export function forWorkflow(id:string):Location {
    return {
        name:"workflows-id",
        params:{id}
    }
}

export function forAdvisorWorkflows(client:ClientLineItem,extra:any = {}):Location {
    const $onetime = useOnetime()
    return  $onetime.withOutbound( {
        name:"adviser-workflows"
    },{client,...extra})
}

export function universeAware(l:Location,targetUniverse?:string):Location {
    const $universe = useUniverse()
    if(!$universe.isDistinct) return l
    return merge({},l,{query:{universe:targetUniverse ?? $universe.currentId}})
}

export const HELP_SCOUT_URL = "https://distributary.helpscoutdocs.com/"
export const KNOWLEDGE_URL = "https://knowledge.distributary.io/"


