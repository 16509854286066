/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $RelatedReport = {
    description: `Related Report that might be worth showing `,
    properties: {
        report_id: {
            type: 'string',
            isRequired: true,
        },
        label: {
            type: 'string',
            isRequired: true,
        },
    },
} as const;
