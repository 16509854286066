import {Vue,Component} from "nuxt-property-decorator"
import {components} from "~/schemas/api"
import {ErrorStateBase,MissingModelError,ErrorHandler} from "~/components/utils/editing"
import {
    DocumentsService,ApiDocument,InputApiDocument, ApiError,
    StartUploadResponse
} from "~/schemas/gen"
import {INotifier, useNotifier} from "~/plugins/notifier.client"
export type {InputApiDocument} from "~/schemas/gen" 



/**
 *  Editor class
 */
@Component
export class Editor<ErrorStateT> extends ErrorStateBase<ErrorStateT> {
    model:InputApiDocument|null = null
    
    async doAdd(handler?:ErrorHandler):Promise<StartUploadResponse> {
        this.validationErrors = {}
        this.errorState = false;
        let resp = await this.addImpl( this.model,
           (x:InputApiDocument) => DocumentsService.startUpload(x) , handler)
        return resp;
    }

    get notifier():INotifier {
        return this.$notifier  || useNotifier()
    }

    async doUpdate(id:string,errorHandler?:ErrorHandler):Promise<ApiDocument> {
        try{
            let resp = await this.updateImpl(id,this.model!,
                (id:string,x:InputApiDocument) => DocumentsService.updateDocument(id,x),
                errorHandler);
             this.notifier.success("Updated Document");
            return resp
        }catch(err){
            console.error("Update Error:",err)
            throw err
        }
    }

    async doDelete(id:string,errorHandler?:ErrorHandler):Promise<boolean>{
        this.clearErrors();
        if(!this.model) throw new MissingModelError();
        let resp = await DocumentsService.deleteDocument(id).catch(err => err)
        if(await this.maybeHandleError(resp,errorHandler)) return false;
        this.notifier.success("Document Deleted")
        return true;
    }

}


