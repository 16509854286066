/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ProfileDisplaySettings = {
    properties: {
        workflows_enabled: {
            type: 'boolean',
        },
        account_style: {
            type: 'AccountDisplayStyle',
        },
    },
} as const;
