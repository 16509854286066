/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $TimeSeriesResponse = {
    properties: {
        unit: {
            type: 'ApiInstrument',
            isRequired: true,
        },
        series: {
            type: 'dictionary',
            contains: {
                type: 'any-of',
                contains: [{
                    type: 'ResponseSeries',
                }, {
                    type: 'ResponseSeriesError',
                }],
            },
        },
        instruments: {
            type: 'dictionary',
            contains: {
                type: 'ApiInstrument',
            },
        },
    },
} as const;
