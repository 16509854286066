/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $LineItemPart = {
    properties: {
        unit: {
            type: 'any-of',
            contains: [{
                type: 'ApiInstrument',
            }, {
                type: 'InstrumentRef',
            }, {
                type: 'string',
                pattern: 'r#[0-9]+',
            }],
            isRequired: true,
        },
        total: {
            type: 'any-of',
            contains: [{
                type: 'number',
            }, {
                type: 'number',
            }],
            isRequired: true,
        },
    },
} as const;
