/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum AccountRefType {
    ID = 'id',
    NAME = 'name',
}
