/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum RecurType {
    SPECIFIED = 'S',
    COUPON = 'C',
}
