/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SettlementDetails = {
    properties: {
        account: {
            type: 'AccountRef',
            isRequired: true,
        },
        unit: {
            type: 'FullInstrumentRef',
            isRequired: true,
        },
    },
} as const;
