/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiWorkflowInputStage = {
    properties: {
        identifier: {
            type: 'string',
            isRequired: true,
        },
        type: {
            type: 'string',
            isRequired: true,
        },
        config: {
            type: 'any-of',
            contains: [{
                type: 'FileInputStageConfig',
            }, {
                type: 'DataTabularConfig',
            }, {
                type: 'FileReportConfig',
            }],
        },
        display: {
            type: 'WorkflowStageDisplay',
        },
        inputs: {
            type: 'array',
            contains: {
                type: 'any-of',
                contains: [{
                    type: 'string',
                }, {
                    type: 'ApiWorkflowInputRef',
                }],
            },
        },
        schemas: {
            type: 'array',
            contains: {
                type: 'ApiWorkflowInputSchemaItem',
            },
            isRequired: true,
        },
    },
} as const;
