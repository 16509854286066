/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ImportSpec = {
    properties: {
        mappings: {
            type: 'dictionary',
            contains: {
                type: 'any-of',
                contains: [{
                    type: 'ExpressionColumn',
                }, {
                    type: 'ConstantColumn',
                }, {
                    type: 'MappedColumn',
                }],
            },
            isRequired: true,
        },
        name: {
            type: 'string',
        },
        input_name: {
            type: 'string',
        },
    },
} as const;
