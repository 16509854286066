/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ImportSuggestionRequest = {
    description: `Suggest import mappings`,
    properties: {
        headers: {
            type: 'array',
            contains: {
                type: 'string',
            },
            isRequired: true,
        },
        importable: {
            type: 'string',
            isRequired: true,
        },
        document_id: {
            type: 'string',
            format: 'uuid',
        },
        sample: {
            type: 'array',
            contains: {
                type: 'array',
                contains: {
                    type: 'string',
                },
            },
        },
        current: {
            type: 'ImportSpec',
        },
    },
} as const;
