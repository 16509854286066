import type {Context} from "@nuxt/types"
import {ref,onScopeDispose,shallowRef, computed,onBeforeUnmount} from "vue"
import type {Ref,ComputedRef} from "vue"


export type PopoverSize = "peek"|"full"|"expanded"|"extra-full"

export interface  IPopover {
  readonly visible:boolean
  readonly activeSize:PopoverSize|undefined
  readonly top?:IPopoverScope
}

export type IPopoverScope = {
  key:any
  size:Ref<PopoverSize>
  visible:ComputedRef<boolean>
  [key:string]:any
}


declare module 'vue/types/vue' {
  interface Vue {
    $popover:IPopover
  }
}
var scopeUpdate = shallowRef(new Date())
var scopes = new Map<number,IPopoverScope>();
let popover!:IPopover

const topScope = computed(() => {
  let item:IPopoverScope|undefined = undefined
  scopeUpdate.value//Access scope update
  scopes.forEach((v) => {
    item =v
  })
  return item;
})
export function usePopover():IPopover {
  return popover
}

export type PopoverScopeOpts = {
  active:ComputedRef<boolean>
  initialSize?:PopoverSize
}

const last = ref(0)
export function usePopoverScope({active,initialSize}:PopoverScopeOpts):IPopoverScope{
  last.value = last.value +1
  let entry:IPopoverScope = {
    component: true,
    size:ref(initialSize || "expanded"),
    visible:active,
    key:last.value
  }

  scopes.set(last.value,entry)
  scopeUpdate.value = entry.key
  ///When the scope goes away we will remove it
  let undo = () => {
    scopes.delete(entry.key)
    scopeUpdate.value=new Date()
  }
  onBeforeUnmount(undo)
  onScopeDispose(undo)
  return entry
}



declare module '@nuxt/types' {
    interface Context {
        $popover:IPopover
    }
}


export default ({app}:Context,inject:any) => {
  popover = {
    get visible():boolean {
      scopeUpdate.value
      return this.top?.visible?.value ?? false
    },
    get activeSize(){

      return this.top?.size?.value
    },
    get top(){
      return topScope.value

    }
  }

  inject ("popover",popover)
}
