/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $LineItemData = {
    description: `This represents the row data parts of a line item`,
    properties: {
        position: {
            type: 'number',
        },
        value: {
            type: 'number',
        },
        price: {
            type: 'number',
        },
        components: {
            type: 'dictionary',
            contains: {
                type: 'LineItemPart',
            },
        },
        analytics: {
            type: 'array',
            contains: {
                properties: {
                },
            },
        },
    },
} as const;
