/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CapitalCommitmentDetails = {
    properties: {
        detail_type: {
            type: 'Enum',
        },
        payable_unit_id: {
            type: 'string',
            format: 'uuid',
        },
        fund_id: {
            type: 'string',
            format: 'uuid',
        },
        fees_short: {
            type: 'ShortFeeDeclaration',
        },
        expenses_short: {
            type: 'ShortExpenseDeclaration',
        },
        waterfall: {
            type: 'array',
            contains: {
                type: 'FundFlowClauseDetails',
            },
        },
        underlying_equity_id: {
            type: 'string',
            format: 'uuid',
        },
        committed_capital: {
            type: 'number',
        },
    },
} as const;
