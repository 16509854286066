import {GetterTree,MutationTree,ActionTree} from "vuex"
import type {components} from "~/schemas/api"
import type {ApiProfile,ApiUser,ApiFirm,ApiFirmMember,
        LoginRequest,SignupRequest,SignupResponse,MfaToken,
        MfaChallengeResponse,UserInfo,SystemInfo,ProfileResponse
} from "~/schemas/gen"
import {HttpValidationError} from "~/utils/validation"
import {HttpGenericError} from "~/utils/errors"
import Axios from "axios"
import type {Auth} from "@nuxtjs/auth-next"


interface RootState {
    auth:Auth
}



export const state = () => {
    return {
        loginState:false,
        systemInfo:<null|SystemInfo>null,
        impersonator:<ApiUser|null>null,
        mfaChallenge:<MfaChallengeResponse|null>null,
        rootToken:<string|null>null,
        firm:<ApiFirm|null>null,
        firmMembership:<ApiFirmMember|null>null
    }
}
export type UserState = ReturnType<typeof state>

const PROFILE_KEY = "user/system/info"
const IMPERSONATOR_KEY = "user/impersonator"
const ROOT_TOKEN_KEY = "user/root/token"


export const mutations:MutationTree<UserState> = {
    updateLoginState(state){
        state.loginState = false
    },
    updateSystemInfo(state,info:SystemInfo){
        state.systemInfo = info
    },
    updateImpersonatorUser(state,user:ApiUser|null){
        state.impersonator =  user
    },
    updateMfaChallenge(state,c:MfaChallengeResponse|null){
        state.mfaChallenge= c;
    },
    updateRootToken(state,x:string){
        state.rootToken = x;
    },
    updateFirm(state,firm:ApiFirm|null){
        state.firm = firm
    },
    updateFirmMembership(state,mem:ApiFirmMember|null){
        state.firmMembership =  mem
    }
}


export const getters:GetterTree<UserState,RootState> = {
    jwtToken(state):Record<string,any>  {
        //@TODO: Resolve this later
        return {}
    },
    rootToken(state,getters,rootState):string|null{
       return state.rootToken
    },
    systemInfo(state):SystemInfo|null{
        return  state.systemInfo
    },
    systemUserId(state,getters):number|undefined{
        //@tsignore
        return state.systemInfo?.system_user_id ||  parseInt(process.env.NUXT_ENV_SYSTEM_USER_ID!)
    },
    profile(state:UserState,getters,rootState:RootState):ApiProfile{
        return (rootState.auth as any).user?.profile;
    },
    firm(state:UserState,getters,rootState:RootState):ApiFirm|null {
        return state.firm
    },
    firmMembership(state:UserState,getters,rootState:RootState):ApiFirmMember|null {
        return state.firmMembership
    },
    impersonator(state,getters):ApiUser|null {
        return state.impersonator
    },
    isImpersonating(state:UserState){
        return state.impersonator != null
    },
    isAdviser(state:UserState,getters,rootState:RootState):boolean {
        const auth:Auth =rootState.auth;
        const user =auth?.user as ApiUser
        const roles= user?.profile?.enabled_roles
        if(!roles) return false;
        return  roles.indexOf("adviser") != -1 || roles.indexOf("advisor")!= -1
    },
    mfaChallenge(state:UserState){
        return state.mfaChallenge
    }
}

function isMfaToken(x:LoginRequest|MfaToken): x is MfaToken {
    return "device_id" in x;
}

export const actions:ActionTree<UserState,RootState> = {
    async login({dispatch,commit},req:LoginRequest|MfaToken ):Promise<UserInfo|Error> {
        let mfaChallenge:MfaChallengeResponse|null = null;
        try{
            let scheme:string  = "local"
            let data!:any;
            if(isMfaToken(req)){
                data = req;
                scheme = "mfa"
            }else data = {input:req}
            /**
             * MFA schema assumes there  is an MFA challenge in the cookie session
             * that has not expired
             */
            let resp = await this.$auth.loginWith(scheme,{
                data
            }).catch((err:Error) => {
                if(Axios.isAxiosError(err)){
                    let r= err.response;
                    switch(r?.status){
                        case 400:
                        case 401:
                            {
                                let {data } = r
                                if(data.code == "mfa-challenge"){
                                    mfaChallenge = data as MfaChallengeResponse
                                }
                                return new HttpGenericError(r).withDefaultMessage("Login Failed")
                            }
                    }
                }
                return err
            })
            if(resp instanceof Error) return resp;
            if(!resp) return new Error("Login failed")
            let lr = resp.data as UserInfo
            this.$gtag.event("auth/login",{user:lr.user?.username})
            this.$gtag.config({
              'user_id':lr.user?.profile?.uuid
            })
            await dispatch('finishLogin',lr);
            return lr
        }finally {
            commit("updateMfaChallenge",mfaChallenge )
        }
    },
    //
    //Start impersonation process witha  token in hand
    async impersonate({commit,dispatch},token:string){
        //Logout currentuser
        await  this.$auth.logout()
        let resp = await this.$auth.loginWith("impersonation",{
            data:{token}
        }).catch((err:Error) => {
            if(Axios.isAxiosError(err)){
                let r= err.response;
                switch(r?.status){
                    case 400:
                    case 401:
                        return new HttpGenericError(r).withDefaultMessage("Login Failed")
                }
            }
            return err
        })
        if(resp instanceof Error) return resp;
        if(!resp) return new Error("Impersonation failed")
        let lr = resp.data as UserInfo
        let original_user_name = lr.impersonator?.username
        this.$gtag.event("auth/impersonate",{user:lr.user!.username,
        original_user_name})
        this.$gtag.config({
          'user_id':lr.user?.profile?.uuid
        })
        await dispatch('finishLogin',lr);
        return lr
    },
    async signup({dispatch},req:SignupRequest):Promise<Error|SignupResponse>{
        let resp = await this.$axios.post("/auth/signup",req).catch((err:Error) => err)
        if(Axios.isAxiosError(resp)){
            if(resp.response){
                let r = resp.response;
                if(r.status == 422){
                    return new HttpValidationError("Signup Request Failed",r.data?.detail)
                }else if(r.status == 400){
                    return new HttpGenericError(r);
                }
            }
            return resp
        }else if(resp instanceof Error){
            return resp
        }else{
          this.$gtag.event("sign_up",{
            method:"site"
          })
          let data = resp.data as SignupResponse
          await dispatch("finishLogin",data.info)
          return data
        }
    },
    initialize({commit}){
        //THis is called whenever a page is loaded
        //To fix up this store
        if(this.$auth.user){
            //HACK:
            //We have an interceptor that will capture the response of /auth/self
            //and attach it to the data so we can access the rest of it.
            //This will allow the new page behaviour to integrate
            //impersonation state (that is not stored in local storage for safety reasons)
            let rawData:ProfileResponse = (this.$auth.user as any).rawResponse?.()?.data
            let impUser = rawData?.impersonator ?? this.$auth.$storage.getLocalStorage(IMPERSONATOR_KEY);
            let rootToken =rawData?.root_access_token ?? this.$auth.$storage.getLocalStorage(ROOT_TOKEN_KEY)
            let systemData = rawData?.system ?? this.$auth.$storage.getLocalStorage(PROFILE_KEY)
            let firm =  rawData?.firm
            let firmMembership = rawData?.firm_membership

            if(impUser) commit("updateImpersonatorUser",impUser)
            if(rootToken) commit("updateRootToken",rootToken)
            if(systemData) commit("updateSystemInfo",systemData)
            if(firm) commit("updateFirm",firm)
            if(firmMembership) commit("updateFirmMembership",firmMembership)
        }
    },
    async finishLogin({commit},payload:UserInfo){
        this.$apolloHelpers.onLogin(payload.access_token)
        this.$universe.userUniverse(payload.user!,payload.access_token)
        this.$auth.setUser(payload.user)
        this.$auth.setUserToken(payload.access_token,"in-cookie")
        this.$auth.$storage.setLocalStorage(PROFILE_KEY,payload.system)
        this.$auth.$storage.setLocalStorage(IMPERSONATOR_KEY,payload.impersonator)
        this.$auth.$storage.setLocalStorage(ROOT_TOKEN_KEY,payload.access_token)
        commit("updateLoginState",true)
        commit("updateSystemInfo",payload.system)
        commit("updateImpersonatorUser",payload.impersonator)
        if(payload.firm) commit("updateFirm",payload.firm)
        if(payload.firm_membership) commit("updateFirmMembership",payload.firm_membership)
    },
    setRootToken({commit},token:string){
        this.$auth.$storage.setLocalStorage(ROOT_TOKEN_KEY,token)
        commit("updateRootToken",token)
    },
    logout({commit}){
        this.$auth.logout();
        this.$apolloHelpers.onLogout();
        this.$universe.logout();
        this.$auth.$storage.removeUniversal(ROOT_TOKEN_KEY)
        commit("updateRootToken",null)
        commit("updateLoginState",false)
        commit("updateImpersonatorUser",null)
        commit("updateFirm",null)
        commit("updateFirmMembership",null)
    },
    async refresh({commit}){
        await this.$auth.refreshTokens().then(resp => {
            if(resp){
                this.$apolloHelpers.tokenRefresh(resp.data.access_token)
            }else throw new Error("Failed to Refresh!")
        })
    }
}
