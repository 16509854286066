/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiFirm = {
    properties: {
        uuid: {
            properties: {
            },
            isRequired: true,
        },
        name: {
            type: 'string',
            isRequired: true,
        },
        id_number: {
            type: 'string',
        },
        user_id: {
            type: 'string',
            format: 'uuid',
        },
        email_domain: {
            type: 'string',
        },
        settings: {
            type: 'ApiFirmSettings',
        },
    },
} as const;
