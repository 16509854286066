/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $FileReportConfig = {
    properties: {
        ref: {
            type: 'string',
        },
        inline: {
            type: 'FileReportInline',
        },
    },
} as const;
