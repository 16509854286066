/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SessionUpdateRequest = {
    properties: {
        body: {
            type: 'string',
            isRequired: true,
        },
        key: {
            type: 'string',
        },
        session: {
            type: 'ImportSessionMeta',
        },
        parent: {
            type: 'string',
        },
    },
} as const;
