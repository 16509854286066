/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $MortgageDetails = {
    properties: {
        detail_type: {
            type: 'Enum',
        },
        periodicity: {
            type: 'Periodicity',
            isRequired: true,
        },
        payment_convention: {
            type: 'InterestPaymentConvention',
            isRequired: true,
        },
        fixed_interest_rate: {
            type: 'number',
            isRequired: true,
        },
        payable_notional: {
            type: 'number',
        },
        interest_only: {
            type: 'boolean',
        },
        payable_unit_id: {
            type: 'string',
            format: 'uuid',
        },
        collateral_id: {
            type: 'string',
            format: 'uuid',
        },
        mortgage_tax: {
            type: 'number',
        },
        appraisal_fee: {
            type: 'number',
        },
        credit_report_fee: {
            type: 'number',
        },
        amort_schedule: {
            type: 'AmortizationParameters',
        },
    },
} as const;
