/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $InputApiInstrument = {
    description: `Instrument that cna be created by a user`,
    properties: {
        identifier: {
            type: 'string',
            isRequired: true,
            maxLength: 100,
            minLength: 1,
        },
        instrument_type: {
            type: 'InstrumentType',
            isRequired: true,
        },
        identifier_type: {
            type: 'IdentifierType',
            isRequired: true,
        },
        display_name: {
            type: 'string',
            isRequired: true,
            maxLength: 100,
            minLength: 1,
        },
        details: {
            type: 'any-of',
            contains: [{
                type: 'EquityDetails',
            }, {
                type: 'SafeDetails',
            }, {
                type: 'BondDetails',
            }, {
                type: 'RealEstateDetails',
            }, {
                type: 'CcyDetails',
            }, {
                type: 'MortgageDetails',
            }, {
                type: 'RecurDetails',
            }, {
                type: 'CapitalCommitmentDetails',
            }],
        },
        created_time: {
            type: 'string',
            format: 'date-time',
        },
        updated_time: {
            type: 'string',
            format: 'date-time',
        },
        issue_date: {
            type: 'any-of',
            contains: [{
                type: 'string',
                format: 'date',
            }, {
                type: 'string',
                format: 'date-time',
            }],
        },
        issuer_id: {
            type: 'string',
            format: 'uuid',
        },
        issuer: {
            type: 'InputApiEntity',
        },
        issued_amount: {
            type: 'number',
        },
        start_date: {
            type: 'any-of',
            contains: [{
                type: 'string',
                format: 'date',
            }, {
                type: 'string',
                format: 'date-time',
            }],
        },
        end_date: {
            type: 'any-of',
            contains: [{
                type: 'string',
                format: 'date',
            }, {
                type: 'string',
                format: 'date-time',
            }],
        },
        settlement_unit_id: {
            type: 'string',
            format: 'uuid',
        },
        user_description: {
            type: 'string',
        },
        par_amount: {
            type: 'number',
        },
        price_multiplier: {
            type: 'number',
        },
        updates: {
            type: 'InstrumentUpdates',
        },
        acl: {
            type: 'ApiPermissions',
        },
        import_facet: {
            type: 'ImportFacet',
        },
    },
} as const;
