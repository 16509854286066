/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $InputApiEntity = {
    description: `Api Entity for user input`,
    properties: {
        notes: {
            type: 'string',
            description: `Notes from user, attributes and tags are extracted from this`,
        },
        first_name: {
            type: 'string',
            description: `Individuals first name`,
            maxLength: 50,
        },
        middle_name: {
            type: 'string',
            description: `Individuals middle name or initial`,
            maxLength: 50,
        },
        last_name: {
            type: 'string',
            description: `Individuals last name`,
            maxLength: 50,
        },
        birth_date: {
            type: 'string',
            description: `when was the entity created`,
            format: 'date',
        },
        local_type: {
            type: 'string',
            description: `for legal entities, a city level entity designation`,
            maxLength: 4,
        },
        state_type: {
            type: 'string',
            description: `for legal entities, a state level entity designation`,
            maxLength: 4,
        },
        national_type: {
            type: 'string',
            description: `for legal entities, a state level entity designation`,
            maxLength: 4,
        },
        email: {
            type: 'string',
            description: `email`,
            maxLength: 128,
        },
        phone: {
            type: 'string',
            description: `phone`,
            maxLength: 50,
        },
        market_segment: {
            type: 'string',
            description: `market_segment`,
            maxLength: 50,
        },
        info: {
            type: 'any-of',
            description: `info`,
            contains: [{
                type: 'string',
                format: 'json-string',
            }],
        },
        name: {
            type: 'string',
            isRequired: true,
            maxLength: 128,
            minLength: 1,
        },
        legal_name: {
            type: 'string',
            isRequired: true,
            maxLength: 128,
            minLength: 1,
        },
        parent_id: {
            type: 'string',
            format: 'uuid',
        },
        ceo_id: {
            type: 'string',
            format: 'uuid',
        },
        manager_id: {
            type: 'string',
            format: 'uuid',
        },
        website: {
            type: 'string',
            format: 'uri',
            maxLength: 2083,
            minLength: 1,
        },
        acl: {
            type: 'ApiPermissions',
        },
        settlement_account_id: {
            type: 'string',
            format: 'uuid',
        },
        import_facet: {
            type: 'ImportFacet',
        },
        client_identifier: {
            type: 'string',
        },
    },
} as const;
