/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $PasswordTokenResponse = {
    properties: {
        user: {
            type: 'ApiUser',
            isRequired: true,
        },
        challenge: {
            type: 'MfaChallengeResponse',
        },
    },
} as const;
