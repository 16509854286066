/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { TwoFactorDevice } from './TwoFactorDevice';

/**
 * MFA Challenge Response
 * Indicates the list of devices
 */
export type MfaChallengeResponse = {
    email: string;
    devices: Array<TwoFactorDevice>;
    message?: string;
    code?: MfaChallengeResponse.code;
    token?: string;
};

export namespace MfaChallengeResponse {

    export enum code {
        MFA_CHALLENGE = 'mfa-challenge',
    }


}

