import {Ref} from "vue"
import {ApiWorkflow,ApiWorkflowType,InputApiWorkflow,WorkflowStatus  } from "~/schemas/gen"
import {Workflow_Types, Workflows} from "~/models/graphql"
import gql from "graphql-tag";
import { capitalize } from "lodash";
import {useQuery} from "../apollo";

export type ApiWorkflowNode = Partial<Workflows>

export interface WorkflowGuideState {
    input:Partial<InputApiWorkflow & ApiWorkflow>;
    state:Record<string,any>;
}



export function newState():WorkflowGuideState{
    return {
        input: {
            heldaway:{
            }
        },
        state:{}
    }
}


export const fragApiWorkflowFields = gql`
fragment ApiWorkflowFields on workflows {
    ulid updated_time
    type {
        name identifier
    }
    document {
        uuid name parent { name uuid }
    }
}
`


var mapping:Record<number,string>|null = null


export function statusToName(x:WorkflowStatus):string {
    if(!mapping){
        mapping = {}
        Object.entries(WorkflowStatus).forEach(([k,v]) => {
            mapping![v as number]=capitalize(k)
        })
    }
    return mapping[x]
}


export enum WorkflowFlags {
    NEEDS_ATTENTION =1,
    ARCHIVED = 2,
    DOWNLOADED =4
}






