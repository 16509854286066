
import Vue,{CreateElement,VNode,RenderContext} from "vue"
import {VIcon} from "vuetify/lib"
import {Component,Prop,Watch,PropSync} from "nuxt-property-decorator"


@Component({
           components:{VIcon}
})
export default class PreviewPanel extends Vue {
    @Prop({required:true}) readonly id!:string
    @Prop({default:512}) readonly size!:number
    error:Error|null =  null

    getUrl():string {
       return `/api/v1/documents/${this.id}/thumbnail/?size=${this.size}`
    }
    render(h:CreateElement){
        var imageUrl = this.getUrl();
        let {error} = this;
        let imgChild!:VNode;
        if(error || !this.id){
            imgChild = h(VIcon,{class:["large","py-10"],props:{size:50}},"far file")
        }else{
            imgChild = h("img",{ 
                domProps:{
                    src:imageUrl 
                },on:{
                    error: (err:Error) => {
                        this.error = err
                    }
                }
            })
        }
        return h("div",{
            class:["preview-panel"],
            style:{
            },
            props:{...this.$attrs},
            on:{...this.$listeners}
        },[
           imgChild 
        ])
    }

}
