/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $RangeClause = {
    properties: {
        lt: {
            properties: {
            },
        },
        gt: {
            properties: {
            },
        },
        gte: {
            properties: {
            },
        },
        lte: {
            properties: {
            },
        },
    },
} as const;
