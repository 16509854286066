/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $EtlStageState = {
    description: `Etl State State`,
    properties: {
        status: {
            type: 'all-of',
            contains: [{
                type: 'EtlStageStatus',
            }],
        },
        state: {
            properties: {
            },
        },
        row_estimate: {
            type: 'number',
        },
        data_errors: {
            type: 'number',
        },
        updated_at: {
            type: 'string',
            format: 'date-time',
        },
    },
} as const;
