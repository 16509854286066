/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $LoginRequestInput = {
    properties: {
        input: {
            type: 'LoginRequest',
            isRequired: true,
        },
    },
} as const;
