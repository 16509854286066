/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum CapitalActivityType {
    GENERIC = 'G',
    SETTLEMENT = 'S',
}
