/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $TwoFactorDevice = {
    description: `Two Factor Device`,
    properties: {
        name: {
            type: 'string',
            isRequired: true,
        },
        factor_id: {
            type: 'string',
            isRequired: true,
        },
        device_id: {
            type: 'string',
        },
    },
} as const;
