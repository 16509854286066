import {nestedSetter} from "~/utils/schema_funcs"
import isPlainObject from "lodash/isPlainObject"
import merge  from "lodash/merge"
import pick from "lodash/pick"
import { isValidNameError } from "graphql"

export interface ColSpec {
    xs?:number
    sm?:number
    md?:number
    lg?:number
    xl?:number
}
interface SchemaValueItem {
    type:string
    label?:string
    hint?:string
    tooltip?:string
    prefix?:string
    col?:ColSpec
}

interface SchemaArrayItem {
    type:"array"
    schema:SchemaRecords
}

type SchemaGroupType ="tabs"|"steps"|"panel"|"flat"
interface SchemaObjectItem {
    type:"group"
    schema:SchemaRecords
    group?: SchemaGroupType
    title?: string
}
export type SchemaItem = (SchemaValueItem|SchemaArrayItem|SchemaObjectItem) & Record<string,any>
export type SchemaRecords = Record<string,SchemaItem>


export interface SchemaGroup {
    label?:string
    path:string[] //Path to location in the model
    schemaPath?:string[] //Path as expressed
    type:SchemaGroupType
    items: SchemaRecords
    props?:Record<string,any>
    preamble?:string;
    title?:string
    titleProps?:Record<string,any>
    subTitle?:string
    bodyProps?:Record<string,any>
    schema?:SchemaRecords;
    collapsible?:boolean;
    defaultCollapsed?:boolean;
}
export const GROUP_COPY_FIELDS = ["label",
    "title", "subTitle",
    "collapsible","defaultCollapsed",
    "titleProps","props","preamble"]

export interface SchemaHooks {
    //called on an item
    onSchemaGroup(group:SchemaGroup):any;
    onSchemaItem(item:SchemaItem,name:string,path:string[],group:SchemaGroup):any;
    //Additional attributes for a schema node
    propsFor(item:SchemaItem,name:string,path:string[],group:SchemaGroup):Record<string,any>;
    classFor(item:SchemaItem,name:string,path:string[],group:SchemaGroup):Record<string,any>;
    groupPropsFor(group:SchemaGroup):Record<string,any>;
    /*//Produce a value for a field
    valueFor(value:any,name:string,item:SchemaItem,group:SchemaGroup):any;
    //Transform a value for a field
    valueTo(value:any,name:string,item:SchemaItem,group:SchemaGroup):any;
    */
}

export type ISchemaHooks = Partial<SchemaHooks>

export interface InputCompleteEvent<T>{
    $event:T
    $raw:any
    group:SchemaGroup
    key: keyof T
}


/**
 * Convert a schema into  a set of schema groups
 * that are individually forms that share the same model but might
 * focus on different sections
 *
 */
export function schema2Groups(schema:SchemaItem|SchemaRecords,
    hooks:ISchemaHooks,path:string[] = [],
    schemaPath:string[] = [],
    parentSchema:SchemaItem|null = null
):SchemaGroup[]{
    //console.log("Schema to Group",path,schema);
    function newGroup():SchemaGroup {
        return {
            type:"flat",path,
            items:{},
            get schema():SchemaRecords{
                return this.items;
            }
        }
    }
    let  lastGroup:SchemaGroup = newGroup()
    let items:SchemaGroup[] = [lastGroup]
    let namedGroups:Record<string,SchemaGroup>={}
    let isEmpty= (x:SchemaGroup) => Object.keys(x.items).length == 0;
    let nextGroup = () =>{
        if(isEmpty(lastGroup)) items.pop();
        lastGroup = newGroup()
        items.push(lastGroup);
        return lastGroup
    }
    /**
     * Merge a set of group into
     */
    let mergeGroup= (tgt:SchemaGroup,src:SchemaGroup[]) => {
        var toPush:SchemaGroup[]=[];
        /*
        if(tgt.path.length != 0){
            let newItems= {}
            let target = nestedSetter(newItems,tgt.path)
            if(!target["schema"]) target.schema = {};
            merge(target.items,tgt.items)
            //Object.entries(tgt.items).forEach(([name,value]) => target.schema[name]=value)
            //Object.assign(tgt,{items:newItems,path:[]})
        }*/
        if(src.length == 0) return;
        if (tgt.type == "flat" ){
            let grp = src[0]
            if(grp.type == "flat" ){
                //merging two flat groups we will process the paths
                let tgtNode:SchemaRecords= tgt.items as any;
                for(let idx=0;idx < grp.path.length;++idx){
                    //IF same path leve in both nothing to do
                    let p1 = tgt.path[idx]
                    let p2 = grp.path[idx];
                    if(p1 == p2) continue;
                    //Create nested group node
                    let newNode=  tgtNode[p2] as any;
                    if(!newNode){
                        newNode = {
                        };
                        tgtNode[p2] = newNode as any
                        tgtNode = newNode  as any
                    }
                }
                //At this point we are at a real thing so we will just update things
                merge(tgtNode,src[0].items);
                toPush=src.slice(1)
            }else {
                toPush = src
            }
       }else toPush=src;
        //Append the tail end items
        items.push(...toPush)
        lastGroup =items[items.length-1]
    }
    if(!schema) return []
    function processItem(name:string|null,item:SchemaItem){
        if(!item) return;
        switch(item.type){
            case "array":
                throw new Error("Arrays not supported yet")
                break
            case "wrap":
            case "group":
                {
                    let isNestedGroup =/^tabs|stepper$/.test(parentSchema?.group)
                    let groupType = item.group ?? "flat";
                    let wrapPath = name?schemaPath.concat([name]):schemaPath;
                    let grpPath = (name && item.type!="wrap")?path.concat([name]):path
                    let parts = schema2Groups(item.schema,{},grpPath,schemaPath,item)
                    //console.log("Recurse>",parts,items)
                    switch(groupType){
                        case "flat": //This gets merged into the current group
                            if(!isNestedGroup){
                                mergeGroup(lastGroup,parts)
                                break;
                            }
                            /**
                             * If we are in a nested group
                             * The Sub elements that are wraps deserve a top level node
                             */
                            if(name){
                                lastGroup.items[name] = {
                                    ...pick(item,GROUP_COPY_FIELDS),
                                    type:item.type,
                                    schema: parts[0].items
                                }
                                if(parts.length  == 1) break
                                parts = parts.slice(1); //Handle any extra groups after
                                break;
                            }
                        default:
                            //Non flat group is created a we merge the first item into this last group
                            nextGroup()
                            mergeGroup(lastGroup,parts)
                            lastGroup.type  = groupType
                            lastGroup.path =grpPath;
                            lastGroup.schemaPath = wrapPath;
                            Object.assign(lastGroup,pick(item,GROUP_COPY_FIELDS))
                            lastGroup.label=  item.label
                            nextGroup(); //New Flat Group
                    }
                }
                break
            default:
            //Add item to the current group
            if(!name) console.log("Processing ",item)
            lastGroup.items[name || "_root_"]={...item}
        }
        //console.info("Processing",path,name,lastGroup)
    }
    if("type" in schema){
        processItem(null,schema as SchemaItem)
    }else{
        Object.entries(schema).forEach(([name,item]) => {
            processItem(name,item)
        })
    }
    //Once all the items are together  we will trigger hooks
    //
    function processItemHook(schema:SchemaItem,name:string,path:string[],grp:SchemaGroup):SchemaItem{
        let item = schema;
        let props = hooks.propsFor?.(schema,name,path,grp)
        let updateItem=(update:any):void => {
            if(item === schema) item = {...schema}
            Object.assign(item,update)
        }
        if(props) updateItem(props)
        let clazz = hooks.classFor?.(schema,name,path,grp)
        if(clazz) updateItem({'class':clazz})
        //Handle wrap recursively
        if(schema.type == "wrap"){
            let subSchema = (schema as any).schema
            for(let name in subSchema){
                let elt = subSchema[name]
                let result = processItemHook(elt,name,path,grp)
                if(result !== elt){
                    if(item.schema === subSchema){
                        updateItem({schema:{...subSchema}})
                    }
                    item.schema[name]=result;
                }
            }
        }
        return item
    }

    // Remove all the empty groups
    items =items.filter(x => !isEmpty(x))
    for(let grp of items){
        let {path,items:grpItems} = grp
        hooks.onSchemaGroup?.(grp)
        function processItemHooksObj(items:Record<string,any>,path:string[]):Record<string,any>{
            for(let name in items){
                let schema =items[name];
                //Node has a type so we will assume its an item
                let hasType = schema.type;
                let isNestedObj = isPlainObject(schema);
                if(hasType || !isNestedObj){
                    let result = processItemHook(schema,name,path,grp)
                    if(result){
                        items[name]= result;
                    }
                }else {
                    //Node without a type could be a nested object .
                    items[name]=processItemHooksObj(schema,path.concat([name]))
                }
            }
            return items
        }
        processItemHooksObj(grpItems,path);
    }
    //console.log("Final COnfig ->",items)
    return items;
}




