
import {ApiDocument,InputApiDocument, DocumentsService,SimpleDocumentUrl, ApiDocumentLinkUpdates} from "~/schemas/gen"
import {ApiEntityNode} from "~/core/entity"
import gql from "graphql-tag"
import Vue, {shallowRef,ref,Ref,triggerRef,computed} from "vue"
import {Store} from "vuex/types/index"
import axios from "axios"
import type * as Papa from "papaparse"
import type {Parser,ParseResult} from "papaparse"
import {useGtag} from "~/plugins/auth.gtag.client"
import {getBaseUrl} from "~/plugins/api-axios-setup"
import {DocumentResponseFilter} from "./filtering"
import {DataTableHeader} from "vuetify"


export type HeaderFilter = (headers:DataTableHeader[]) => DataTableHeader[];


export interface DocumentType{
    name:string
    mime:string
    extensions:string[]
}

export type ApiDocumentNode = Partial<ApiDocument> & {
    document_type?:DocumentType
    owner?:ApiEntityNode
}

export interface SplitDataFrame {
    columns:string[]
    index:any[]
    data:any[][]
    aggregates?:Record<string,any>
}

/**
 * UploadFlowInput -
 *
 */
export type UploadFlowInput = InputApiDocument & {
    file?:File
    universe?:string
}

export const fragDocumentFields =  gql`
fragment ApiDocumentFields on entity_documents {
    uuid
    name storage_size flat_tags version_number
    created_time updated_time accessed_time
    document_type { name  mime extensions }
}
`


const  UPLOAD_MIMES = ".pdf,.doc,.docx,.eml,.txt,.ppt,.pptx,.xlsx,.xls,.csv,image/*"
const SUPPORTED_MIMES = [
    "image/png","image/jpg","image/jpeg","image/gif","image/bmp",
    "application/pdf",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "text/plain","text/csv",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.oasis.opendocument.presentation",
    "application/vnd.oasis.opendocument.spreadsheet",
    "application/vnd.oasis.opendocument.text",
    "application/octet-stream"
]

interface IPickFileProps{
    mimes?:string
}

export async function pickDocumentFile(opts?:IPickFileProps):Promise<File|undefined>{
    return new Promise((resolve,reject) =>{
        var upd = document.createElement("input")
        upd.type="file"
        upd.accept = opts?.mimes || UPLOAD_MIMES

        upd.onclick = () => {
            upd.onfocus = () => {
                if(upd.value.length == 0){
                    reject();
                }
            }
        }
        upd.onchange= e => {
            resolve(upd.files![0])
        }
        upd.click();
    })
}

export const  DISTINCT_ICONS = {
    "fas file-excel":["xls","xlsx"],
    "fas file-word":["doc","docx"],
    "fas file-powerpoint":["ppt","pptx"],
    "fas file-image":["png","gif","bmp","jpg","jpeg"],
    "fas file-pdf":["pdf"],
    "fas file-csv":["csv"]
}

/**
 * Icon for a particular file type
 */
const ICON_FILETYPE_MAP:Record<string,string> = Object.entries(DISTINCT_ICONS)
    .reduce((prevValue:Record<string,string>,[icon,extensions]) => {
    for(let ext of extensions){
        prevValue[ext] = icon
    }
    return prevValue
},{})


export function iconFor(x:DocumentType|string):string {
    let extensions = typeof x ==="string"?[x]:x.extensions
    for(let e of extensions){
        let  icon = ICON_FILETYPE_MAP[e]
        if(icon) return icon
    }
    return "fas file"
}


export function nameFor(x:ApiDocument):string {
    let prefix = "" ;
    let {parent} = x
    if(parent){
        if( !parent.is_folder) prefix =parent.name + "#"
    }
    return  prefix + x.name
}

export function supportedFile(f:File):boolean {
    let parts = f.name.split(".");
    return supportedExtension(parts.pop()!)
}

export function supportedKind(f:DataTransferItem):boolean {
    return supportedMime(f.type)
}


export function supportedMime(mime:string):boolean{
    return  SUPPORTED_MIMES.indexOf(mime) > -1
}

/**
 * Is this file extension supported
 */
export function supportedExtension(x:string):boolean {
    return x in  ICON_FILETYPE_MAP
}


/**
 * Manages Selection behaviour
 */
export class SelectionSet{
    items:string[] = []

    reset(){
        this.items = []
    }
    get first(){
        return this.items[0]
    }
    ///Select only a single item
    selectOnly(id:string){
        this.items=  [id]
    }
    //Toggle selection
    toggleSelected(id:string,$event?:MouseEvent){
        try{
            let idx =this.items.indexOf(id)
            let multiple = $event?.shiftKey
            if(multiple){
                try{
                if( idx == -1){
                    this.items.push(id)
                    return
                }
                this.items.splice(idx,1)
                return
                }finally {
                    //In multiple selection remove the default browser selection
                    document.getSelection()?.removeAllRanges?.()
                }
            }
            if(idx  == -1) {
                this.items = [id]
            } else {
                this.items = []
            }
        }catch(e){
        }finally{
            if($event){
                $event.preventDefault()
                $event.stopPropagation()
            }
        }
    }
    isSelected(id:string):boolean {
        return this.items.indexOf(id)  != -1
    }
}


//Get a blob url from a documents contents

export async function blobForDocument(uuid:string,$store:Store<any>,slice?:number):Promise<Blob> {
     let url:SimpleDocumentUrl|Error = await $store.dispatch("documents/view",{uuid}).catch(err =>err)
    if(url instanceof Error){
        throw Object.assign(url,{stage:"resolve"})
    }

    // get slice information form the backent (this is the first few lines)

    let headers:any = {}
    if(slice){
        let previewSlice = /*tabular.value?.preview_bytes ?? */ slice
        headers.Range = `bytes=0-${previewSlice}`
    }

    //Make a new Axios client because we want to not have the usual authentication infomraiton passed
    let priv = axios.create()
    let data =  await priv.get(url.url,{
        responseType:"blob",
        headers
    }).catch(err => err)
    if(data instanceof Error){
        throw Object.assign(data,{stage:"download"})
    }

    return data.data
}


export async function getParser():Promise<typeof Papa>{
    return import("papaparse")
}

/**
 * Generate a SplitDataFrame from a csv documents content
 */
export async function getCsvDocument(uuid:string,$store:Store<any>):Promise<SplitDataFrame> {
    let data  = await blobForDocument(uuid,$store,1024*10).catch(err => err)
    //Get csv parser and parse the data
    let parser= await getParser();
    let result:ParseResult<any> = await new Promise((resolve,reject) => {
        parser.parse(data,{
            header:false,
            skipEmptyLines:true,
            complete:resolve,
            error:reject
        })
    })
    return {
        columns:result.data[0] as string[],
        index:[],
        data:result.data.slice(1)
    }
}

/**
 * Initial a download of a document
 */
export async function onDownloadDocument(item:Pick<ApiDocumentNode,"uuid">,$store:Store<any>,
                                         eventOptions?:Record<string,any>,
                                         viewOptions?:Record<string,any>) {
    let wnd = window.open('','_blank')
    const $gtag = useGtag()
    eventOptions =  eventOptions || {}
    try {
        let res:SimpleDocumentUrl = await $store.dispatch("documents/view",{uuid:item.uuid,
        ...(viewOptions||{})})
        if(wnd) wnd.location.href =  res.url
        else{
            throw new Error("Unable to open window!")
        }
        $gtag.event("document/download",{...eventOptions})
    }catch(err){
        if(wnd) wnd.close()
        console.error("Failed to Download document",err)
        $gtag.event("document/download/error",{
            error:`${err}`,
            ...eventOptions
        })

    }
}


export function getStableDocumentUrl(docId:string):string {
  return getBaseUrl() + `/documents/${docId}/file/latest/`
}

export function isPopoverSupported(doc:InputApiDocument|ApiDocument|ApiDocumentNode):boolean {
  switch(typeof doc.document_type){
    case "string":
      switch(doc.document_type.toLowerCase()){
      case "application/pdf":
        return true;
    }
  }
  return false;
}

export type IDocumentSet  = {
  search:Ref<string>
  documents:Ref<ApiDocument[]>
  filter:DocumentResponseFilter
  isIncluded:(d:ApiDocument) => boolean
  toggleIncluded:(d:ApiDocument) => void
  embeddedProps:Ref<Record<string,any>>
  embeddedOn:Record<string,any>
}

export type IDocumentSetOptions= {
  headerFilter?:HeaderFilter,
  onAdd?:(d:ApiDocument) => void
  onRemove?:(d:ApiDocument) => void
}
/**
 * A composable that manages the state of a bunch of document that are being gathered together.
 */
export function useDocumentSet(opts?:IDocumentSetOptions):IDocumentSet {
  //Documents we are managing
  const docs = shallowRef<ApiDocument[]>([]);
  //Search text
  const search = ref("")
  //Filter to capture search documents
  const filter:DocumentResponseFilter =  (items,req,resp) => {
   return items
  }

  //Selection headers
  const  defHeaders = (headers:DataTableHeader[]) => {
    return headers.flatMap((x,i) => {
      if(i == 0){
        return [x,{
          text:"",value:"document.included",sortable:false,width:35,
          cellClass:"text-no-wrap mx-0 px-0"
        }]
      }
      return [x]
    })
  }
  let selectionHeaders = defHeaders
  if(opts?.headerFilter) {
    selectionHeaders = (x) => {
      return opts.headerFilter!(defHeaders(x))
    }
  }
  return {
    search, documents:docs,
    filter,
    isIncluded(d:ApiDocument){
      return !!docs.value.find(x => x.uuid == d.uuid)
    },
    toggleIncluded(d:ApiDocument){
      let idx =docs.value.findIndex(x => x.uuid ==  d.uuid)
      try{
      if(idx == -1) {
        docs.value.push(d)
        docs.value = docs.value
        opts?.onAdd?.(d)
      } else {
        docs.value = docs.value.filter((_,i) => i!= idx)
        opts?.onRemove?.(d)
      }
      }finally{
        triggerRef(docs)
      }

    },
    embeddedOn:{
      "upload-complete":(d:ApiDocument) => {
        docs.value.push(d)
        triggerRef(docs)
      }
    },
    embeddedProps:computed(() => {
      return {
        search: search.value,
        searchDisabled: search.value == null ||  search.value.length == 0,
        headerFilter:selectionHeaders,
        explicitDocuments: (() => {
          //IF there is a search we return that
          if((search.value?.length ?? 0) > 0) return undefined
          return docs.value || []
        })(),
        resultFilter: filter
      }
    })
  }
}
