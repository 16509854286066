import {ErrorResponse } from "@apollo/client/link/error"
import {fromPromise } from "@apollo/client/link/utils"

/**
 * When there is a 
 */ 

let refreshPending:Promise<any>|null =  null;

const HEADER_X_ROOT = "x-distributary-root-token"

export default function (resp:ErrorResponse , $nuxt:Vue['$nuxt']):any {

    let { graphQLErrors, networkError, operation, forward } = resp;

    //REun a retry
    function retry():any{
        let cxt = operation.getContext();
        if(cxt["isRetry"]) return;
        //We will add a retry flag and rerun 
        const token = $nuxt.$apolloHelpers.getToken()
        operation.setContext({
            isRetry:true,
            headers:{
                ...cxt.headers,
                "Authorization":`Bearer ${token}`
            }
        })
        console.log("ISsuing Retry!",operation)
        return forward(operation)
    }
    if(!graphQLErrors){
        graphQLErrors = []
        let errors = (networkError as any)?.result?.[0]?.errors;

        if(errors && Array.isArray(errors)){
            graphQLErrors = errors;

        }
    }
    for(let e of graphQLErrors){
        if(e?.extensions?.path == '$' ){
            let code =e?.extensions?.code
            if(["invalid-headers","invalid-jwt"].indexOf(code)!= -1 ){
                let cxt = operation.getContext()
                if(cxt.isRetry)  continue;
                //If there is a handler for token refrehes we will use it 
                let handler = cxt["refresh.handler"]
                if(handler) return fromPromise(handler(resp)).flatMap((x:any) => x)
                //Standared refresh handling
                if(!refreshPending) {
                    console.log("Refresh Token due to Error Code ->",code)

                    refreshPending = $nuxt.$universe.refresh().then(x => {
                        console.log("Updated Via Refresh token")
                    }).catch(err => console.log("Refresh Error",err))
                    .finally(() => refreshPending = null )
                }
                return fromPromise(refreshPending).flatMap(() => retry())
            }
        }
    }
  console.error('Global error handler',arguments)
  console.error(graphQLErrors, networkError, operation, forward)

}
