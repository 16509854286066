/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UserInfo = {
    properties: {
        access_token: {
            type: 'string',
            isRequired: true,
        },
        user_id: {
            type: 'number',
        },
        user: {
            type: 'ApiUser',
        },
        system: {
            type: 'SystemInfo',
            isRequired: true,
        },
        firm: {
            type: 'ApiFirm',
        },
        firm_membership: {
            type: 'ApiFirmMember',
        },
        impersonator: {
            type: 'ApiUser',
        },
    },
} as const;
