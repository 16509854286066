/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiFirmSettings = {
    properties: {
        mfa_required: {
            type: 'boolean',
        },
        defaults: {
            properties: {
            },
        },
    },
} as const;
