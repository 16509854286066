/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $InputCustomEtlInstance = {
    description: `Custom ETL Instance Inputs`,
    properties: {
        states: {
            type: 'dictionary',
            contains: {
                type: 'InputEtlStageState',
            },
        },
        config: {
            properties: {
            },
        },
    },
} as const;
