/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $InputApiPartialEvent = {
    properties: {
        notes: {
            type: 'string',
        },
        description: {
            type: 'string',
            maxLength: 100,
        },
        event_data: {
            type: 'EventData',
        },
        advisor_note: {
            type: 'string',
        },
    },
} as const;
