/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $TimeSeriesQuery = {
    properties: {
        start_time: {
            type: 'any-of',
            contains: [{
                type: 'string',
                format: 'date-time',
            }, {
                type: 'string',
                format: 'date',
            }],
            isRequired: true,
        },
        end_time: {
            type: 'any-of',
            contains: [{
                type: 'string',
                format: 'date-time',
            }, {
                type: 'string',
                format: 'date',
            }],
        },
        interval: {
            properties: {
            },
        },
        limit: {
            type: 'number',
        },
        columns: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
    },
} as const;
