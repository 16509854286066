
import {FilterSpec} from "~/core/common/filtering"
import {dataCache} from  "~/schemas/enrichment"
import {EVENT_TYPE_CHOICES} from "~/schemas/event_schemas"



export const EventFilterSpec:FilterSpec[] = [
    {
        label:"Event Type",
        field:"event_type",
        component:"select",
        alias:"typ",
        multiple:true,
        props:{
            chips:true, multiple:true,
            clearable:true,
            itemText:"title",itemValue:"const",
            get items(){
                return EVENT_TYPE_CHOICES
            }
        }
    },{
        label:"Event Time",
        field:"instant",
        component:Date,
        alias:"ts",
        range:true
    },{
        label:"Created Time",
        field:"created_time",
        component:Date,
        range:true,
        alias:"created"
    },
    {
        label:"Root Event",
        field:"root_event.uuid",
        component:"InputEvent",
        alias:"root",
    },
    {
        label:"Except",
        field:"except",
        query_targets:["uuid"],
        component:"InputEvent",
        operator:"_neq",
        hidden:true
    },
]
