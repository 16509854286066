/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiDocumentLink = {
    properties: {
        uuid: {
            type: 'string',
            isRequired: true,
            format: 'uuid',
        },
        document_id: {
            type: 'string',
            isRequired: true,
            format: 'uuid',
        },
        attributes: {
            properties: {
            },
        },
        entity: {
            type: 'any-of',
            contains: [{
                type: 'ApiEntity',
            }, {
                type: 'string',
                format: 'uuid',
            }],
        },
        account: {
            type: 'any-of',
            contains: [{
                type: 'ApiAccount',
            }, {
                type: 'string',
                format: 'uuid',
            }],
        },
        transaction: {
            type: 'any-of',
            contains: [{
                type: 'ApiTransaction',
            }, {
                type: 'string',
                format: 'uuid',
            }],
        },
        prospect: {
            type: 'any-of',
            contains: [{
                type: 'ApiTransactionProspect',
            }, {
                type: 'string',
                format: 'uuid',
            }],
        },
        event: {
            type: 'string',
            format: 'uuid',
        },
        workflow: {
            type: 'string',
            pattern: '[0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26}',
        },
    },
} as const;
