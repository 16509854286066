import {Events_Bool_Exp,Events} from "~/models/graphql"
import {EventType} from "~/schemas/gen"
import {fragApiTransactionFields} from "~/components/input/Transaction.vue"
import gql from "graphql-tag"


export type ApiEventNode = Events

export const fragApiEventNodeFields = gql`
fragment ApiEventNodeFields on events {
    uuid event_type instant identifier
    created_time updated_time ordinal
}
`

export const fragApiEventNodeDeepFields = gql`
fragment ApiEventNodeDeepFields on events {
    ...ApiEventNodeFields
    parent_event {...ApiEventNodeFields}
    initial_transaction { ...ApiTransactionFields }
    child_transaction(limit:1){ ...ApiTransactionFields }
}
${fragApiEventNodeFields}
${fragApiTransactionFields}
`


export function eventSearchExp(rawSearch:string):Events_Bool_Exp {
    let clauses:Events_Bool_Exp[] = []
    let search= rawSearch?.trim?.();
    if(search && search != ""){
        search = "%" + search + "%"
        clauses.push(
            { identifier: {  _ilike:search} },
            { description: {  _ilike:search} }
        )
    }
    if(clauses.length ==0) return {}
    return {_or:clauses} 
}


export const EventTypeLabels = {
    [EventType.CAPITAL_CALL]:"Capital Call",
    [EventType.CAPITAL_DISTRIBUTION]:"Distribution",
    [EventType.CAPITAL_ACTIVITY]:"Activity/Settlement",
    [EventType.NEW_TRANSACTION]:"New Transaction",
    [EventType.NOTE]:"Note",
    [EventType.ACCOUNT_UPDATE]:"Account Update",
    [EventType.ENTITY_UPDATE]:"Entity Update"
}

export function labelForEventType(t:EventType):string|undefined {
    return EventTypeLabels[t] 
}


import {InputApiEvent,ApiEvent } from "~/schemas/gen"
import {InputDocWithFile} from "~/core/documents/uploads"

export interface EventGuideState { 
    input: Partial<InputApiEvent & ApiEvent>;
    prospect_id:string|null,
    documents:InputDocWithFile[] 
    manual_mode:boolean|null
    state:Record<string,any>
    settlement?:Partial<InputApiEvent & ApiEvent>;
}


