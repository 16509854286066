/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiEvent } from '../models/ApiEvent';
import type { DeleteResponse } from '../models/DeleteResponse';
import type { EventSummaryResponse } from '../models/EventSummaryResponse';
import type { InputApiEvent } from '../models/InputApiEvent';
import type { InputApiPartialEvent } from '../models/InputApiPartialEvent';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EventsService {

    /**
     * Add Event
     * Add An Event
     * @param requestBody
     * @param commit
     * @param host
     * @returns ApiEvent Successful Response
     * @throws ApiError
     */
    public static addEvent(
        requestBody: InputApiEvent,
        commit: boolean = false,
        host?: string,
    ): CancelablePromise<ApiEvent> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/events/',
            headers: {
                'host': host,
            },
            query: {
                'commit': commit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event
     * @param id
     * @param wantRoot
     * @param host
     * @returns ApiEvent Successful Response
     * @throws ApiError
     */
    public static getEvent(
        id: string,
        wantRoot: boolean = false,
        host?: string,
    ): CancelablePromise<ApiEvent> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/events/{id}',
            path: {
                'id': id,
            },
            headers: {
                'host': host,
            },
            query: {
                'want_root': wantRoot,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Event
     * Delete The event
     * @param id
     * @param toDelete
     * @param host
     * @returns DeleteResponse Successful Response
     * @throws ApiError
     */
    public static deleteEvent(
        id: string,
        toDelete: any,
        host?: string,
    ): CancelablePromise<DeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/events/{id}',
            path: {
                'id': id,
            },
            headers: {
                'host': host,
            },
            query: {
                'toDelete': toDelete,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Event Summary
     * @param id
     * @param host
     * @returns EventSummaryResponse Successful Response
     * @throws ApiError
     */
    public static getEventSummary(
        id: string,
        host?: string,
    ): CancelablePromise<EventSummaryResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/events/{id}/summary',
            path: {
                'id': id,
            },
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Root Event
     * @param id
     * @param host
     * @returns ApiEvent Successful Response
     * @throws ApiError
     */
    public static getRootEvent(
        id: string,
        host?: string,
    ): CancelablePromise<ApiEvent> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/events/{id}/root',
            path: {
                'id': id,
            },
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Event Fragment
     * Update a fragment of an event
     * @param uuid
     * @param requestBody
     * @param commit
     * @param host
     * @returns ApiEvent Successful Response
     * @throws ApiError
     */
    public static updateEventFragment(
        uuid: string,
        requestBody: InputApiPartialEvent,
        commit: boolean = false,
        host?: string,
    ): CancelablePromise<ApiEvent> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/events/{uuid}/fragment',
            path: {
                'uuid': uuid,
            },
            headers: {
                'host': host,
            },
            query: {
                'commit': commit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Event
     * Update an Event
     * @param uuid
     * @param requestBody
     * @param commit
     * @param host
     * @returns ApiEvent Successful Response
     * @throws ApiError
     */
    public static updateEvent(
        uuid: string,
        requestBody: InputApiEvent,
        commit: boolean = false,
        host?: string,
    ): CancelablePromise<ApiEvent> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/events/{uuid}',
            path: {
                'uuid': uuid,
            },
            headers: {
                'host': host,
            },
            query: {
                'commit': commit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
