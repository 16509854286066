/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiInstrument } from '../models/ApiInstrument';
import type { ApiObservableType } from '../models/ApiObservableType';
import type { InstrumentListRequest } from '../models/InstrumentListRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DataService {

    /**
     * Currencies List
     * @param host
     * @returns ApiInstrument Successful Response
     * @throws ApiError
     */
    public static currenciesList(
        host?: string,
    ): CancelablePromise<Array<ApiInstrument>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/data-types/currencies',
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Asset Type List
     * @returns any Successful Response
     * @throws ApiError
     */
    public static assetTypeList(): CancelablePromise<Array<any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/data-types/assets/types',
        });
    }

    /**
     * Identifier Type List
     * @returns any Successful Response
     * @throws ApiError
     */
    public static identifierTypeList(): CancelablePromise<Array<any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/data-types/identifiers/types',
        });
    }

    /**
     * Instrument Type List
     * @returns any Successful Response
     * @throws ApiError
     */
    public static instrumentTypeList(): CancelablePromise<Array<any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/data-types/instruments/types',
        });
    }

    /**
     * Instrument List
     * Return a list of instrument that the user can access
     * @param prefix
     * @param requestBody
     * @param offset
     * @param pageSize
     * @param host
     * @returns ApiInstrument Successful Response
     * @throws ApiError
     */
    public static instrumentList(
        prefix: string,
        requestBody: InstrumentListRequest,
        offset?: number,
        pageSize: number = 10,
        host?: string,
    ): CancelablePromise<Array<ApiInstrument>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/data-types/instruments',
            headers: {
                'host': host,
            },
            query: {
                'prefix': prefix,
                'offset': offset,
                'page_size': pageSize,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Tag List
     * @param prefix
     * @param src
     * @param offset
     * @param pageSize
     * @param host
     * @returns string Successful Response
     * @throws ApiError
     */
    public static tagList(
        prefix: string,
        src?: ('events' | 'root_events' | 'entities' | 'accounts'),
        offset?: number,
        pageSize: number = 10,
        host?: string,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/data-types/tags',
            headers: {
                'host': host,
            },
            query: {
                'prefix': prefix,
                'src': src,
                'offset': offset,
                'page_size': pageSize,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Observable Types
     * @returns ApiObservableType Successful Response
     * @throws ApiError
     */
    public static observableTypes(): CancelablePromise<Array<ApiObservableType>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/data-types/observables/types',
        });
    }

}
