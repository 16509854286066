/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ObservableTypes {
    POSITION = 'std.position',
    PRICE = 'std.price',
    STATEMENT_BALANCE = 'std.statement.balance',
    STATEMENT_PERIOD = 'std.statement.period',
    SYMBOL_MAPPING = 'std.symbol.mapping',
}
