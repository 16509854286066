/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DocumentSearchLinkClause = {
    properties: {
        type: {
            type: 'string',
        },
        uuid: {
            type: 'string',
        },
        is_workflow_input: {
            type: 'boolean',
        },
        is_workflow_output: {
            type: 'boolean',
        },
    },
} as const;
