import Vue from "vue"
import {DataOptions} from "vuetify"

class UISettings extends Vue {
    get activeLocale():string {
        return 'en-US'
    }
}

const uiSettings= new UISettings()


export const defaultDataOptions:DataOptions = {
    page:1,
    itemsPerPage:10,
    sortBy:[],
    sortDesc:[],
    groupBy:[],
    groupDesc:[],
    mustSort:false,
    multiSort:false
}


export const NAV_HEIGHT =  36;

export default uiSettings;
