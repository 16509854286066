/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum NetWorthFeatureId {
    TOTALPOSITIONVALUE = 'total_position_value',
    POSITIONCOUNT = 'position_count',
}
