/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiDocumentInfo = {
    properties: {
        pages: {
            type: 'number',
        },
        structure: {
            type: 'ApiDocStructure',
        },
        origin: {
            type: 'ApiDocFragmentOrigin',
        },
    },
} as const;
