/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $FileInlineMapping = {
    description: `Reference to the key of an advisor Note`,
    properties: {
        defn: {
            type: 'ImportMappingIdea',
            isRequired: true,
        },
    },
} as const;
