import {Component,Prop,ProvideReactive,Vue } from "nuxt-property-decorator"



@Component({})
export default class ErrorContext  extends Vue {
    @Prop() context!:any;

    @ProvideReactive() 
    get errorContext (){
        return  this.context
    }

    render(h:typeof Vue.prototype.$createElement){
        return h('div',{},this.$scopedSlots?.default?.({}) || [])
    }
}
