/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SafeDetails = {
    detail_type?: SafeDetails.detail_type;
    valuation_cap?: number;
    valuation_cap_unit_id?: string;
    discount?: number;
    post_money?: boolean;
    cc_common?: boolean;
    cc_preferred?: boolean;
    cc_unissued?: boolean;
    cc_preferred_unissued?: boolean;
    cc_converts?: boolean;
    cc_options?: boolean;
    cc_promised_options?: boolean;
    cc_options_pool?: boolean;
    cc_option_pool_increases?: boolean;
    cc_warrants?: boolean;
};

export namespace SafeDetails {

    export enum detail_type {
        SAFE = 'SAFE',
    }


}

