/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $EventData = {
    description: `    `,
    properties: {
        nt: {
            type: 'NewTransactionEventData',
        },
    },
} as const;
