/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiDocument = {
    description: `ApiDocument - `,
    properties: {
        name: {
            type: 'string',
            isRequired: true,
        },
        document_type: {
            type: 'string',
            isRequired: true,
        },
        created_time: {
            type: 'string',
            isRequired: true,
            format: 'date-time',
        },
        updated_time: {
            type: 'string',
            isRequired: true,
            format: 'date-time',
        },
        accessed_time: {
            type: 'string',
            isRequired: true,
            format: 'date-time',
        },
        storage_size: {
            type: 'number',
            isRequired: true,
        },
        version_number: {
            type: 'number',
            isRequired: true,
        },
        uploader_id: {
            type: 'string',
            format: 'uuid',
        },
        info: {
            type: 'ApiDocumentInfo',
        },
        uuid: {
            type: 'string',
            isRequired: true,
            format: 'uuid',
        },
        is_folder: {
            type: 'boolean',
            isRequired: true,
        },
        owner_id: {
            type: 'string',
            format: 'uuid',
        },
        flat_tags: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        parent_id: {
            type: 'string',
            format: 'uuid',
        },
        acl: {
            type: 'ApiPermissions',
        },
        is_pending: {
            type: 'boolean',
        },
        links: {
            type: 'array',
            contains: {
                type: 'ApiDocumentLink',
            },
        },
        parent: {
            type: 'ParentFragment',
        },
        child_count: {
            type: 'number',
        },
        children: {
            type: 'array',
            contains: {
                type: 'ApiDocument',
            },
        },
        workflows: {
            type: 'array',
            contains: {
                type: 'ApiWorkflow',
            },
        },
        fragment_page: {
            type: 'number',
        },
    },
} as const;
