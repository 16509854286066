/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ProfileResponse = {
    properties: {
        user: {
            type: 'ApiUser',
            isRequired: true,
        },
        system: {
            type: 'SystemInfo',
            isRequired: true,
        },
        impersonator: {
            type: 'ApiUser',
        },
        secure_deadline: {
            type: 'number',
        },
        mfa_secure_deadline: {
            type: 'number',
        },
        root_access_token: {
            type: 'string',
        },
        firm: {
            type: 'ApiFirm',
        },
        firm_membership: {
            type: 'ApiFirmMember',
        },
    },
} as const;
