import vue,{CreateElement,VNode, defineComponent} from "vue"
import {VIcon,VBtn,VHover,VMenu} from "vuetify/lib"
import { Location } from "vue-router";
import merge from "lodash/merge"
import { DocumentNode } from "graphql";
import {universeAware} from "../routing";

/**
 * Generic Exploration Link that is only visible on hover
 */
export function itemExplorationLink(h:CreateElement,route:Location,
    item:VNode):VNode {
        //Make exploiration universe aware
        route = universeAware(route)
        return h(VMenu,{
            props:{
                rounded:"pill",
                "open-on-hover":true,top:true,"offset-y":true,
                "open-delay":1000,"close-delay":200
            },
            scopedSlots: {
                default: () => {
                    let icon =h(VIcon,{class:["mr-2"],props:{small:true,size:20}},
                        "fas external-link-alt")
                    let openItem = h(VBtn,{
                        props:{icon:true,fab:true,small:true,
                            to:route,target:"_blank",
                            class:["text-center"]},
                        attrs:{title:"View item",rel:"opener"}
                    },[icon])
                    return h("div",{
                        class:["py-1","px-1","white"],
                    },
                        [openItem])
                },
                activator:(props) => {
                    return h(item.tag,merge({},item.data,{
                        props:props.props,
                        on:props.on
                    }),item.children)
                }
            }
        })
    }



export interface PartialQuery{
    query:DocumentNode|(() => DocumentNode);
    variables:(search:string) => any;
    update:(data:any) => any;
    context?:any;
    clientId?:any;
}
