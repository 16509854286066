/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiUserAccessGrant = {
    properties: {
        grantee: {
            type: 'string',
            isRequired: true,
        },
        standard_permissions: {
            type: 'AccessPermissions',
            isRequired: true,
        },
        last_accessed: {
            type: 'string',
            format: 'date-time',
        },
        role: {
            type: 'AccessGrantRole',
        },
        client_identifier: {
            type: 'string',
        },
    },
} as const;
