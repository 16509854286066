/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiObservable } from '../models/ApiObservable';
import type { BatchImportResponse_ApiObservable_ } from '../models/BatchImportResponse_ApiObservable_';
import type { BatchRequest_InputApiObservable_ } from '../models/BatchRequest_InputApiObservable_';
import type { InputApiObservable } from '../models/InputApiObservable';
import type { ListResponse_InputApiObservable_ } from '../models/ListResponse_InputApiObservable_';
import type { ListResponse_ObservableSummaryItem_ } from '../models/ListResponse_ObservableSummaryItem_';
import type { ObservableSummaryRequest } from '../models/ObservableSummaryRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ObservableService {

    /**
     * Add Observable
     * Add an Observable Data Element
     * @param requestBody
     * @param commit
     * @param host
     * @returns ApiObservable Successful Response
     * @throws ApiError
     */
    public static addObservable(
        requestBody: InputApiObservable,
        commit: boolean = false,
        host?: string,
    ): CancelablePromise<ApiObservable> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/observable/',
            headers: {
                'host': host,
            },
            query: {
                'commit': commit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Bulk Observable Create
     * @param requestBody
     * @param host
     * @returns BatchImportResponse_ApiObservable_ Successful Response
     * @throws ApiError
     */
    public static bulkObservableCreate(
        requestBody: BatchRequest_InputApiObservable_,
        host?: string,
    ): CancelablePromise<BatchImportResponse_ApiObservable_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/observable/bulk/create',
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Observable
     * @param id
     * @param host
     * @returns ApiObservable Successful Response
     * @throws ApiError
     */
    public static getObservable(
        id: string,
        host?: string,
    ): CancelablePromise<ApiObservable> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/observable/{id}',
            path: {
                'id': id,
            },
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Observable
     * @param id
     * @param requestBody
     * @param commit
     * @param host
     * @returns ApiObservable Successful Response
     * @throws ApiError
     */
    public static updateObservable(
        id: string,
        requestBody: InputApiObservable,
        commit: boolean = false,
        host?: string,
    ): CancelablePromise<ApiObservable> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/observable/{id}',
            path: {
                'id': id,
            },
            headers: {
                'host': host,
            },
            query: {
                'commit': commit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Summarize
     * @param requestBody
     * @param host
     * @returns ListResponse_ObservableSummaryItem_ Successful Response
     * @throws ApiError
     */
    public static summarize(
        requestBody: ObservableSummaryRequest,
        host?: string,
    ): CancelablePromise<ListResponse_ObservableSummaryItem_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/observable/summary',
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Find Symbol Mappings
     * Run one or more searches in the context of the an observable
     * @param id
     * @param query
     * @param cacheOnly
     * @param host
     * @returns ListResponse_InputApiObservable_ Successful Response
     * @throws ApiError
     */
    public static findSymbolMappings(
        id: string,
        query?: Array<string>,
        cacheOnly: boolean = true,
        host?: string,
    ): CancelablePromise<ListResponse_InputApiObservable_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/observable/{id}/search_symbols',
            path: {
                'id': id,
            },
            headers: {
                'host': host,
            },
            query: {
                'query': query,
                'cacheOnly': cacheOnly,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
