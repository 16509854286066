import {ApiUser,ApiProfile} from "~/schemas/gen"
import {useStore} from "~/plugins/composition";
import type {Store} from "vuex/types/index";


export function useUser($store?:Store<any>){
    $store = $store || useStore()!
    const user = $store.$auth.user  as ApiUser
    return {
        user,
        get profile(){ return user?.profile },
        get impersonator() {
            return $store!.getters['user/impersonator']  as ApiUser
        },
        get isAdviser(){ return $store?.getters['user/isAdviser'] },
        get isAdmin(){
            return user.is_staff || this.impersonator?.is_staff
        }
    }
}
