/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum LineItemCategory {
    CUSTOM = 'Custom',
    OPENFX = 'OpenFx',
    LIVEFX = 'LiveFx',
    CASH = 'Cash',
    DEBT = 'Debt',
    TV = 'TV',
    FLOW = 'Flow',
    TOTAL = 'Total',
}
