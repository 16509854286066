/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ClientAccountLine = {
    properties: {
        uuid: {
            type: 'string',
            isRequired: true,
            format: 'uuid',
        },
        name: {
            type: 'string',
            isRequired: true,
        },
        workflow_ulid: {
            type: 'string',
            pattern: '[0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26}',
        },
        period_end_date: {
            type: 'string',
        },
        period_value: {
            type: 'any-of',
            contains: [{
                type: 'number',
            }, {
                type: 'number',
            }, {
                type: 'number',
            }],
        },
        acct_number: {
            type: 'string',
        },
        status: {
            type: 'string',
        },
        flags: {
            type: 'number',
        },
        workflow_identifier: {
            type: 'string',
        },
        latest_reports: {
            type: 'array',
            contains: {
                type: 'string',
                format: 'uuid',
            },
        },
        confirmed_reports: {
            type: 'array',
            contains: {
                type: 'string',
                format: 'uuid',
            },
        },
    },
} as const;
