/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $InstrumentRename = {
    description: `Update to change the name of an instrument`,
    properties: {
        name: {
            type: 'string',
            isRequired: true,
        },
    },
} as const;
