/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum SignupErrorCode {
    INVALID_PASSWORD = 'invalid-password',
    ALREADY_EXISTS = 'already-exists',
    INVALID_EMAIL = 'invalid-email',
    INTERNAL_ERROR = 'internal-error',
    LIKELY_ROBOT = 'likely-robot',
}
