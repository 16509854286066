/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $MfaToken = {
    description: `An MFA Token`,
    properties: {
        device_id: {
            type: 'string',
            isRequired: true,
        },
        token: {
            type: 'string',
            isRequired: true,
        },
    },
} as const;
