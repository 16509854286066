/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $StatusResponse = {
    description: `Generic Status Response`,
    properties: {
        status: {
            type: 'boolean',
            isRequired: true,
        },
        message: {
            type: 'string',
            isRequired: true,
        },
        status_code: {
            type: 'number',
        },
    },
} as const;
