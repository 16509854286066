/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $PasswordChangeRequest = {
    properties: {
        token: {
            type: 'string',
            isRequired: true,
        },
        password: {
            type: 'string',
            isRequired: true,
            format: 'password',
        },
        is_current_password: {
            type: 'boolean',
        },
    },
} as const;
