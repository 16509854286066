/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiCustomEtlRun } from '../models/ApiCustomEtlRun';
import type { ApiWorkflowInputSchema } from '../models/ApiWorkflowInputSchema';
import type { ImportMappingIdea } from '../models/ImportMappingIdea';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EtlService {

    /**
     * Start Run
     * @param ulid
     * @param host
     * @returns ApiCustomEtlRun Successful Response
     * @throws ApiError
     */
    public static startRun(
        ulid: string,
        host?: string,
    ): CancelablePromise<ApiCustomEtlRun> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/workflows/{ulid}/etl/start',
            path: {
                'ulid': ulid,
            },
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Schedule Run
     * @param ulid
     * @param runId
     * @param host
     * @returns ApiCustomEtlRun Successful Response
     * @throws ApiError
     */
    public static scheduleRun(
        ulid: string,
        runId: string,
        host?: string,
    ): CancelablePromise<ApiCustomEtlRun> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/workflows/{ulid}/etl/{runId}/schedule',
            path: {
                'ulid': ulid,
                'runId': runId,
            },
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Schemas
     * Get the fully defined schema choices for a workflow
     * @param ulid
     * @param stageId
     * @param host
     * @returns ApiWorkflowInputSchema Successful Response
     * @throws ApiError
     */
    public static getSchemas(
        ulid: string,
        stageId: string,
        host?: string,
    ): CancelablePromise<Array<ApiWorkflowInputSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/workflows/{ulid}/etl/{stageId}/schemas',
            path: {
                'ulid': ulid,
                'stageId': stageId,
            },
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Mappings
     * Get the fully defined schema choices for a workflow
     * @param ulid
     * @param stageId
     * @param schemaId
     * @param host
     * @returns ImportMappingIdea Successful Response
     * @throws ApiError
     */
    public static getMappings(
        ulid: string,
        stageId: string,
        schemaId?: string,
        host?: string,
    ): CancelablePromise<Array<ImportMappingIdea>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/workflows/{ulid}/etl/{stageId}/mappings',
            path: {
                'ulid': ulid,
                'stageId': stageId,
            },
            headers: {
                'host': host,
            },
            query: {
                'schemaId': schemaId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Stop Run
     * @param ulid
     * @param runId
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static stopRun(
        ulid: string,
        runId: string,
        host?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/workflows/{ulid}/etl/{runId}/stop',
            path: {
                'ulid': ulid,
                'runId': runId,
            },
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Status
     * @param ulid
     * @param runId
     * @param host
     * @returns ApiCustomEtlRun Successful Response
     * @throws ApiError
     */
    public static getStatus(
        ulid: string,
        runId: (string | 'latest'),
        host?: string,
    ): CancelablePromise<ApiCustomEtlRun> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/workflows/{ulid}/etl/{runId}/status',
            path: {
                'ulid': ulid,
                'runId': runId,
            },
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Output
     * @param ulid
     * @param runId
     * @param stageId
     * @param outputName
     * @param fmt
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getOutput(
        ulid: string,
        runId: string,
        stageId: string,
        outputName: string,
        fmt: ('doc' | 'json' | 'json+split' | 'csv' | 'xlsx'),
        host?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/workflows/{ulid}/etl/{runId}/output',
            path: {
                'ulid': ulid,
                'runId': runId,
            },
            headers: {
                'host': host,
            },
            query: {
                'stageId': stageId,
                'outputName': outputName,
                'fmt': fmt,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
