
import {Component,Vue,Prop,mixins,PropSync } from "nuxt-property-decorator"
import {ApiDocument, InputApiDocument} from "~/schemas/gen"
import {SchemaHooks,SchemaGroup,SchemaItem} from "~/core/schemas/grouping"
import { InputCompleteEvent} from "~/components/input/SchemaForm.vue"
import {WithErrors,makeFullPath} from "~/components/utils/editing"
import {ValidationErrorMap} from "~/utils/validation"
import mapValues from "lodash/mapValues"


// @TODO: Add support for assigning document-type
const DOCUMENT_SCHEMA:Record<string,any> = {
    name: {
        type:"text",label:"Document Name",
            col:{md:6,xs:6}
    },
    flat_tags:{
        type:"text",label:"Tags",
            placeholder:"Enter tags separated by space or comma",
            prependIcon:"tags",
            hint:"Tag names",
            col:{md:6,xs:6 },
            toCtrl({value}:any){ return  toTagListString(value) },
            fromCtrl({value}:any){ return  fromTagListString(value) }
    }
}


@Component({
})
export default class DocumentView extends mixins(WithErrors) {
    @Prop(Object)  readonly value!:InputApiDocument|ApiDocument
    @Prop({type:Object,default(){ return {} }}) readonly errors!:ValidationErrorMap
    @Prop({type:Boolean,default:false}) readonly isEdit!:boolean
    @Prop({default:"Add Document"}) readonly title!:string
    @Prop({default:false}) readonly disabled!:boolean
    @Prop({default:null,type:[File,Blob]}) readonly file!:File|null
    @PropSync("tabModel",{
        required:false,
        default:"details"
    }) readonly parentTabModel!:string
    valid:boolean = false
    schema:any = DOCUMENT_SCHEMA

    get inputValue():any {
        let {value} = this;
        let flat_tags = toTagListString(value.flat_tags);
        return { 
            ...value,
            get flat_tags(){
                return flat_tags
            },
            set flat_tags(value:string){
                flat_tags = value;

            }
        }
    }
    get hasTabs():boolean {
        return "tabs" in this.$scopedSlots
    }
    get activeTab():string {
        if(this.hasTabs) return this.parentTabModel
        return "details"
    }

    get hasBack():boolean {
        return "back" in this.$listeners
    }
    //Is this document a child of anohter document
    get isChildDoc():boolean {
        let value = this.value  as ApiDocument
        return (value.parent && value.parent.is_folder == false) ?? false
    }

    // Take the detail Schema and try to find the 
    get schemaWithErrors(){
        return this.schema;
    }

    schemaPropsFor(item:SchemaItem,name:string,path:string[],group:SchemaGroup):Record<string,any> {

        let fullPath=makeFullPath(name,path) 
        let props:Record<string,any> = {}
        this.addErrorMessages(fullPath,props);
        if(this.disabled) props.disabled=true;
        let {value} = this
        switch(fullPath){
            case "name":
                {
                    let {parent} =  (value as ApiDocument)
                    if(parent && !parent.is_folder){
                        props.disabled=true;
                        props.prependIcon="lock"
                    }
                }
                break;

        }
        return props
    }

    async handleValueUpdate($event:InputCompleteEvent<InputApiDocument>) {
        let {key,$event:value,$raw} = $event
        /*if(key == "flat_tags"){
            let {flat_tags} = value
            value = Object.assign({},value,{flat_tags:fromTagListString(flat_tags)})
        }*/
        this.$emit("input", value)
    }

}


function toTagListString(flat_tags:string[]|undefined):string {
    if(!flat_tags) return ""
    if(!Array.isArray(flat_tags)) return flat_tags
    return flat_tags.join(", ");
}
function fromTagListString(flat_tags:string|string[]|undefined):string[] {
    switch(typeof(flat_tags)){
        case "string":
            return (flat_tags as string).trim().split(/\s*,[\s,]*/)
        case "object": 
            if(Array.isArray(flat_tags)) return flat_tags;
            default:
            return []
    }
}
