
import {Vue,Getter,Component,Watch,namespace} from "nuxt-property-decorator"

const SnackbarNs= namespace('snackbar')

@Component
export default class GlobalSnackbar extends Vue {
    body:string = ""
    title:string = ""
    input = false
    props:Record<string,any> = {}
    @SnackbarNs.Getter('lastMessage') lastMessage:any;

    @Watch('input')
    onInputChange(x:boolean){
        //When the snackbar is dismissed we will hide the props
        if(x == false){
            this.props = {}
        }
    }
    @Watch('lastMessage') 
    onNewMessage({body,title,props}:any){
        this.body=body
        this.props=props
        this.title=title
        this.input=true;
    }


    get textContent(){
        return this.body;
    }
}
