/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DateTimeFormat = {
    type: DateTimeFormat.type;
    format: string;
};

export namespace DateTimeFormat {

    export enum type {
        DATE = 'date',
    }


}

