/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $InputApiDocument = {
    description: `InputApiDocument - `,
    properties: {
        name: {
            type: 'string',
            isRequired: true,
            maxLength: 128,
            minLength: 1,
        },
        document_type: {
            type: 'string',
        },
        version_number: {
            type: 'number',
        },
        uploader_id: {
            type: 'string',
            format: 'uuid',
        },
        info: {
            type: 'ApiDocumentInfo',
        },
        storage_size: {
            type: 'number',
            isRequired: true,
        },
        uuid: {
            type: 'string',
            format: 'uuid',
        },
        owner_id: {
            type: 'string',
            format: 'uuid',
        },
        is_folder: {
            type: 'boolean',
        },
        flat_tags: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        is_pending: {
            type: 'boolean',
        },
        parent_id: {
            type: 'string',
            format: 'uuid',
        },
        acl: {
            type: 'ApiPermissions',
        },
        link_updates: {
            type: 'ApiDocumentLinkUpdates',
        },
    },
} as const;
