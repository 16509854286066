/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SystemInfo = {
    description: `Bits of system  information needed on the client side`,
    properties: {
        system_user_id: {
            type: 'number',
            isRequired: true,
        },
        admin_url: {
            type: 'string',
        },
    },
} as const;
