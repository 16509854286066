/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ImportSessionMeta = {
    properties: {
        saved: {
            properties: {
            },
        },
        headers: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        header_row: {
            type: 'number',
        },
        imported: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        document_id: {
            type: 'string',
            format: 'uuid',
        },
        filename: {
            type: 'string',
        },
        file_modified: {
            type: 'string',
            format: 'date-time',
        },
        target: {
            type: 'string',
        },
    },
} as const;
