/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum NotionalExchange {
    INITIAL = 'I',
    FINAL = 'F',
    BOTH = 'B',
}
