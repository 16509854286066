
import { Entities_Bool_Exp} from "~/models/graphql"
import {useUniverse} from "~/plugins/auth.universe"



export function entitySearchExp(search:string):Entities_Bool_Exp {
    let clauses:Entities_Bool_Exp[] = []
    let $universe = useUniverse();
    const univ = $universe.current
    if(univ){
        clauses.push({ user: { profile: {uuid:{_eq: univ.user.profile!.uuid}}}})
    }
    search=search.trim();
    if(search != ""){
        clauses.push({ _or:[
            { name: { _ilike:"%" + search+"%" }},
            { legal_name: { _ilike:"%" + search+"%" }},
        ]})
    }
    if(clauses.length ==0 ) return {}
    return {
        _and:clauses
    }
} 




