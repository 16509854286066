

export default function ({app,$auth}:any){
    //Event on login error
    $auth.onError((error:any,name:string,endpoint:string)=> {
        app.$gtag.event("login/error",{error,name,endpoint})
    })
}

export function useGtag(){
    return window.$nuxt.$gtag;
}
