/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $MfaChallengeResponse = {
    description: `MFA Challenge Response
    Indicates the list of devices `,
    properties: {
        email: {
            type: 'string',
            isRequired: true,
        },
        devices: {
            type: 'array',
            contains: {
                type: 'TwoFactorDevice',
            },
            isRequired: true,
        },
        message: {
            type: 'string',
        },
        code: {
            type: 'Enum',
        },
        token: {
            type: 'string',
        },
    },
} as const;
