/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UserSummaryError = {
    properties: {
        code: {
            type: 'string',
            isRequired: true,
        },
        message: {
            type: 'string',
            isRequired: true,
        },
        leg_id: {
            type: 'number',
        },
        identifier: {
            type: 'string',
        },
        metadata: {
            properties: {
            },
        },
    },
} as const;
