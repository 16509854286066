/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $InputApiWorkflow = {
    properties: {
        document_id: {
            type: 'string',
            format: 'uuid',
        },
        workflow_type_id: {
            type: 'any-of',
            contains: [{
                type: 'Workflows',
            }, {
                type: 'string',
            }],
            isRequired: true,
        },
        rel_account_id: {
            type: 'string',
            format: 'uuid',
        },
        status: {
            type: 'WorkflowStatus',
        },
        flags: {
            type: 'number',
        },
        heldaway: {
            type: 'InputHeldAwayReconciliation',
        },
        custom_etl: {
            type: 'InputCustomEtlInstance',
        },
    },
} as const;
