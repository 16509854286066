/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientSearchRequest } from '../models/ClientSearchRequest';
import type { ListResponse_ClientLineItem_ } from '../models/ListResponse_ClientLineItem_';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AdvisorService {

    /**
     * Clients List
     * @param requestBody
     * @param limit
     * @param offset
     * @param host
     * @returns ListResponse_ClientLineItem_ Successful Response
     * @throws ApiError
     */
    public static clientsList(
        requestBody: ClientSearchRequest,
        limit: number = 10,
        offset?: number,
        host?: string,
    ): CancelablePromise<ListResponse_ClientLineItem_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/clients/list',
            headers: {
                'host': host,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
