import Vue,{unref,provide} from 'vue'
import type {Context} from "@nuxt/types"
import { createApolloProvider } from '@vue/apollo-option'
import 'cross-fetch/polyfill'

import { ApolloClients, provideApolloClients,
    DefaultApolloClient} from '@vue/apollo-composable'
import { ApolloClient,
    ApolloLink,
    createHttpLink,
    InMemoryCache, split
} from '@apollo/client/core'
import {BatchHttpLink } from "@apollo/client/link/batch-http"
import { setContext } from '@apollo/client/link/context'
import { onError,ErrorResponse } from '@apollo/client/link/error'
import {ApolloHelpers} from "~/types/apollo.nuxtlike"
import {ApolloProviderOptions} from '@vue/apollo-option/types/apollo-provider'
import type { OpenAPIConfig } from "~/schemas/gen/core/OpenAPI"
import {AuthService,UserInfo} from "~/schemas/gen"

import  errorHandlerFunc from '~/plugins/apollo-error-handler'
import { SchemaPolicy } from '~/schemas/apollo.metadata'

/**
 * This plugin is so that the Vue 2.7 composition apollo code can be used.
 * Along with the Options code   @nuxtjs/apollo was not available as of nov-15-2022 for vue 2.7
 * Some Reference
 *https://github.com/vuejs/apollo/discussions/1382
 *
 */

const HEADER_X_CONST =  "x-distributary-const"

interface IApolloOptions {
    httpEndpoint:string
    tokenName:string
    validateToken:(_:string) => boolean
    getAuth:()=>string|Promise<string>
    cache:any
    apollo:any
    ssr:boolean
    defaultOptions?:any
    connectToDevTools?:boolean

}

let availableClients:Record<string,ApolloClient<any>> = {}

export default (ctx:Context, inject:any) => {
  const providerOptions = { clients: {} }
  const { app, beforeNuxtRender, req,env } = ctx
  const AUTH_TOKEN_NAME = 'apollo-token'
  const AUTH_TYPE = 'Bearer '

    // Config

    const defaultTokenName = ''  || AUTH_TOKEN_NAME

    function defaultGetAuth () {
        const token =  ctx.$universe.current?.access_token
        return token && defaultClientConfig.validateToken(token) ? AUTH_TYPE + token : ''
    }


    const clientConfig = () => {
        return {
            "httpEndpoint":  env.GRAPHQL_URL,
            validateToken: () => true,
            connectToDevTools:ctx.isDev,
            getAuth:defaultGetAuth,
            tokenName: defaultTokenName,
            cache:  new InMemoryCache({typePolicies:SchemaPolicy}),
            apollo: {
                $query: {
                    loadingKey: 'loading',
                    fetchPolicy: 'cache-and-network',
                }
            },
            ssr: !!process.server
        }
    }

    let defaultClientConfig:IApolloOptions = clientConfig()
    let rootClientConfig:IApolloOptions = Object.assign(clientConfig(),{
        getAuth(){
            let {root } = ctx.$universe
            return AUTH_TYPE +  root?.access_token
        }
    })

    if (!process.server) {
        let  oldCache = (window as any).__NUXT__?.apollo?.defaultClient ?? null;
        defaultClientConfig.cache.restore(oldCache)
    }


    let client = makeClient<any>(defaultClientConfig,ctx)
    let rootClient =  makeClient<any>(rootClientConfig  , ctx,true)

    //defaultApolloCreation.apolloClient.wsClient = defaultApolloCreation.wsClient

    let clients = availableClients =  {
        "default":client,
        "root":rootClient
    }
    const vueApolloOptions:ApolloProviderOptions<any> = Object.assign({defaultClient:client,clients}, {
        defaultOptions: defaultClientConfig.apollo,
        rootOptions:rootClientConfig.apollo
    })
    const apolloProvider = createApolloProvider({
        defaultClient: client,
        clients
    })
    // Allow access to the provider in the context
    app.apolloProvider = apolloProvider
    let config:any = Vue.config
    config.globalProperties =config.globalProperties || {}
    Vue.prototype.$apolloProvider = apolloProvider
    apolloProvider.install(Vue)


    let oldSetup = app.setup;
    app.setup =function(...rest){
        oldSetup?.(...rest)
        //console.log("Providing Clients",clients,arguments)
        //provideApolloClients(clients)
        provide(ApolloClients,clients)
        provide(DefaultApolloClient,client)

    }
    if (process.server) {
        const ApolloSSR = require('vue-apollo/ssr')
        beforeNuxtRender(({ nuxtState }) => {
            nuxtState.apollo = ApolloSSR.getStates(apolloProvider)
        })
    }else{
        installCacheEvictor(ctx,client,defaultClientConfig.cache)
        installCacheEvictor(ctx,rootClient, rootClientConfig.cache)
    }

    const withClients = async (cl:ApolloClient<any>|undefined,handler:(x:ApolloClient<any>) => Promise<void>) => {
        if(cl) {
            return await handler(cl)
        }

        await Promise.all(Object.values(clients).map(handler))
    }

    const helpers:ApolloHelpers = {
        onLogin: async (token:string,
            apolloClient,
            cookieAttributes:any =null, skipResetStore = false) => {

                await withClients(apolloClient,async (x) => {
                    if (!skipResetStore) {
                        try {
                            await x.resetStore()
                        } catch (e:any) {
                            // eslint-disable-next-line no-console
                            console.log('%cError on cache reset (setToken)', 'color: orange;', e.message)
                        }
                    }
                })
            },
        async tokenRefresh(token:string){
            return helpers.onLogin(token,apolloProvider.defaultClient,
                null,true)
        },
        onLogout: async (apolloClient , skipResetStore = false) => {
            await withClients(apolloClient,async (x) => {
                if (!skipResetStore) {
                    try {
                        await x.resetStore()
                    } catch (e:any) {
                        // eslint-disable-next-line no-console
                        console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
                    }
                }
            })
        },
        getToken: (tokenName = AUTH_TOKEN_NAME) => {
            return ctx.$universe.current?.access_token ?? ""
        },
        //Manual cache clearing
        clearCache(){
            [client,rootClient].forEach(x => {
                x.stop()
                x.resetStore()
                x.reFetchObservableQueries()
            })

        }
    }
    inject('apolloHelpers', helpers)
}



/**
 * A Token Refresh Handle
 */
function makeTokenRefreshHandler(ctx:Context) {

    let pendingRootRefresh:Promise<any>|null = null
    return async function(err:ErrorResponse){
        const op = err.operation
        //PRevent retry loops
        const opContext=op.getContext();
        if(opContext.isRetry) return
        if(pendingRootRefresh == null){
            let root_token = ctx.$universe.root?.access_token
            /**
             * When we run a refresh via the special path
             * we ned to update the root token directly
             */
            pendingRootRefresh = AuthService.authRefresh({
                access_token:root_token,root:true
            }).then(async refToken => {
                //Capture and store the access_token form the root refresh
                let {access_token } = refToken as UserInfo
                await ctx.store.dispatch("user/setRootToken",access_token)
                console.log("Root Refrehs Succes")
                return access_token
            }).catch(err => {
                console.log("Root Refresh Error",err)
                return err
            }).finally(() => {
                pendingRootRefresh = null
            })
        }
        //Wait for the pending refresh
        let new_token =  await pendingRootRefresh
        //REfresh Failed so we bail
        if(new_token instanceof Error) return;
        //Update the new root token
        const oldHeaders = opContext.headers
        op.setContext({
            isRetry:true,//add retry flag
            headers:{
                ...oldHeaders,
                "Authorization": `Bearer ${new_token}`
            }
        })
        return err.forward(op);
    }
}


function makeClient<T>(opts:IApolloOptions,ctx:Context,isRootClient?:boolean):ApolloClient<T>{
    let {cache} = opts
    let refreshHandler = makeTokenRefreshHandler(ctx)
    const authLink = setContext(async (req,context) => {
        let auth= await opts.getAuth()
        if(!auth) return context
        let extraContext:Record<string,any>= {}
        let extraHeaders:Record<string,any> = {
            'Authorization': auth
        }
        let {headers} = context
        if(isRootClient){
            //Root Client will always use the root refresh Handler
            extraContext['refresh.handler'] =  refreshHandler
            extraHeaders['X-Auth-Root']= 1
        }else if(unref(context["auth.root"]) === true){
            // We will switch to the auth for the root token
            let ogReq = req
            let {root } = ctx.$universe
            if(root && root.access_token){
                const root_token = root.access_token
                auth = "Bearer " + root_token;
                extraContext['refresh.handler'] =  refreshHandler
                extraContext['fetchPolicy'] = "no-cache"
                extraHeaders["Authorization"] =  auth
                extraHeaders['X-Auth-Root']= 1
            }
        }else if(context["auth.override"]){
            extraContext["fetchPolicy"] = "no-cache"
            extraHeaders["Authorization"] = `Bearer ${context['auth.override']}`
            extraHeaders['X-Auth-Override']= 1
        }
        return {
            ...context,
            ...extraContext,
            headers: {
                ...headers,
                ...extraHeaders,
            }
        }
    })
    let httpLink!:ApolloLink;
    if(ctx.env.GRAPHQL_BATCH){
        httpLink = new BatchHttpLink({
            uri:opts.httpEndpoint
        })
    }else{
        httpLink = createHttpLink({
            uri: opts.httpEndpoint
        });
    }

    const errorLink  = onError((resp:ErrorResponse) => {
        return errorHandlerFunc(resp,window.$nuxt)
    })
    const link =  ApolloLink.from([
        authLink,
        errorLink,
        httpLink])
    return new ApolloClient({
        link,
        cache,
        ...(process.server?{ssrMode:true}:{ssrForceFetchDelay:100}),
        connectToDevTools: opts.connectToDevTools||false,
        defaultOptions: opts.defaultOptions
    })
}

/**
 * Install an axios injector that invalidates the cache. For now
 * it will do it generically
 * later we can be selective
 */
function installCacheEvictor(ctx:Context,client:ApolloClient<any>,
    cache:InMemoryCache){
    let {$axios} = ctx;
    $axios.interceptors.response.use(function(response){
        try{
            do{
                let {config} = response;
                let spec:OpenAPIConfig =  (config as any).openapi?.config
                if(!spec) break
                if(["get","head"].indexOf(config.method ?? "get") != -1)break;
                if(response.status >= 400) break
                if(response.headers[HEADER_X_CONST]) break;
                //Print Clearing Cache
                client.stop()
                client.resetStore()
                client.reFetchObservableQueries()
            }while(false)
        }catch(err){
        }
        return response;
    })
}


export function getClient(name:string){
    return availableClients[name]
}
