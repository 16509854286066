/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthRefresh } from '../models/AuthRefresh';
import type { Body_auth_login_std_api_v1_auth_login_std_post } from '../models/Body_auth_login_std_api_v1_auth_login_std_post';
import type { ErrorResponse } from '../models/ErrorResponse';
import type { ImpersonationRequest } from '../models/ImpersonationRequest';
import type { LoginRequestInput } from '../models/LoginRequestInput';
import type { MfaChallengeResponse } from '../models/MfaChallengeResponse';
import type { MfaToken } from '../models/MfaToken';
import type { PasswordChangeRequest } from '../models/PasswordChangeRequest';
import type { PasswordResetRequest } from '../models/PasswordResetRequest';
import type { PasswordTokenResponse } from '../models/PasswordTokenResponse';
import type { ProfileResponse } from '../models/ProfileResponse';
import type { SignupRequest } from '../models/SignupRequest';
import type { SignupResponse } from '../models/SignupResponse';
import type { UserInfo } from '../models/UserInfo';
import type { WaitlistCheckRequest } from '../models/WaitlistCheckRequest';
import type { WaitlistCheckResponse } from '../models/WaitlistCheckResponse';
import type { WaitlistRequest } from '../models/WaitlistRequest';
import type { WaitlistResponse } from '../models/WaitlistResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * Auth Logout
     * Destroy the user session  and
     * Destroy the refresh token
     * @param host
     * @returns void
     * @throws ApiError
     */
    public static authLogout(
        host?: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/logout',
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Auth Login Std
     * @param formData
     * @returns any Successful Response
     * @throws ApiError
     */
    public static authLoginStd(
        formData: Body_auth_login_std_api_v1_auth_login_std_post,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/login/std',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Auth Impersonate
     * Endpoint to initiate impersonation
     * @param requestBody
     * @param userAgent
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static authImpersonate(
        requestBody: ImpersonationRequest,
        userAgent?: string,
        host?: string,
    ): CancelablePromise<(UserInfo | ErrorResponse)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/impersonate',
            headers: {
                'user-agent': userAgent,
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reauth User
     * @param requestBody
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static reauthUser(
        requestBody: LoginRequestInput,
        host?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/reauth',
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Auth Login
     * @param requestBody
     * @param userAgent
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static authLogin(
        requestBody: LoginRequestInput,
        userAgent?: string,
        host?: string,
    ): CancelablePromise<(UserInfo | MfaChallengeResponse | ErrorResponse)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/login',
            headers: {
                'user-agent': userAgent,
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Auth Mfa Login
     * Peform a completion of a partial session
     * using an MFA token challenge
     * 1. This can be called after a login with an MFA token in session
     * 2. This can also be called on an existing session to restore the
     * secure AURA in the leadup to an MFA action
     * @param requestBody
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static authMfaLogin(
        requestBody: MfaToken,
        host?: string,
    ): CancelablePromise<(UserInfo | MfaChallengeResponse | ErrorResponse)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/login-mfa',
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Auth Refresh
     * @param requestBody
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static authRefresh(
        requestBody: AuthRefresh,
        host?: string,
    ): CancelablePromise<(UserInfo | ErrorResponse)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/refresh_token',
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Auth Self
     * @param needRoot
     * @param host
     * @returns ProfileResponse Successful Response
     * @throws ApiError
     */
    public static authSelf(
        needRoot: boolean = false,
        host?: string,
    ): CancelablePromise<ProfileResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/auth/self',
            headers: {
                'host': host,
            },
            query: {
                'need_root': needRoot,
            },
            errors: {
                401: `Unauthorized`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Check Approved
     * Check if an email has been approved for signup
     * @param requestBody
     * @returns WaitlistCheckResponse Successful Response
     * @throws ApiError
     */
    public static checkApproved(
        requestBody: WaitlistCheckRequest,
    ): CancelablePromise<WaitlistCheckResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/waitlist-check',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Signup
     * @param requestBody
     * @param userAgent
     * @param host
     * @returns SignupResponse Successful Response
     * @throws ApiError
     */
    public static signup(
        requestBody: SignupRequest,
        userAgent?: string,
        host?: string,
    ): CancelablePromise<SignupResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/signup',
            headers: {
                'user-agent': userAgent,
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Join Waitlist
     * @param requestBody
     * @param userAgent
     * @param host
     * @returns WaitlistResponse Successful Response
     * @throws ApiError
     */
    public static joinWaitlist(
        requestBody: WaitlistRequest,
        userAgent?: string,
        host?: string,
    ): CancelablePromise<WaitlistResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/waitlist-join',
            headers: {
                'user-agent': userAgent,
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Password Reset
     * @param requestBody
     * @param userAgent
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static passwordReset(
        requestBody: PasswordResetRequest,
        userAgent?: string,
        host?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/password-reset',
            headers: {
                'user-agent': userAgent,
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Password Token Check
     * Password Token Lookup
     * @param token
     * @param host
     * @returns PasswordTokenResponse Successful Response
     * @throws ApiError
     */
    public static passwordTokenCheck(
        token: string,
        host?: string,
    ): CancelablePromise<PasswordTokenResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/check-token',
            headers: {
                'host': host,
            },
            query: {
                'token': token,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Change Password
     * Change the users password
     * it will validate that they know the current  password or have a working token
     * @param requestBody
     * @param userAgent
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static changePassword(
        requestBody: PasswordChangeRequest,
        userAgent?: string,
        host?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/change-password',
            headers: {
                'user-agent': userAgent,
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
