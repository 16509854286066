/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExtractResponse } from '../models/ExtractResponse';
import type { PostmarkInboundEmail } from '../models/PostmarkInboundEmail';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * Get Schema
     * @param name
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getSchema(
        name: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/schemas/{name}',
            path: {
                'name': name,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Postmark Inbound
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static postmarkInbound(
        requestBody: PostmarkInboundEmail,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/inbound-mail/postmark/webhook/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Extract Webhook
     * Extract webhook
     * -
     * Webhooks for instruments
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static extractWebhook(
        requestBody: ExtractResponse,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/inbound/extract/webhook',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
