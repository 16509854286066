/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $NetWorthSummary = {
    description: `Summary of the net worth values`,
    properties: {
        at: {
            type: 'NetWorthLine',
            isRequired: true,
        },
        since: {
            type: 'NetWorthLine',
        },
        diff: {
            type: 'NetWorthLine',
        },
    },
} as const;
