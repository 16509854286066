import type {Context} from "@nuxt/types"
import type {Location} from "vue-router"
import { registerHandler ,getChannel } from "./broadcast.client"

export interface IOneTime {
    inbound:Promise<Record<string,any>|undefined>
    withOutbound(location:Location,data:Record<string,any>):Location
    consume():void;
}

declare module 'vue/types/vue' {
    interface Vue {
        $onetime:IOneTime
    }
}

declare module '@nuxt/types' {
    interface Context {
        $onetime:IOneTime
    }
}


const ONETIME_REQUEST = "onetime.request"
const ONETIME_DATA = "onetime.data"
const ONETIME_QUERY = "onetime.query"
const ONETIME_REQUEST_TIMEOUT = 500;

//List of keys we are waiting for
const pendingKeys:Record<string,(x:any) => void> = {}
let registered:boolean  = false;
function broadcastHandler(e:MessageEvent) {
    let payload = e.data.payload;
    if(!payload) return
    let {type} = payload
    let proxy = e.source as WindowProxy;
    switch(type){
            //Request the ONETIME data
        case ONETIME_QUERY:
            {
                let {key} = payload;
                let stored= rawGetByKey(key)
                if(stored){
                    getChannel()?.postMessage({type:ONETIME_DATA,payload:{key,data:stored}})
                }
            }
            break
        case ONETIME_DATA:
            //When we get data we will tirgger the pending handler
            {
                let {key,data} = payload;
                console.log("GOt Data ->",payload)
                pendingKeys[key]?.(payload);
                delete pendingKeys[key]
            }
            break
    }
}

function ensureHandler(){
    if(!registered) registerHandler(ONETIME_REQUEST,broadcastHandler)
    registered = true;
}

async function requestOnetime(key:string):Promise<Record<string,any>|undefined>{
    ensureHandler()
    let c = getChannel();
    if(!c) return undefined
    c.postMessage({ type:ONETIME_REQUEST, payload: {type:ONETIME_QUERY, key} })
    return new Promise<any>(async (resolve,reject) => {
        // Timer  to remove pending hook
        let timer = window.setTimeout(() => {
            delete pendingKeys[key]
            resolve(undefined)
        },ONETIME_REQUEST_TIMEOUT)
        // Setup handler for if the data comes from another window
        pendingKeys[key] = (x:any) => {
            window.clearTimeout(timer)
            resolve(x)
        }
    })
}

function rawGetByKey(key:string):any{
    let stored =window.sessionStorage.getItem(key)
    if(stored) return JSON.parse(stored);
    return undefined
}

const KEY_PREFIX = "onetime"
export default (cxt:Context,inject:any) => {
    /**
     * One time is a system for passing single use data to routes
     */

    function getKey():string|undefined{
        let {query} = cxt
        let entry = query["onetime"];
        if(!entry) return
        let key:string|null =Array.isArray(entry)?entry[0]:entry;
        if(!key) return;
        return  `${KEY_PREFIX}/${key}`
    }
    const onetime:IOneTime  = {

        get inbound():Promise<Record<string,any>|undefined> {
            let key= getKey();
            if(key){
                let stored = rawGetByKey(key)
                if(stored) return stored;
                return requestOnetime(key)
            }
            return Promise.resolve(undefined)
        },
        withOutbound(location:Location,data:Record<string,any>){
            let key = new Date().getTime().toString(16)
            location.query = location.query || {}
            location.query["onetime"] = key
            let value =  JSON.stringify(data)
            window.sessionStorage.setItem(`${KEY_PREFIX}/${key}`, value)
            return location;
        },
        consume(){
            let key= getKey();
            if(!key) return;
            window.sessionStorage.removeItem(key);
        }
    }
    ensureHandler();
    inject("onetime",onetime)

}

export function useOnetime():IOneTime {
    return window.$nuxt.$onetime
}


