/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ObservableSummaryRequest = {
    properties: {
        by: {
            type: 'any-of',
            contains: [{
                type: 'ByAccount',
            }, {
                type: 'ByDocument',
            }],
            isRequired: true,
        },
        columns: {
            type: 'array',
            contains: {
                type: 'string',
            },
            isRequired: true,
        },
        clause: {
            type: 'any-of',
            contains: [{
                type: 'ExactDate',
            }, {
                type: 'DateRange',
            }, {
                type: 'AndClause',
            }, {
                type: 'OrClause',
            }, {
                type: 'ByType',
            }],
        },
        order: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
    },
} as const;
