/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiCapitalActivityEvent = {
    properties: {
        activity: {
            type: 'CapitalActivityType',
            isRequired: true,
        },
        payable_unit_id: {
            type: 'string',
            isRequired: true,
            format: 'uuid',
        },
        amount: {
            type: 'number',
            isRequired: true,
        },
        src_account_id: {
            type: 'string',
            format: 'uuid',
        },
        dst_account_id: {
            type: 'string',
            format: 'uuid',
        },
        settled_event_id: {
            type: 'string',
            format: 'uuid',
        },
    },
} as const;
