
import {Component,Vue,Prop,Watch,ModelSync,Emit,
    Inject, Getter,State} from "nuxt-property-decorator"
import {Context} from "@nuxt/types"
import debounce from "lodash/debounce"
import { components } from "~/schemas/api"
import { getCurrencies } from "~/schemas/enrichment"
import {VNode,CreateElement} from "vue"
import {VChip,VIcon} from "vuetify/lib"
import {EntityService,ApiEntity,EntityRef,EntityRefDetails,InputApiEntity,ApiProfile } from "~/schemas/gen"
import gql from "graphql-tag"
import {ZERO_UUID} from "~/utils/uuid"
import InputContext from "utils/InputContext"
import {DocumentNode} from "graphql"
import { PartialQuery } from "~/core/common/input"
import {
    fragApiEntityFields,isEntityRef,
    searchEntities,isCreateEntry,
    renderEntity
}  from "~/core/entity"




type LabelRenderer =(x:ApiEntity|EntityRef) =>string




const initialEntity = gql`
query initialEntity($entityUuid:uuid!){
entities(
    where: { uuid:{_eq: $entityUuid} }
    ) {
    ...ApiEntityInputFields
    }
}
${fragApiEntityFields}
`

@Component({
           components:{VChip,VIcon}
})
class EntityItem extends Vue {
    /**
     * EntityItem - 
     */
    @Prop() item!:ApiEntity|EntityRef
    @Prop({default:false}) extended!:boolean 
    @Prop({type:Function}) labelRenderer?:LabelRenderer;
    @Getter("user/systemUserId") systemUserId!:string|null


    get profile():ApiProfile {
        return this.$universe.current?.user.profile!
    }

    render(h:CreateElement){
        let {item,extended,profile,systemUserId} = this;
        let children:(VNode|string)[] = [ (this.labelRenderer ??  renderEntity)(item) ," " ]
        let chipChildren:(VNode|string)[] = []
        let id = isEntityRef(item)?item.id:item.uuid;
        if(profile?.primary_entity?.uuid  == id){
            chipChildren.push("Primary")
        }

        if(chipChildren.length > 0){ 
            children.push(h("v-chip", { props:{small:true,label:true,
                outlined:true,color:"primary"}},
                            chipChildren ))
        }
        if(isCreateEntry(item)){
            if(extended){
                children.push(h("h6",{},"Create New Entity"))
            }else {
                children.push(h("v-chip",{props:{small:true,color:"green",
                    label:true}},"NEW"))
            }
        }

        children.push(" ")
        return h("div",{ class:{"entity-input-select":true}},children)

    }

}



/**
 * Instrument Input component with combobox and autocomplete functionality
 */
@Component({
           inheritAttrs:true,
           components:{EntityItem},
           apollo:{
               $client:(function (this:EntityInput){
                   return this.dataSource?.clientId ??  this.clientId ?? "default"
               } as any),
               searchEntities:{
                   query(){
                       return this.dataSource?.query ?? searchEntities
                   },
                   variables():any {
                       let {dataSource} = this
                       if(dataSource ){
                           return dataSource.variables(this.search)
                       }
                       return {
                           query:this.search+ "%"
                       }
                   },
                   update(data){
                       if(this.dataSource) {
                           return this.dataSource.update(data)
                       }
                       return data.entities
                   },
                   skip() {
                       let {search} = this   
                       if(this.dataSource) return false;
                       return !search || this.search?.trim?.()?.length ==0
                   },
                   context(){
                       return this.dataSource?.context ?? {}
                   }
               },
               initialEntity:{
                   query:initialEntity,
                   variables():any { 
                       let uuid!:string
                       if(typeof(this.initValue) == "string") uuid = this.initValue
                       else if(isEntityRef(this.initValue)) uuid=this.initValue.id as string
                       else uuid = this.initValue.uuid
                       return {entityUuid:uuid}
                   },
                   update: data => data.entities[0],
                   skip(){
                       if(this.dataSource) return true;
                       return !this.initValue
                   }
               },
               recentEntities:{
                   query:gql`
                   query recent {
                       entities(limit:5,order_by:{updated_time:desc}){
                           ...ApiEntityInputFields
                       }
                   }
                   ${fragApiEntityFields}
                   `,
                   skip(){
                       if(this.dataSource) return true;
                       return false;
                   },
                   update: data => data.entities
               }
           }
})
export default class EntityInput extends Vue {
    @ModelSync("value","input") input!:string|ApiEntity|EntityRef;
    //@Prop({default: "name"}) outType!:InstrumentRefType 
    @Prop({}) vjsfContext!:any; //Optional Vjsf slot context
    @Prop({type:String}) label!:string|null;
    @Prop({type:Array}) errorMessages!:any[]
    @Prop({type:Boolean,default:false}) allowCreate!:boolean
    //If Set to a vlaue we will show a Create link that will open another window
    @Prop({type:[String,Boolean],default:false}) createLinkLabel!:string|false;
    @Prop({type:Boolean,default:false}) objectMode!:boolean;
    //We want entity refths
    @Prop({type:Boolean,default:false}) refMode!:boolean
    @Prop({type:Object}) dataSource!:PartialQuery|null
    @Prop({type:Function}) labelRenderer?:LabelRenderer
    @Prop({type:String}) contextId?:string
    @Prop({type:String,default:null}) clientId?:string
    @Inject({from:'inputContext',default:undefined}) inputCxt!:InputContext|undefined



    search:string =  ""
    loading:boolean = false
    searchEntities:ApiEntity[] = []
    recentEntities:ApiEntity[] = []
    initialEntity:ApiEntity|null = null
    initValue:ApiEntity|EntityRef|string|null = null
    itemError:Error|any|null = null
    fixed:boolean = false

    get passedListeners():object{
        let {input,...captured} = this.$listeners
        return   captured;
    }

    async mounted(){
        let {input} = this
        this.initValue = input;
    }

    get displayLabel():string{
        return this.label || this.vjsfContext?.label
    }
    //Get value as an apientity object
    get valueAsApiEntity():ApiEntity{
        let input:ApiEntity|EntityRef =this.input as any
        if(!isEntityRef(input)) return input;
        return {
            uuid: input.id! as string,
            ...(input.details!)
        }
    }
    get items():ApiEntity[]{
        let entries:ApiEntity[] = []
        let {allowCreate,input:value,search} = this;
        if(allowCreate){
            if(value && typeof value !== "string" && isCreateEntry(value)){
                entries.push(this.valueAsApiEntity);
            }else if(search){
                search = search.trim();
                if(search.length > 0){
                    entries.push({
                                 name:search,
                                 legal_name:search,
                                 uuid:ZERO_UUID
                    })
                }
            }
        }
        if(this.searchEntities.length > 0) entries.push(...this.searchEntities)
        if(this.initialEntity) entries.push(this.initialEntity)
        if(this.recentEntities.length >  0 ) entries.push(...this.recentEntities)
        //If we have an entity as input and it is not in the entries list 
        //we will add it so it can eb selected and shown
        if(value  && typeof value !== "string" ){
            if("uuid" in value ){
                let tgtUuid = value.uuid
                let found = entries.find((x) => {
                    return x.uuid  == tgtUuid
                })
                if(!found) entries.push(value)
            }
        }
        return entries
    }
  
    get selectInput():string|null {
        if(!this.input) return null;
        if(typeof(this.input) == "string") return this.input;
        if(isEntityRef(this.input)) return this.input.id as string;
        return  this.input.uuid!
    }

    
    @Emit("input")
    inputChange(x:any,obj?:ApiEntity|EntityRef){
        if(this.contextId) this.inputCxt?.write(this.contextId,{id:x,obj})
    }

    onItemSelected(x:ApiEntity|EntityRef|null){
        if( x == null) {
            this.inputChange(null)
            return
        }
        let ret!:any

        if(this.objectMode){
            if(this.refMode){
                if(isEntityRef(x)) ret =x 
                else {
                    ret = {
                        id:x.uuid,
                        details:x
                    }
                }
            }else ret =x 
        }else ret = isEntityRef(x)?x.id:x.uuid!

        this.inputChange(ret,x)
    }
    onKeyDown($event:KeyboardEvent){
        if($event.keyCode == 13){
            //Pressing retunr will choose the first suggestion
            $event.stopPropagation();
            $event.preventDefault();
            this.selectFirst();
        }
    }

    selectFirst(){
        if(this.items.length > 0){
            this.onItemSelected(this.items[0])
        }
    }

    compareEntity(x:ApiEntity|string,y:ApiEntity|string):boolean {
        if(x === y ||  x == y  ) return true
        if(x == null || y == null) return false;
        if(typeof(x) == "string"){
            if(typeof(y) == "object") return x == y.uuid
            return false
        } 
        if(typeof(y) == "string") return y === x.uuid
        return false;
    }
    getItemText(item:ApiEntity){
        if(typeof item === "string") return item
        return renderEntity(item)
    }
   
    getItemValue(x:ApiEntity ){
        return x.uuid
    }


    //open a window to createa  new entity
    popupCreateEntity(){
        let lnk= this.$router.resolve({
                             name:"entities-create",query:{
                                 popup:"true"
                             }
        })
        let proxy=window.open(lnk.href,"create-popup");
        if(proxy){
            proxy.addEventListener("entity-created" as any ,(e:CustomEvent) => {
                let created =e.detail["entity"] as ApiEntity
                if(created){
                    this.searchEntities.push(created);
                    this.onItemSelected(created);
                }
            })
        }
        
    }
}

