/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $FileInputStageConfig = {
    description: `Configuration for a File input stage`,
    properties: {
        mimes: {
            type: 'array',
            contains: {
                type: 'string',
            },
            isRequired: true,
        },
        multiple: {
            type: 'boolean',
        },
        required: {
            type: 'boolean',
        },
    },
} as const;
