import Vue,{ DirectiveBinding,VNode } from "vue"


export type NativeResizeCallback = (el:HTMLElement,entries: ResizeObserverEntry[])=> void
/**
 * Run a resize observer
 */ 
Vue.directive("native-resize-observer",{
    bind(el:HTMLElement,binding:DirectiveBinding<NativeResizeCallback>,node:VNode){
        let resizer =new ResizeObserver((ents) => binding.value(el,ents));
        (el as any).__resizer =resizer
        resizer.observe(el)

        //console.log("Resize ->",el,binding,node)
    },
    inserted(el:HTMLElement,binding:DirectiveBinding<NativeResizeCallback>){
        //console.log("Resize Inserted  -> ",...arguments)
        binding.value(el,[])
    },
    unbind(el:HTMLElement,binding:DirectiveBinding<Function>,node:VNode){
        let resizer = (el as any).__resizer as ResizeObserver;
        resizer.disconnect();
        delete (el as any).__resizer 

    }
})



