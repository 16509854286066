/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ResponseSeries = {
    properties: {
        key: {
            properties: {
            },
        },
        data: {
            type: 'dictionary',
            contains: {
                type: 'array',
                contains: {
                    properties: {
                    },
                },
            },
            isRequired: true,
        },
        status: {
            type: 'string',
        },
        annotations: {
            type: 'dictionary',
            contains: {
                type: 'array',
                contains: {
                    properties: {
                    },
                },
            },
        },
        continuation: {
            type: 'string',
        },
        start_time: {
            type: 'string',
            format: 'date-time',
        },
        end_time: {
            type: 'string',
            format: 'date-time',
        },
    },
} as const;
