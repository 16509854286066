/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ShortFeeDeclaration = {
    description: `This object represents a simplification of several FundFlowClause Declarations.
    That can be expanded into a full set of flow clauses.
    It is only applicable in the case of a Single GP for the commitment `,
    properties: {
        management_fee: {
            type: 'number',
            exclusiveMaximum: 100,
        },
        management_notional_target: {
            type: 'FundNotionalTarget',
        },
        management_fee_end_date: {
            type: 'string',
            format: 'date',
        },
        hurdle_rate: {
            type: 'number',
        },
        general_partner_id: {
            type: 'string',
            format: 'uuid',
        },
        carried_interest: {
            type: 'number',
            exclusiveMaximum: 100,
        },
        carried_interest_notional_target: {
            type: 'FundNotionalTarget',
        },
        carried_interest_end_date: {
            type: 'string',
            format: 'date',
        },
    },
} as const;
