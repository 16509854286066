/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PipelineRelated } from './PipelineRelated';
import type { PipelineStepEntry } from './PipelineStepEntry';

export type PipelineSetup = {
    steps: Array<PipelineStepEntry>;
    related?: Array<PipelineRelated>;
    event_type?: PipelineSetup.event_type;
};

export namespace PipelineSetup {

    export enum event_type {
        SETUP = 'setup',
    }


}

