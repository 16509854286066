/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum EtlStageStatus {
    INIT = '000I',
    VALIDATED = '100V',
    CONFIG_CHANGED = '101V',
    SCHEDULED = '200S',
    RUNNING = '300R',
    USER_REVIEW = '400U',
    COMPLETE = '800C',
    PARTIAL_COMPLETE = '801C',
    FAILED = '900F',
}
