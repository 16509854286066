/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $RecurDetails = {
    properties: {
        detail_type: {
            type: 'Enum',
        },
        periodicity: {
            type: 'Periodicity',
            isRequired: true,
        },
        recur_type: {
            type: 'RecurType',
            isRequired: true,
        },
        payable_unit_id: {
            type: 'string',
            isRequired: true,
            format: 'uuid',
        },
        payable_notional: {
            type: 'number',
            isRequired: true,
        },
        coupon_percent: {
            type: 'number',
        },
    },
} as const;
