/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiTransaction = {
    description: `Transaction(id, user, transaction_id, uuid, created_time, transaction_time, amendment_time, notes, use_of_proceeds, user_create_details, details, src_event)`,
    properties: {
        id: {
            type: 'number',
            description: `id`,
        },
        transaction_id: {
            type: 'string',
            description: `user generated transaction name, if empty system generated id will be here`,
            maxLength: 100,
        },
        uuid: {
            type: 'string',
            description: `Unique id for transaction`,
            isRequired: true,
            format: 'uuid',
        },
        created_time: {
            type: 'string',
            description: `When this record was created`,
            isRequired: true,
            format: 'date-time',
        },
        transaction_time: {
            type: 'string',
            description: `time of execution of the transaction`,
            isRequired: true,
            format: 'date-time',
        },
        amendment_time: {
            type: 'string',
            description: `The last amendment time is system generated, and refers to when the last time this transaction was updated in the system, this could be very different from the effective date of the transaction`,
            isRequired: true,
            format: 'date-time',
        },
        notes: {
            type: 'string',
            description: `Notes about the transaction including rationale price target, relationships with other transaction etc`,
        },
    },
} as const;
