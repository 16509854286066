/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiAccount = {
    description: `Api Account object`,
    properties: {
        uuid: {
            type: 'string',
            isRequired: true,
            format: 'uuid',
        },
        entity_id: {
            type: 'string',
            isRequired: true,
            format: 'uuid',
        },
        owner_id: {
            type: 'string',
            isRequired: true,
            format: 'uuid',
        },
        created_time: {
            type: 'string',
            isRequired: true,
            format: 'date-time',
        },
        updated_time: {
            type: 'string',
            isRequired: true,
            format: 'date-time',
        },
        name: {
            type: 'string',
            isRequired: true,
            maxLength: 128,
        },
        acct_type: {
            type: 'AcctType',
            isRequired: true,
        },
        user_defined: {
            type: 'boolean',
            isRequired: true,
        },
        date_established: {
            type: 'string',
            format: 'date',
        },
        acct_number: {
            type: 'string',
            maxLength: 20,
        },
        group: {
            type: 'string',
            maxLength: 20,
        },
        bs_label: {
            type: 'string',
            maxLength: 4,
        },
        acl: {
            type: 'ApiPermissions',
        },
        settlement_account_id: {
            type: 'string',
            format: 'uuid',
        },
        notes: {
            type: 'string',
        },
        user_attributes: {
            properties: {
            },
        },
        user_tags: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        is_archived: {
            type: 'boolean',
        },
        is_self_settled: {
            type: 'boolean',
        },
    },
} as const;
