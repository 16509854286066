import {GetterTree,MutationTree,ActionTree} from "vuex"
import { itemExplorationLink } from "~/core/common/input"
import {withinUniverse} from "~/plugins/auth.universe"
import {ApiDocument,InputApiDocument,StartUploadResponse,StartUploadItem,
    DocumentsService} from "~/schemas/gen"

export enum UploadJobState {
    Pending=1, //Requesting upload to start
        Created= 2, //Document is created and upload is starting
        Uploading=3,
        Failed = 4,
        Completed= 5
}

export interface UploadJob {
    file:File
    name:string
    state:UploadJobState
    // Document Object
    document?:ApiDocument
    response?:StartUploadResponse
    error?:Error
}

interface UploadSetState  {
    //List of uploads
    nextId:number;
    jobs:Record<number,UploadJob> ;
    lastUpdated:number|null ;
    timestamp:number|null;
    // last document to be updated or
    lastDocument:ApiDocument|null;
}


export const state= () => {
    return {
        uploads:{jobs:{},nextId:1,lastUpdated:null,timestamp:Date.now(),
        lastDocument:null} as UploadSetState
    }
}

export type DocumentState = ReturnType<typeof state>


export const mutations:MutationTree<DocumentState> = {
    nextUploadId(state){
        state.uploads.nextId++
    },
    updateUpload(state:DocumentState,{id,job}:any){
        state.uploads.jobs[id]=job
        state.uploads.lastUpdated = id
        state.uploads.timestamp = Date.now()
    },
    mergeUploadState(state:DocumentState,{id,update}:any) {
        let job = state.uploads.jobs[id] || {}
        if(update instanceof Function){
            state.uploads.jobs[id] = update(job)
        }else{
            state.uploads.jobs[id]={...job,...update}
        }
        state.uploads.lastUpdated = id;
        state.uploads.timestamp = Date.now()
    },
    updateLastDocument(state:DocumentState,{doc}:any){
        state.uploads.lastDocument = doc
    }
}

export interface UploadPayload {
    file:File
    input:InputApiDocument
    universe:string
}

/**
 * Getter State
 */
export const getters:GetterTree<DocumentState,any> = {
    lastChanged(state:DocumentState):number {
        let {uploads} = state
        return uploads.lastUpdated || 0
    },
    lastChangedAt(state:DocumentState):number{
        return state.uploads.timestamp || 0
    }
}

/**
 * Actions
 */
export const actions:ActionTree<DocumentState,any> = {
    /**
     * Start  an upload
     * Returns the upload job id
     */
    async startUpload({commit,dispatch,state},{file,input,universe}:UploadPayload):Promise<number>{
        commit('nextUploadId')
        let {uploads:{nextId}} = state
        let job:UploadJob  = {
            name:file.name || "Unknown",
            file,state:UploadJobState.Pending
        }
        //We will fire the pending job
        let start = () =>  DocumentsService.startUpload(input)
        if(universe){
            const start_ = start
            start= () => withinUniverse(universe,start_)
        }
        let resp = await start().catch((err:Error) => err);
        if(resp instanceof Error){
            commit('mergeUploadState',{id:nextId,
                update:{
                    ...job,
                    error:resp,state:UploadJobState.Failed
                }
            })
            return nextId
        }
        //Add this job into the mix
        Object.assign(job,{response :resp,
            document:resp.items[0].doc,
            state:UploadJobState.Created})
        commit('mergeUploadState',{id:nextId,update: { ...job, response :resp,
            document:resp.items[0].doc,
            state:UploadJobState.Created}})
        commit('updateLastDocument',{doc:resp.items[0].doc})
        //Start running the upload
        dispatch('runUploadJob',{jobId:nextId,file,isNew:!input.uuid,item:resp.items[0]})
        return nextId
    },

    /**
     * Wait for job to complete or fail
     */
    async waitForJob(context:any,payload:number){
        let isComplete= (j:UploadJob) => {
            switch(j.state){
                case UploadJobState.Failed:
                case UploadJobState.Completed:
                    return true
            }
            return false
        }
        return new Promise((resolve:any,reject:any) => {

            /*let unsubscribe = this.watch(
                ({documents}:{documents:DocumentState},getters:any) => {
                    return documents.uploads.jobs[payload]
                },(newState,oldState) => {
                    console.log("Update",payload,oldState,newState)
                    if(isComplete(newState)){
                        unsubscribe();
                        resolve(newState)
                    }
                },{deep:true,immediate:true})*/
            let initJob = context.state.uploads.jobs[payload]
            if(isComplete(initJob)) return resolve(initJob);
            let unsubscribe = this.subscribe((mutation,state) => {
                let job = state.documents.uploads.jobs[payload];
                if(isComplete(job)){
                    unsubscribe()
                    resolve(job)
                }
            })
        })
    },
    //Get the current state of a job
    getJob({state},payload:number):UploadJob|undefined {
        return state.uploads.jobs[payload]
    },
    /**
     * View a document URL
     */
    async view(context,payload:{uuid:string,version?:"latest"|number,view?:boolean|"auto"}){
        let {baseURL} = this.$axios.defaults
        let version = payload.version ?? "latest";
        return DocumentsService.getDocumentVersion(payload.uuid,version,true,payload.view ?? false)
    },

    /**
     * Start the  the actual file upload
     */
    async runUploadJob({commit,state,dispatch},
        payload:{jobId:number,isNew:boolean,file:File,item:StartUploadItem}){
        let {jobId,file,item} = payload
        let {url,fields} = item.upload;
        let job = state.uploads.jobs[jobId]
        // POpulate formdata
        let fd = new FormData();
        Object.entries(fields).forEach(([k,v]) => fd.append(k,v as string))
        fd.append("file",file);
        /**
         *       let resp = await fetch(url,{method:"POST",
            body:fd,redirect:"error"}).catch(err => err)
        console.log("Respone ->",resp)
         */
        //Send request
        let resp:XMLHttpRequest|ProgressEvent= await new Promise<XMLHttpRequest>((resolve,reject) => {
            let req =new XMLHttpRequest();
            req.withCredentials = false
            req.addEventListener("load",() => {
                resolve(req)
            })
            req.addEventListener("error",(e:ProgressEvent) => reject(e))
            req.open("POST",url)
            req.send(fd)
        }).catch((err:ProgressEvent) => err);
        const failed = (error:ProgressEvent|string,extra:Record<string,any>={}) => {
            try{
                if(payload.isNew) {
                    console.log("!!","Deleting partial document")
                    DocumentsService.deleteDocument(job.document!.uuid)
                }
            }catch(err){
                console.error("Cant Delete Document",err)
            }
            let err!:Error;
            if(typeof error === "string"){
                err= new Error(error)
            }else {
                err = new Error("Upload Failed");
                Object.assign(err,{event:error})
            }
            commit("mergeUploadState",{
                id:jobId,update:{
                    state:UploadJobState.Failed,
                    error:Object.assign(err,extra)}
            })
            return false
        }
        if(resp instanceof ProgressEvent) return failed(resp)
        if(resp.status  != 204){
            return failed("Storage Upload Failed",{request:resp})
        }
        //Success We will make a call to the redirec endpoint
        let doc = job.document!
            let finalResp = await DocumentsService.uploadComplete(doc.uuid,doc.version_number)
        if(finalResp instanceof ProgressEvent) return failed(finalResp)
        if(!finalResp.status) {
            return failed("Upload Confirmation Failed:" + finalResp.message,{response:finalResp})
        }
        commit("mergeUploadState",{
            id:jobId,update:{
                state:UploadJobState.Completed
            }
        })
        return true;
    }

}



