/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $FlowClauseLimit = {
    description: `This represents limitation on a fund flow clause`,
    properties: {
        irr_hurdle: {
            type: 'number',
        },
    },
} as const;
