/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiEntity } from '../models/ApiEntity';
import type { InputApiEntity } from '../models/InputApiEntity';
import type { ListResponse_ApiEntity_ } from '../models/ListResponse_ApiEntity_';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EntityService {

    /**
     * List Entities
     * @param limit
     * @param offset
     * @param host
     * @returns ListResponse_ApiEntity_ Successful Response
     * @throws ApiError
     */
    public static listEntities(
        limit: number = 10,
        offset?: number,
        host?: string,
    ): CancelablePromise<ListResponse_ApiEntity_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/entities',
            headers: {
                'host': host,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Entity
     * @param requestBody
     * @param commit
     * @param host
     * @returns ApiEntity Successful Response
     * @throws ApiError
     */
    public static addEntity(
        requestBody: InputApiEntity,
        commit: boolean = true,
        host?: string,
    ): CancelablePromise<ApiEntity> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/entities',
            headers: {
                'host': host,
            },
            query: {
                'commit': commit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Entity
     * Update an Entity
     * @param uuid
     * @param requestBody
     * @param commit
     * @param host
     * @returns ApiEntity Successful Response
     * @throws ApiError
     */
    public static updateEntity(
        uuid: string,
        requestBody: InputApiEntity,
        commit: boolean = true,
        host?: string,
    ): CancelablePromise<ApiEntity> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/entities/{uuid}',
            path: {
                'uuid': uuid,
            },
            headers: {
                'host': host,
            },
            query: {
                'commit': commit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Search Entities
     * @param limit
     * @param offset
     * @param host
     * @returns ListResponse_ApiEntity_ Successful Response
     * @throws ApiError
     */
    public static searchEntities(
        limit: number = 10,
        offset?: number,
        host?: string,
    ): CancelablePromise<ListResponse_ApiEntity_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/entities/search',
            headers: {
                'host': host,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Entity
     * @param id
     * @param host
     * @returns ApiEntity Successful Response
     * @throws ApiError
     */
    public static getEntity(
        id: string,
        host?: string,
    ): CancelablePromise<ApiEntity> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/entities/{id}',
            path: {
                'id': id,
            },
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
