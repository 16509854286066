import gql from "graphql-tag"
import {ApiEntity,EntityRef} from "~/schemas/gen"
import {ZERO_UUID} from "~/utils/uuid"



export type ApiEntityNode = Partial<ApiEntity> & {
    manager?:ApiEntityNode
    parent?:ApiEntityNode
}


export function isEntityRef(item:ApiEntityNode|EntityRef):item is EntityRef {
    if("id" in item && "details" in item) return true;
    if("uuid" in item) return false;
    return false;
}

/**
Is this a create entry
*/
export function isCreateEntry(item:ApiEntity|EntityRef):boolean {
    if(isEntityRef(item)) return item.id == ZERO_UUID
    return item.uuid == ZERO_UUID 
}



export function renderEntity(item:ApiEntity|EntityRef):string {
    
    let name!:string 
    let legal_name:string|undefined;
    if(isEntityRef(item)){
        name =item.details?.name ?? "???"
        legal_name = item.details?.legal_name;
    }else{
        name = item.name
        legal_name = item.legal_name
    }
    if(!isCreateEntry(item)){
        let llen = legal_name?.length ?? 0
        if(llen > 0)  name = name + ` \u25B8 ${legal_name}`
    }
    return name
}



export const fragApiEntityFields = gql`
fragment ApiEntityInputFields on entities {
    name legal_name first_name last_name uuid
    email phone 
}
`

export const searchEntities = gql`
query searchEntities($query:String!){
entities( 
    where:{ _or:[ 
    { name : {_ilike: $query}}
    { legal_name: {_ilike: $query }}
    ]
    }
){
    ...ApiEntityInputFields
}
}
${fragApiEntityFields}
`;
