/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $DataTabularTab = {
    properties: {
        title: {
            type: 'string',
        },
        header: {
            type: 'string',
        },
        footer: {
            type: 'string',
        },
        preview: {
            type: 'boolean',
        },
        identifier: {
            type: 'string',
        },
    },
} as const;
