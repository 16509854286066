/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Workflows {
    HELDAWAY_EXTRACTION = 'std.heldaway.extract',
    ANY_ETL = 'std.etl.???',
}
