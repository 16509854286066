/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $FileDocumentInput = {
    description: `Id of a real document`,
    properties: {
        document: {
            type: 'string',
            isRequired: true,
            format: 'uuid',
        },
    },
} as const;
