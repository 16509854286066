/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $InstrumentListRequest = {
    properties: {
        instrument_types: {
            type: 'array',
            contains: {
                type: 'InstrumentType',
            },
        },
    },
} as const;
