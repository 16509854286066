/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum InstrumentType {
    SAFE = 'SAFE',
    PART_UNIT = 'UNIT',
    EQUITY = 'EQTY',
    DEBT = 'BOND',
    RECURRING = 'RECR',
    COLLATERALIZED_DEBT = 'CBND',
    DERIVATIVE_SECURITY = 'DRIV',
    CURRENCY = 'CCY',
    CAPITAL_COMMITMENT = 'CAPC',
    COMMODITY = 'COMM',
    REAL_ESTATE = 'REAL',
    OTHER = 'OTHR',
    FX = 'FX',
    CRYPTOCURRENCY = 'CPTO',
}
