/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum FundNotionalTarget {
    INVESTED_CAPITAL = 'I',
    COMMITTED_CAPITAL = 'C',
    ALTERNATE = 'A',
    CUMULATIVE_LP_FEES = 'L',
    CUMULATIVE_GP_FEES = 'G',
}
