/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * These are the difference kind of instrument refs we will handle
 */
export enum InstrumentRefType {
    NAME = 'name',
    CCY = 'ccy',
    ISIN = 'isin',
    FIGI = 'figi',
    ID = 'id',
    CRTA = 'crta',
    ANGL = 'angl',
}
