/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SimpleDocumentContent = {
    properties: {
        body: {
            type: 'string',
            isRequired: true,
        },
        uuid: {
            type: 'string',
            format: 'uuid',
        },
        meta: {
            properties: {
            },
        },
        azure_meta: {
            properties: {
            },
        },
    },
} as const;
