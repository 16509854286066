/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiTransactionProspect = {
    properties: {
        uuid: {
            type: 'string',
            isRequired: true,
            format: 'uuid',
        },
        created_time: {
            type: 'string',
            isRequired: true,
            format: 'date-time',
        },
        instrument_type: {
            type: 'InstrumentType',
        },
        event_type: {
            type: 'EventType',
        },
        direction: {
            type: 'Direction',
        },
        notes: {
            type: 'string',
        },
        request: {
            properties: {
            },
        },
        instrument: {
            type: 'ApiInstrument',
        },
        instrument_id: {
            type: 'string',
            format: 'uuid',
        },
        acl: {
            type: 'ApiPermissions',
        },
        user_attributes: {
            properties: {
            },
        },
        user_tags: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
    },
} as const;
