/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiObservableTypeInfo = {
    properties: {
        identifier_type: {
            type: 'any-of',
            contains: [{
                type: 'Enum',
            }, {
                type: 'Enum',
            }],
            isRequired: true,
        },
        required_fields: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
    },
} as const;
