
import createVisitor from "json-schema-visitor"
import mapValues from "lodash/mapValues"
import upperFirst from "lodash/upperFirst"
import pickBy from "lodash/pickBy"
import merge from "lodash/merge"
import isEmpty from "lodash/isEmpty"
import {ValidationErrorMap} from "./validation"

export interface RigidSchema {
    type:string
    label:string
    ext?:string
    hint?:string
    col?:Record<string,any>
    items?:Record<string,any>[]|Record<string,any>|Schema[]
    itemText?:string
    itemValue?:string
    [key:string]:any
}

export interface NestedSchema {
    type:string
    schema:SchemaSet
}
export type PartialSchema = Partial<Schema>  | {
    schema:PartialSchemaSet
}

type BaseSchema = NestedSchema | RigidSchema
export type Schema = BaseSchema & Record<string,any>;
export type SchemaSet = Record<string,Schema>;
export type PartialSchemaSet =  Record<string,PartialSchema>

type TransformCallbackResult =  Schema|Just|Drop
type TransformCallback = (path:string[],schema:any,) => TransformCallbackResult


class Just {
    value:Schema
    constructor(value:Schema){
        this.value = value
    }
    static Unwrap(x:TransformCallbackResult|null):any {
        if(x instanceof Just) return x.value
        return x;
    }
}
class Drop {
    constructor(){
    }
}

const DoDrop =  new Drop()
function isDrop(x:TransformCallbackResult): x is Drop {
    return DoDrop === x
}


interface TransformerControls {
    Just(x:TransformCallbackResult): TransformCallbackResult;
    Drop:Drop
}
type TransformerFunc = (x:Schema,callback:TransformCallback) => Schema
type SeqTransformerFunc = (x:Schema[],callback:TransformCallback) => Schema[]
type Transformer = (TransformerFunc|SeqTransformerFunc) & TransformerControls


/**
 *
 */
export function nestedSetter(node:Record<string,any>,path:string[]):Record<string,any>{
    let target= node
    for(let node of path){
        // Deep copy the path segment we are going down
        if(target.schema){
            target = target.schema[node] =  { ...(target.schema[node] ||  {})}
            continue;
        }
        target = target[node] = {
            ...(target[node] || {})
        }
    }
    return target;
}

/**
 * Construct a new schema by running a function on al elements of the 
 * current schema andkeeping the results
 */
export const rawTransformSchema:TransformerFunc|SeqTransformerFunc = createVisitor({
    object(old:Schema, callback:TransformCallback) {
        let res = callback([],old)
        if(res instanceof Just ) return res.value
        let schema:Schema = { ...res || old} as Schema
        schema.properties = mapValues(schema.properties,(x:Schema,key:string) => {
            let trans =rawTransformSchema(x as any, (oldPath:string[],schema:Schema) =>  callback(oldPath.concat(key),schema))
            trans = Just.Unwrap(trans)
            if(trans instanceof Drop || trans instanceof Just) throw new Error("Not Supported")
            return trans || x
        })
        return schema
    },
    array(schema:Schema, callback:TransformCallback) {
        let res = callback([],schema)
        if( res instanceof Just) return res.value;
        if( isDrop(res)) return res;
        schema = { ...(res as Schema)||schema } 
        if(Array.isArray(schema.items)){
            let items = schema.items as Schema[];
            schema.items =items.map((x:Schema,index:number) => {
                return rawTransformSchema(x as any,(oldPath,schema) => callback(oldPath.concat([`${index}`]),schema)) || x
            }).map(Just.Unwrap).filter((x:TransformCallbackResult) => x !=  DoDrop)
        }else {
            schema.items=rawTransformSchema(schema.items as any, (oldPath,schema) => callback(oldPath.concat('0'),schema))!
        }
        return schema
    },
    allOf(schema:Schema, callback:TransformCallback) {
        let res =callback([],schema)
        if ( res instanceof Just) 
            return res.value
        if (res === DoDrop) return res;
        schema = {...(res as Schema)||schema}
        schema.allOf = schema.allOf.map((x:Schema)=> rawTransformSchema(x as any, callback))
            .map(Just.Unwrap)
            .filter((x:TransformCallbackResult) => x != DoDrop)
        return schema
    },
    anyOf(schema:Schema, callback:TransformCallback) {
        let res = callback([],schema)
        if(res instanceof Just) 
            return res.value
        if(res  === DoDrop) return res
        schema = {...(res as Schema)||schema}
        schema.anyOf.map((x:Schema) => rawTransformSchema(x as any, callback))
            .map(Just.Unwrap)
            .filter((x:TransformCallbackResult) => x!= DoDrop)
        return schema
    },
    oneOf(schema:Schema, callback:TransformCallback) {
        let res = callback([],schema)
        if(res instanceof  Just) 
            return res.value
        if(res instanceof Drop) return  res
        res = (res !== schema)?res:{...(res as any) || schema}
        (res as any).oneOf = schema.oneOf.map((x:Schema) => rawTransformSchema(x as any, callback))
            .map(Just.Unwrap)
            .filter((x:TransformCallbackResult) => x!= DoDrop)
        return res 
    },
    any(schema:Schema, callback:TransformCallback) {
        let res= callback([],schema)
        if(res instanceof Just) return res.value
        return  res || schema
    }
})

export const transformSchema:Transformer =
    function(schema:Schema,callback:TransformCallback):Schema|null  {
        let res= Just.Unwrap(rawTransformSchema(schema as any,callback))
        if( isDrop(res ) ) return null
        return res;
} as Transformer

transformSchema.Just= function(x:TransformCallbackResult){
    if(x instanceof Just) return x;
    if(x instanceof Drop) return Drop;
    return new Just(x)
}
transformSchema.Drop = DoDrop

const isScalar = createVisitor({
    object(_:Schema){ return false},
    array(_:Schema){ return  false },
    any(x:Schema){
        switch(x.type){
            case "allof":
            case "oneof":
            case "anyof":
                return false
        }
        return true
    }
})


/**
 * Update a schema with x-options.fieldProps that match the schema item
 */
export function schemaErrorLoader(mp:ValidationErrorMap,prefix:string=""):TransformCallback {
    return function(inPath,schema){
        let key!:string ;
        let errKey = schema?.['x-options']?.['errorKey']
        let lastIndex = inPath.length - 1;
        let path = inPath.map((seg:string,index:number) => {
            if(index ==  lastIndex) return errKey || seg
            return seg
        }).join(".")
        if(prefix && prefix.length){
            if(path && path.length) key = prefix + "." + path
            else key = prefix
        }else key=path
        let errors = mp[key]
        if(errors && isScalar(schema)){
            //console.log("Checking",key,errors,mp)
            //console.log("Hit ",key)
            let xOpts = {...(schema['x-options'] || {})}
            let fieldProps = { ...(xOpts.fieldProps|| {}) }
            xOpts.fieldProps = fieldProps
            let msgs!:string[]
            let errs = fieldProps.errorMessages
            if(Array.isArray(errs)){
                msgs = [...errs]
            }else if (errs){
                msgs = [errs]
            }else msgs = []
            fieldProps.errorMessages = msgs;
            errors.forEach(x => msgs.push(x.msg))
            fieldProps.errorMessages = msgs
            schema = { ...schema,'x-options':xOpts}
            //console.log("Changes",key,"->",JSON.stringify(schema))
        }
        return schema
    }
}

function isCompositeNode(x:Schema):boolean {
    return x.type == "group" && ("schema" in x)
}

/**
 * Set a nested element in a schema 
 * filling missing levels with information from src
 */
export function setSchemaField(path:string[],inTgt:SchemaSet,obj:Schema,src:SchemaSet){
    let lastIdx = path.length -1;
    let tgt:Schema|SchemaSet = inTgt;
    path.forEach((elt,idx) =>{
        let last = lastIdx ==idx;
        if("schema" in tgt && "type" in tgt) tgt =tgt["schema"];
        if(elt in tgt){
            tgt = tgt[elt]
            return 
        }
        //Missing elemtn so we create it form src
        if(last){
            tgt[elt]  = obj;
            return
        }
        //we are at an intermediate state 
        let srcElt = (idx > 0)?findSchemaField( path.slice(0,idx),src):null;
        if(srcElt){
            tgt = tgt[elt] = {...srcElt,schema:{}}
        }else {
            tgt = tgt[elt] = {
                type:"group",
                schema:{}
            }
        }
    })
}

/**
 * Find the Schema Element by field and path.
 * @param {string} path The path to find
 * @param {SchemaSet[]} schemas The schemas within which to look for the field
 *
 */
export function findSchemaField(path :string|string[], ...schemas:SchemaSet[]):Schema|undefined{
    //We want a list 
    if(typeof path == "string") path= [path]
    let outNode:any  = null
    for(let x of schemas){
        let node:SchemaSet|Schema|null = x
        for (let name of path ){
            if(node == null) break;
            //This is a list of objects that might contain "wraps"
            // Nodes  (these can be nested)
            let wrapSrc:any[] = []
            //Directly look up name
            if(name in node){
                node = node[name]
            } else if(isCompositeNode(node as Schema)) {
                //HAndle a group.
                if(node.schema){
                    if(name in node.schema!){
                        node = node.schema![name]
                        continue
                    }else{ 
                        //In case there is wraps at this level
                        wrapSrc.push(node.schema!)
                        node=null;
                    }
                }
            } else {
                //Check all the elements that happen to be wraps to find a name match
                //THis is only at this level
                wrapSrc.push(node)
                //Clear node and setup a search for wrapped elements
                node=null;
            }
            //
            //While there are things with wraps in them
            while(wrapSrc.length > 0){
                //This is a object that might have keys that are vlaues that are wraps
                let maybeWrapSet = wrapSrc.shift();
                for(let w in maybeWrapSet){
                    let elt:any = maybeWrapSet[w]
                    if(elt.type == "wrap"){
                        node = elt.schema![name]
                        if(node) {
                            wrapSrc = []
                            break;
                        }else {
                            //Wraps children may be wraps too
                            // So ensure we travers them
                            wrapSrc.push(elt.schema!)
                        }
                    }
                }
            }
            if(!node) break;
        }
        if(node){
            if(!outNode) outNode = {}
            merge(outNode,node);
        }
    }
    return outNode as any
}

export type FilterFunc = (name:string,path:string[],item:Schema)=>boolean|Schema
export type MapFunc = (name:string,path:string[],item:Schema)=>Schema|undefined
export type ForeachFunc = (name:string,path:string[],item:Schema)=>void
/**
 * Given a schema filter it to produce a smaller schema 
 */
export function filterSchema(root:SchemaSet,func:FilterFunc,prefix:string[]=[]):SchemaSet|undefined{
    //We want a list 
    let out:SchemaSet = {};
    for(let x in root){
        let node = root[x]
        if(node.type == "wrap"){
            let filtered = filterSchema(node.schema!,func,prefix)
            if(filtered && !isEmpty(filtered)) {
                out[x] ={...node,schema:filtered};
            }
        }else if(node.type == "group"){
            //A group so we are nesting the path
            let grpPath =[...prefix,x]
            let included = func(x,grpPath,node)
            //For groups check if the top level is accepted
            if(included) out[x] = {...node,...(included===true)?{}:included,schema:node.schema}
            else {
                let filtered = filterSchema(node.schema!,func,grpPath)
                if(filtered && !isEmpty(filtered)) out[x] = {...node,schema:filtered}
            }
        }else {
            let included = func(x,[...prefix,x],node)
            if(included){
                if(included !== true) node = included
                out[x] = node
            }
        }
    }
    return out
}

export function mapSchema(root:SchemaSet,func:MapFunc,prefix:string[]=[]):SchemaSet{ 
    //We want a list 
    let out:SchemaSet = {};
    for(let x in root){
        let node = root[x]
        if(node.type == "wrap"){
            let mapped = mapSchema(node.schema!,func,prefix)
            if(mapped && !isEmpty(mapped)) out[x] ={...node,schema:mapped};
        }else if(node.type == "group" ){
            //A group so we are nesting the path
            let mapped = mapSchema(node.schema!,func,[...prefix,x])
            if(mapped && !isEmpty(mapped)) out[x] = {...node,schema:mapped}
        }else {
            let mapped = func(x,[...prefix,x],node)
            if(mapped) out[x] = mapped
        }
    }
    return out
}

export function foreachSchema(root:SchemaSet,func:ForeachFunc,prefix:string[]=[]){ 
    //Call function for each sschema node
    for(let x in root){
        let node = root[x]
        if(node.type == "wrap"){
            foreachSchema(node.schema!,func,prefix)
        }else if(node.type == "group" ){
            //A group so we are nesting the path
            foreachSchema(node.schema!,func,[...prefix,x])
        }else {
            func(x,[...prefix,x],node)
        }
    }
}


/** 
 *
 */
export function labelForField(name:string,...schemas:SchemaSet[]):string|undefined {
    return findSchemaField(name,...schemas)?.label
}



export function enumIdToTitle(value:string):string {
    return value.split("_").map((x) => upperFirst(x.toLowerCase())).join(" ")
}


