/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiWorkflowInputSchemaItem = {
    properties: {
        identifier: {
            type: 'string',
        },
        ref: {
            type: 'string',
        },
        display_name: {
            type: 'string',
        },
    },
} as const;
