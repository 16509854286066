import Vue,{DirectiveBinding,VNode,nextTick} from "vue"


/**
 * Directive to scroll things into view
 */
Vue.directive("scroll-into-view",{
    inserted(el:HTMLElement,binding:DirectiveBinding<ScrollIntoViewOptions>,node:VNode){
        nextTick().then(() => {
            setTimeout(() => {
                el.scrollIntoView(binding.value) 
            },500)
        })
    }
})
