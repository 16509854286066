
import {FilterSpec} from "~/core/common/filtering"
import {dataCache} from  "~/schemas/enrichment"



export const LineItemFilterSpec:FilterSpec[] = [
    {
        label:"Security",
        field:"unit_leg.instrument.uuid",
        component:"InputInstrument",
        props:{
            objectMode:false
        },
        alias:"security"

    },
    {
        label:"Instrument Type",
        field:"unit_leg.instrument.instrument_type",
        component:"select",
        alias:"typ",
        multiple:true,
        props:{
            chips:true, multiple:true,
            clearable:true,
            itemText:"label",itemValue:"id",
            get items(){
                return dataCache.assetTypes
            }
        }
    },
    {
        label:"Consideration",
        field:"consid_leg.instrument.uuid",
        component:"InputInstrument"
    },{
        label:"Account",
        field:"account.uuid",
        component:"InputAccount",
        alias:"acct"
    },/*{
        label:"Action",
        field:"action",
        component:"select",
        alias:"act"
    },
    */{
        label:"Transaction Time",
        field:"transaction.amendment_time",
        component:Date,
        alias:"ts",
        range:true
    }
    
]

export const TransactionLegFilterSpec:FilterSpec[] = [
    {
        label:"Security",
        field:"instrument.uuid",
        component:"InputInstrument",
        props:{
            objectMode:false
        },
        alias:"security"

    },
    {
        label:"Instrument Type",
        field:"instrument.instrument_type",
        component:"select",
        alias:"typ",
        multiple:true,
        props:{
            chips:true, multiple:true,
            clearable:true,
            itemText:"label",itemValue:"id",
            get items(){
                return dataCache.assetTypes
            }
        }
    },{
        label:"Account",
        field:"account",
        query_targets:["src_account.uuid","dst_account.uuid"],
        component:"InputAccount",
        props:{ objectMode:false },
        alias:"acct"
    },
    {
        label:"Transaction Time",
        field:"transaction.amendment_time",
        component:Date,
        alias:"ts",
        range:true
    },
    {
        label:"Missing Event",
        field:"trans.missing",
        query_targets:["transaction.src_event"],
        component:Boolean,
        operator:"is_null",
        alias:"no_evt",
        adminOnly:true
    },
    {
        label:"Root Event",
        field:"trans.root",
        query_targets:["transaction.src_event.uuid"],
        component:"InputEvent",
        alias:"root"
    }
    
]
