
import {
    InputApiDocument,InputApiDocumentLink
} from "~/schemas/gen"
import {UploadJob,UploadJobState} from "~/store/documents"
import {Component,Vue,namespace} from "nuxt-property-decorator"


const documentNs = namespace('documents')

export type InputDocWithFile = InputApiDocument & { 
    flat_tags:string[] // Manadatory in this forumation
    file:File
    job?:UploadJob
}




export type IUploadLinkTarget  = Pick<InputApiDocumentLink,"entity"|"account"|"transaction"|"prospect"|"event"|"instrument">;

export interface IWithDocuments {
    documents:InputDocWithFile[]
    
}
export interface IDocumentProvider extends IWithDocuments {
}

export interface ISubmitEvents {
    onError(error:Error,doc?:InputDocWithFile):void
    onSuccess(doc:InputDocWithFile):void
}


export function makeDocument(f:File,tags:string[]=[]):InputDocWithFile{ 
    return{
        name:f.name, storage_size: f.size,
        file:f, flat_tags:tags,
        job:undefined
    }
}



export function mergeDocuments(items:IWithDocuments['documents'],
                        files:File[],
                        tags:string[]):IWithDocuments['documents']{
    let out = [...items]
    for(let f of files){
        out.push(makeDocument(f,tags))
    }
    return out;
}




/**
 * Render less component that handles file uploads
 */
@Component({})
export class WithDocumentUpload extends Vue {
    @documentNs.Action('startUpload') startUpload!:any
    @documentNs.Action('waitForJob') waitForJob!:(id:number) => Promise<UploadJob>;
    @documentNs.Action('getJob') getUploadJob!:(id:number) => Promise<UploadJob>;
    provider!:IDocumentProvider
    uploading:boolean = false

    withProvider(p:IDocumentProvider):WithDocumentUpload{
        this.provider =p;
        return this
    }
    get documents(){  return this.provider.documents }

    isUploadReady(payload:IWithDocuments,tags?:string[]){
        if(!tags || tags.length  ==0) return payload.documents.length >0 ;
        for (let x of payload.documents){
            if(!x.flat_tags) continue;
            for(let t of tags){
                if(x.flat_tags.indexOf(t)!= -1) return true;
            }
        }
        return false;
    }

    // Remove file from document list
    removeFile(item:InputDocWithFile,
               target?:IWithDocuments){
        target=  target ?? this.provider;
        let docs = target.documents.filter((x) => {
            return x !== item;
        })
        target.documents=docs
    }

    clear(){
        this.provider.documents = []
    }

    //Filter Document tags
    filterDocumentsByTags(tags:string[]){
        return this.provider.documents.filter((x) => {
            return x.flat_tags.filter(t =>  tags.includes(t)).length > 0;
        })
    }

    getDocumentJob(doc:InputDocWithFile):UploadJob|undefined {
        for(let d of this.provider.documents || []){
            if(d === doc) return d.job
        }
        return;
    }

    //Handle a droipped file across all the upload views
    acceptDocumentUpload(items:File|FileList,
                         tags:string[]|undefined,target?:IWithDocuments){
        let files!:File[]
        if(items instanceof FileList){
            files = Array.from(items)
        }else {
            files = [items]
        }
        let tgt=(target ||this.provider);
        tgt.documents = mergeDocuments(tgt.documents,files,tags || [])

    }
   

    setUploadingState(newState:boolean){
        this.uploading = newState;
    }

    /**
     * Start the process of uploading documents
     */
    async startSubmissionUpload(targets:IUploadLinkTarget[],events?:ISubmitEvents):Promise<boolean>{
        //We are a the end of the line so we will submit the documents
        let {documents} = this.provider;
        try{
            this.setUploadingState(true) 
            let failed= 0;
            for(let d of documents){
                if(d.job && d.job.response){
                    if(d.job.state == UploadJobState.Completed)  continue;
                }
                let input:InputApiDocument = {...d,link_updates:{adds:[]}}
                // If thi
                /* if(this.value.prospect_id){
                    input.link_updates!.adds?.push({prospect:this.value.prospect_id})
                }else if(this.editor.model?.id){
                    let docId =  this.editor.model!.id!
                    input.link_updates!.adds!.push({transaction:docId})
                }*/
                input.link_updates!.adds!.push(...targets)
                let jobId = await this.startUpload({input,file:d.file }).catch((err:Error) =>err);
                if(jobId instanceof Error){
                    events?.onError?.(jobId,d);
                    console.log("job Error!",jobId);
                    continue
                }
                //Wait for the job to complete
                let job = d.job = await this.waitForJob(jobId!);
                if(job.error){
                    failed += 1 
                }
            }
            if(failed > 0){
                return false
            }
            return true
        }catch(err:any){
            console.log("Submission Error!",err)
            events?.onError(err)
            return false;
        }finally {
            this.setUploadingState(false);
        }
    }
}
