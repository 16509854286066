/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $InstrumentTimeSeriesRequest = {
    description: `Request Time series for an instrument `,
    properties: {
        instruments: {
            type: 'array',
            contains: {
                type: 'any-of',
                contains: [{
                    type: 'TimeSeriesSelect',
                }, {
                    type: 'string',
                    format: 'uuid',
                }, {
                    type: 'InstrumentTriple',
                }],
            },
            isRequired: true,
        },
        query: {
            type: 'TimeSeriesQuery',
            isRequired: true,
        },
        target_instrument: {
            type: 'any-of',
            contains: [{
                type: 'string',
                format: 'uuid',
            }, {
                type: 'InstrumentTriple',
            }],
        },
    },
} as const;
