/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BusinessDayConvention } from './BusinessDayConvention';
import type { CouponType } from './CouponType';
import type { DayCountConvention } from './DayCountConvention';
import type { NotionalExchange } from './NotionalExchange';
import type { Periodicity } from './Periodicity';

export type BondDetails = {
    detail_type?: BondDetails.detail_type;
    notional_exchange: NotionalExchange;
    payable_unit_id: string;
    periodicity: Periodicity;
    coupon_percent?: number;
    coupon_spread?: number;
    coupon_type?: CouponType;
    first_pay_date?: string;
    last_pay_date?: string;
    dct?: DayCountConvention;
    bdc?: BusinessDayConvention;
};

export namespace BondDetails {

    export enum detail_type {
        BOND = 'BOND',
    }


}

