/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $NumberFormat = {
    properties: {
        type: {
            type: 'Enum',
            isRequired: true,
        },
        format: {
            type: 'string',
            isRequired: true,
        },
    },
} as const;
