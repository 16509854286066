/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum AcctType {
    DEPOSITARY = 'D',
    ASSET_MANAGEMENT = 'A',
    EQUITY = 'EQ',
    PREFERRED_EQUITY = 'EP',
    TREASURY_STOCK = 'TS',
    OTHER_EQUITY = 'EO',
    DEBT = 'DE',
    OTHER_DEBT = 'OD',
    OTHER_ASSET = 'OA',
    OTHER_LIABILITY = 'OL',
    UNREALIZED = 'UG',
    REALIZED_LONG = 'LG',
    REALIZED_SHORT = 'SG',
    INCOME = 'I',
    EXPENSE = 'EX',
    RETAINED = 'RE',
}
