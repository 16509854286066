/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ReportNoteMeta = {
    description: `MetaData for a related Event`,
    properties: {
        related: {
            type: 'array',
            contains: {
                type: 'RelatedReport',
            },
        },
        review: {
            type: 'boolean',
        },
        name: {
            type: 'string',
        },
        formatters: {
            type: 'dictionary',
            contains: {
                type: 'array',
                contains: {
                    type: 'any-of',
                    contains: [{
                        type: 'one-of',
                        contains: [{
                            type: 'ComponentFormat',
                        }, {
                            type: 'DateTimeFormat',
                        }, {
                            type: 'NumberFormat',
                        }, {
                            type: 'Ref',
                        }, {
                            type: 'TableStyle',
                        }],
                    }, {
                        type: 'string',
                    }],
                },
            },
        },
        aggregates: {
            type: 'dictionary',
            contains: {
                type: 'ReportAggrCol',
            },
        },
    },
} as const;
