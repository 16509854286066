
import {Vue,Prop, Component,Getter ,namespace,ModelSync} from "nuxt-property-decorator"
import { ApiTransaction,InputApiDocument ,
    ApiDocumentLink,InputApiDocumentLink,
    ApiDocumentLinkUpdates
} from "~/schemas/gen"
import {fragApiEntityFields} from "~/core/entity"
import {fragApiAccountFields} from "~/components/input/Account.vue"
import {fragApiTransactionFields} from "~/components/input/Transaction.vue"
import { ApiDocumentNode }  from "~/core/documents"
import { 
    ApiTransactionProspectNode,
    fragApiTransactionProspectFields 
} from "~/components/prospect"
import {fragApiEventNodeFields} from "~/core/events"
import {fragApiInstrumentFields } from "~/components/input/Instrument.vue"
import { fragApiObservableDataFields } from "~/core/observable"
import {
    CandidateNode,ApiLinkEntry,IApiLinkTarget,
    candidateName,candidateType,candidateChild,
    candidateId,
    ChangeNode,ApiDocumentLinkNode,
    LINK_TARGET_FIELDS,candidateTypeToString
} from "./LinkInput.vue"
import { DataTableHeader } from "vuetify/types"
import gql from "graphql-tag"
import TreeNode from "~/components/grid/TreeNode"
import {fragApiWorkflowFields} from "~/core/workflows"



const userNs = namespace("user")



/**
 * Panel renders and allows additions of Objects linked to documents.
 *
 */
@Component({
           apollo:{
                   rawLinks:{
                       manual:true,
                           query:gql` query existing_links($q:entity_document_links_bool_exp){
                        rawLinks: entity_document_links(where:$q) {
                            uuid attributes
                            document { uuid }
                            entity { ...ApiEntityFields }
                            transaction { ...ApiTransactionFields }
                            account { ...ApiAccountFields } 
                            prospect:transaction_prospect {...ApiTransactionProspectFields }
                            event { ...ApiEventNodeFields}
                            instrument {...ApiInstrumentFields}
                            observable {...ApiObservableFields }
                            workflow { ...ApiWorkflowFields }
                        }
                        }
                    ${fragApiAccountFields}
                    ${fragApiEntityFields}
                    ${fragApiTransactionFields} 
                    ${fragApiTransactionProspectFields}
                       ${fragApiEventNodeFields}
                       ${fragApiInstrumentFields}
                       ${fragApiObservableDataFields}
                       ${fragApiWorkflowFields}
                       `,
                           variables(){
                               return {
                                   q: {
                                       document: {uuid:  { _eq: this.document.uuid} } 
                                   }
                               }
                           },
                           result(res:any,key){
                               let elts = res?.data?.rawLinks ?? []
                               this.rawLinks = elts.map((x:ApiDocumentLinkNode) => ({
                                   ...x,
                                       __typename:candidateChild(x).__typename
                               }))
                           }
                   },
           },components:{
               TreeNode
           },filters:{candidateTypeToString}
})
export default class DocumentLinkPanel extends Vue {
    @ModelSync('value','change')  document!:InputApiDocument
    @Prop({default:() => []}) readonly initialLinks!:ApiDocumentLinkNode[] 
    @Prop({default:false}) readonly up!:boolean;
    //Links of the existing document
    rawLinks:ApiDocumentLinkNode[] = []
    //Representation of the change that is inbound
    changes:Record<string,ChangeNode> = {}
    //Cache of candidates that have been added since this control was created.
    candidateCache:Record<string,CandidateNode> = {}
    @userNs.Getter("systemUserId") readonly systemUserId!:number


    LINK_COLUMNS:DataTableHeader[] = [
        { text:"Type", value:"type",width:100,sortable:false},
        { text:"Name", value:"name"},
        {text:"",value:"actions","cellClass":"text-right",sortable:false}
    ]

    
    get links():ApiLinkEntry[] {
      
        let iter= function*(this:DocumentLinkPanel){
            for(let x in this.changes){
                let node =this.changes[x];
                if(node.candidate){
                    yield {
                        uuid: x,
                        change: node,
                        ...node.candidate
                    }
                }else {
                    yield { uuid:x,change:node,
                        ...node.node }
                }
            }
            yield* this.rawLinks.filter(x => !(x.uuid in this.changes))
        };
        let res =iter.bind(this)
        return [...res()]
    };

    
    //FIlecycle events
    mounted(){
        // @TODO: Poppulate changes based on input document
        //When we are mounted we want to setup 
    }

    isAdd(node:ApiLinkEntry){
    return node.change?.action == 'add' 
    }
    isUpdate(node:ApiLinkEntry){
    return this.isAdd(node) && !!node.change?.node
    }
    isDelete(node:ApiLinkEntry){
        if(node.change){
            return node.change.action == "del"
        }
        return false
    }
    removeLink(node:ApiLinkEntry){
        if(node.change){
            let {change} = node;
            switch(change.action){
                case "add":
                    this.removeById(candidateId(change.candidate!))
                    return 
                case "del":
                    this.removeById(node.uuid)
            }
        }else {
            //for a change with an id 
            this.removeExisting(node)
        }
    }
    get asDocumentLinkUpdates():ApiDocumentLinkUpdates {
        let to_add:InputApiDocumentLink[] =Object.entries(this.changes)
            .filter(([_,cn]) => cn.action == "add")
            .map(([k,cn]) => {
                //Make and INputApiDocumentLink Node 
                let target!:keyof IApiLinkTarget
                for(let x of LINK_TARGET_FIELDS){
                    if(cn.candidate && cn.candidate[x]) {
                        target= x;
                        break;
                    }
                }
                let tgt :any = cn.candidate![target]
                let rec:InputApiDocumentLink= {
                    [target]:(tgt.uuid ?? tgt.ulid),
                    attributes:cn.attributes ?? {}
                }
                //IF its an existing item then specify the uuid
                if(cn.node){
                    rec.uuid = candidateId(cn.node)
                }
                return rec;
            })

        return {
            adds:to_add,
            deletes:Object.values(this.changes)
            .filter(x => x.action == "del").map(x=>x.node!.uuid)
        }
    }
    //Does this candidate have and existing link
    isExistingLink(node:CandidateNode):boolean {
        let cid = candidateId(node)
        return this.rawLinks.findIndex(x => {
            return x.uuid == cid
        }) != -1
    }
    addCandidate(node:CandidateNode,$event:MouseEvent){
        let rec:ChangeNode = {
            action:"add",
            candidate:node 
        }
        //cehck to see if the candidate exsits
        let cid = candidateId(node)
        for(let f of this.rawLinks){
            if(candidateId(f)  == cid){
                rec.node = f
                break;
            }
        }
        $event.preventDefault();
        //We will cache these to make the round trips faster for actions
        this.candidateCache[cid] =  node
        this.changes= {...this.changes, [cid]:rec}
        this.updateDoc()
    }
    updateDoc(){
        this.document = { ...this.document,
            link_updates :this.asDocumentLinkUpdates
        }
    }
    removeExisting(node:ApiLinkEntry){
        let rec:ChangeNode = { action:"del", node }
        this.changes =  {...this.changes,[node.uuid]:rec }
        this.updateDoc()
    }

    removeCandidate(node:CandidateNode){
        let id = candidateId(node)
        this.removeById(id)
    }
    removeById(id:string){
        let changes= {...this.changes}
        delete changes[id]
        this.changes= changes
    }

    async fetch(){
        this.$apollo.queries.rawLinks.refresh();
    }
    linkName(x:ApiLinkEntry):string {
        if(x.change && x.change.candidate) return candidateName(x.change.candidate!)
        let  child = candidateChild(x)
        return candidateName(x,child.__typename)
    }
    linkType(x:ApiLinkEntry):string {
        if(x.change && x.change.candidate){
            return candidateType(x.change.candidate!)
        }
        if(x.entity) return "Entity"
        else if (x.account) return "Account"
        else if (x.transaction) return "Transaction"
        else if (x.prospect) return "Transaction Prospects"
        else if (x.event) return "Event"
        else if (x.instrument) return "Instrument"
        else if (x.observable) return "Observable"
        else if (x.workflow) return "Workflow"
        return "???"
    }
    
  
}
