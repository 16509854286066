/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ComponentFormat = {
    properties: {
        type: {
            type: 'Enum',
            isRequired: true,
        },
        name: {
            type: 'string',
            isRequired: true,
        },
        props: {
            properties: {
            },
        },
        value: {
            type: 'string',
        },
    },
} as const;
