/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum FixedOrPercentage {
    FIXED = 'F',
    PERCENTAGE = '%',
}
