
export const  state = () => ({
    lastMessage: <null|Record<string,any>> null
})


export const getters = {
    lastMessage(state:any){
        return state.lastMessage;
    }
}


export const mutations  = {
    message(state:any,data:any){
        state.lastMessage = data;
    }
}
