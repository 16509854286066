/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $FileRefMapping = {
    description: `Reference to a mapping from an advisor note`,
    properties: {
        note_key: {
            type: 'string',
            isRequired: true,
        },
    },
} as const;
