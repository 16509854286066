/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AnalyticDetail = {
    properties: {
        expression: {
            type: 'string',
        },
        label: {
            type: 'string',
        },
        meta: {
            properties: {
            },
        },
    },
} as const;
