/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum InterestPaymentConvention {
    FIXED = 'FIX',
    ARM = 'ARM',
}
