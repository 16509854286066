/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $PipelineSetup = {
    properties: {
        steps: {
            type: 'array',
            contains: {
                type: 'PipelineStepEntry',
            },
            isRequired: true,
        },
        related: {
            type: 'array',
            contains: {
                type: 'PipelineRelated',
            },
        },
        event_type: {
            type: 'Enum',
        },
    },
} as const;
