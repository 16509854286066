/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $FundFlowClauseDetails = {
    properties: {
        id: {
            type: 'any-of',
            contains: [{
                type: 'string',
                format: 'uuid',
            }, {
                type: 'number',
            }, {
                type: 'string',
            }],
            isRequired: true,
        },
        identifier: {
            type: 'string',
            isRequired: true,
        },
        notional_target: {
            type: 'FundNotionalTarget',
            isRequired: true,
        },
        payable_unit: {
            type: 'InstrumentRef',
            isRequired: true,
        },
        payable_notional: {
            type: 'number',
        },
        lp_share: {
            type: 'number',
            isRequired: true,
        },
        periodicity: {
            type: 'Periodicity',
        },
        recur_type: {
            type: 'RecurType',
        },
        rate: {
            type: 'number',
        },
        priority: {
            type: 'number',
        },
        is_management_fee: {
            type: 'boolean',
        },
        dct: {
            type: 'DayCountConvention',
        },
        limits: {
            type: 'FlowClauseLimit',
        },
    },
} as const;
