import { components } from "~/schemas/api"
import { InputTransaction } from "~/schemas/transaction_schemas"
import  isPlainObject from "lodash/isPlainObject"
import {DEFAULT_ACCOUNT} from "~/core/account"
import {Transactions_Bool_Exp,
    Transaction_Legs_Bool_Exp,
Transaction_Line_Items_Bool_Exp} from "~/models/graphql"

import {InstrumentRef,AccountRef,AccountRefType,
    AddTransactionRequest,
    InstrumentRefType,InstrumentType} from "~/schemas/gen"
type Direction  = components["schemas"]["Direction"]


export function makeCurrency(name:string|InstrumentRef): InstrumentRef  {
    if(typeof(name) !== "string") return name;
    return { "id":name,type:InstrumentRefType.CCY}
}

export function genericNamedInstrument(name:string):InstrumentRef {
    return {
        id:name,
        type:InstrumentRefType.NAME
    }
}





export function isOneSided(value:Direction):boolean {
    switch(value){
        case "P":
        case "R":
            return true
    }
    return false
}


export function legSearchExp(search:string):Transaction_Legs_Bool_Exp {
    let clauses:Transaction_Legs_Bool_Exp[] = []
    search= search?.trim?.();
    if(search && search != ""){
        search = "%" + search + "%"
        let acctClause = { _or:[{ name : { _ilike: search  } },
            { entity: { name : { _ilike: search  } }}] };

        clauses.push(
            { src_account: acctClause },
            { dst_account: acctClause},
            { transaction: { transaction_id: {  _ilike:search} }},
            { instrument: { display_name: {_ilike:search }}},
        )
    }
    if(clauses.length ==0) return {}
    return {_or:clauses} 
}

export function lineItemSearchExp(search:string):Transaction_Line_Items_Bool_Exp{
    let clauses:Transaction_Line_Items_Bool_Exp[] = []
    search= search?.trim?.();
    if(search && search != ""){
        search = "%" + search + "%"
        clauses.push(
            { account: { name : { _ilike: search  } }},
            { account: { entity: { name : { _ilike: search  } }}},
            { transaction: { transaction_id: {  _ilike:search} }},
            { unit_leg : { instrument: { display_name: {_ilike:search }}}},
            { consideration: { instrument: { display_name: {_ilike:search }}}}
        )
    }
    if(clauses.length ==0) return {}
    return {_or:clauses} 
}



export function transactionSearchExp(rawSearch:string,includeLineItems:boolean=true):Transactions_Bool_Exp{
    let clauses:Transactions_Bool_Exp[] = []
    let search= rawSearch?.trim?.();
    if(search && search != ""){
        search = "%" + search + "%"
        clauses.push(
            { transaction_id: {  _ilike:search} })
        if(includeLineItems){
            let lineItemsQ =lineItemSearchExp(rawSearch)
            for(let x in lineItemsQ){
                //Just to check if it has any keys
                clauses.push({line_item: lineItemsQ})
                break;
            }
        }
    }
    if(clauses.length ==0) return {}
    return {_or:clauses} 
}
