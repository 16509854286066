import type {Context} from "@nuxt/types"
import {ref,watch} from "vue"
import { AccessService, ApiUser,UniverseInfo, UserInfo } from  "~/schemas/gen"
import {
    addRequestInterceptor, addContextData, RequestData
} from "~/schemas/gen/core/request"

export interface IUniverse {
    /// Update to swithc to some other universe
    update(user_id:string):Promise<UniverseInfo>;
    // Set the universe of the active logge din user
    userUniverse(user:ApiUser,token:string):UniverseInfo;
    //Transation to roo tuniverse 
    toRoot():void;
    updateRootToken(token:string):void;
    root:UniverseInfo|null;
    ready:boolean;
    current:UniverseInfo|null; // What is the current universe
    currentId:string|null
    isDistinct:boolean; //Is this universe different from the users 
    logout():void;
    refresh():Promise<void>
}

declare module 'vue/types/vue' {
    interface Vue {
        $universe:IUniverse
    }
}
declare module 'vuex/types' {
    interface Store<S> {
        $universe:IUniverse
    }
}

declare module '@nuxt/types' {
    interface Context {
        $universe:IUniverse
    }
}

const SS_KEY = "auth.universe.current"
export const HEADER_X_UNIVERSE = "x-distributary-universe"
const HEADER_X_UNIVERSE_UPDATED= "x-distributary-universe-updated"
const HEADER_X_ROOT = "x-distributary-root-token"

let universe!:IUniverse



export function useUniverse():IUniverse {
    return universe
}

export function withinUniverse<T>(value:string, handler:() => T):T { 
    if(!value) return handler()
    addContextData(x => {
        return x.set(HEADER_X_UNIVERSE,value)
    })
    return handler()
}

/**
 * Initialize the user model
 */
export default function({app,store,$axios,$auth,$apolloHelpers,query}:Context,inject:any){
    
    const current = ref<UniverseInfo|null>(null)
    const root = ref<UniverseInfo|null>(null)
    universe =  {
        async update(id:string){
            let info = await AccessService.lookupUniverse(id) 
            current.value = info 
            $apolloHelpers.tokenRefresh(info.access_token)
            return  info
        },
        userUniverse(user:ApiUser,token:string){
            root.value = current.value = {user,access_token:token}
            
            return current.value
        },
        get root():UniverseInfo {
            return root.value!;
        },
        updateRootToken(token:string){
            if(root.value){
                root.value.access_token = token
            }
        },
        toRoot(){
            current.value = root.value;
            $apolloHelpers.tokenRefresh(root.value!.access_token)
        },
        get current():UniverseInfo|null {
            return current.value
        },
        get currentId():string|null {
            return current.value?.user?.profile?.uuid ?? null
        },
        get isDistinct():boolean {
            let v = current.value;
            if(!v) return false;
            return v.grant != null
        },
        get ready(){
            return current.value !== null
        },
        logout(){
            //Logout of universes
            root.value = current.value = null;
        },
        async refresh(){
            let resp = await $auth.refreshTokens()
            if(resp){
                //When a refresh happens we will
                //updat ethe root universe if it needs to be updated and 
                //thenw e will upade the root or current if they are relevant
                if(resp.headers[HEADER_X_ROOT] == "1"){
                    store.dispatch("user/setRootToken",resp.data.access_token)
                }
                const info = resp.data as UserInfo
                let noUpdate = true
                let currentUpdate = false;
                for(let tgt of [root,current]){
                    if( tgt.value?.user?.profile?.uuid == info.user?.profile?.uuid){
                        tgt.value!.access_token = info.access_token
                        if(tgt == current) currentUpdate = true
                        noUpdate =false;
                    }
                }
                if(!currentUpdate == true){
                    //If the current context wasnt updated we need to do that
                    await  this.update(this.currentId!)
                }
                if(noUpdate){
                    console.error("TOken refresh for non current and non root user")
                }
            }
        }
    }

    let ssUniverse=  window.sessionStorage.getItem(SS_KEY)
    //When current changed we will update the session store 
    watch(current,(value) => {
        if(value){
            window.sessionStorage.setItem(SS_KEY,JSON.stringify(value))
        }else {
            window.sessionStorage.removeItem(SS_KEY);
        }
    })
    watch(() => store.getters['user/rootToken'],(token) =>{
        universe.updateRootToken(token)
    })
    //Handle profile refreshing translating into changes in  the 
    //active profile infomration
    watch(() => ($auth.user as ApiUser)?.profile?.updated_time,(upd)=> {
        let user =$auth.user as ApiUser; 
        let id = user?.profile?.uuid;
        if(!id) return;
        for(let tgt of [current,root]){
            if(tgt.value && tgt.value.user.profile?.uuid == id){
                tgt.value.user = user;
            }
        }
    })
    
    let user:ApiUser = $auth.user as ApiUser;
    // userToken 
    if(user){
        universe.userUniverse(user,store.getters['user/rootToken'])
    }
    let setUniverse = false;


    if(ssUniverse){
        try{
            let parsed:UniverseInfo = JSON.parse(ssUniverse)
            if(parsed.user && parsed.access_token){
                current.value = parsed;
                setUniverse = true;
                if(user && user.username == parsed.user.username){
                    // Parsed user is the auth user we will take the latest infrmation from auth
                    // This is to makre sure that universe user is consistent
                    current.value.user =user;
                }
            }
        }catch(ex){
            console.log("Error loading universe")
        }
    }



    watch(root,(value) => {
        //console.log("Root Token Update ->",value?.access_token)
    },{deep:true})


    app.router?.beforeResolve(async (to,from,next) => {
        try{
            let query = to.query
            if(!query.universe) return
            if(universe.currentId == query.universe) return 
            await universe.update(query.universe as string)
                .catch(err =>{
                })
        }finally {
            next()
        }
    })


    //Now check the session 
    inject("universe",universe)
    /**
     * We will add a universe header to outbound requests
     */
    addRequestInterceptor(function(rd:RequestData) {
        //Is there 
        let univOverride= rd.context?.get?.(HEADER_X_UNIVERSE,null)
        if(univOverride){
            rd.headers[HEADER_X_UNIVERSE] = univOverride
        }else {
            let univ = current.value;
            if(univ){
                rd.headers[HEADER_X_UNIVERSE] = univ.user.profile!.uuid
            }
        }
    })
    $axios.interceptors.response.use(function(response){
        try{
        let updated:string|string[]= response.headers[HEADER_X_UNIVERSE_UPDATED];
        if(!Array.isArray(updated)  && updated) updated =[updated]
        //Trigger refetch of the user
        if(!updated || updated.length ==0) return;
        $auth.fetchUser().catch(err => console.log("Failed Refetch User",err))
        for (let x of updated){
            if(universe.currentId ==  x){
                universe.update(x).catch(err => console.log("Failed Universe Update",err))
                break;
            }
        }
        }finally{
            return response
        }
    },function(error){
        return Promise.reject(error)
    })
}

