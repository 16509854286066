/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $BatchRequest_InputApiAccount_ = {
    properties: {
        requests: {
            type: 'array',
            contains: {
                type: 'InputApiAccount',
            },
            isRequired: true,
        },
        import_context: {
            type: 'BatchImportContext',
        },
        update: {
            type: 'boolean',
        },
    },
} as const;
