/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiAccount } from '../models/ApiAccount';
import type { BatchImportResponse_ApiAccount_ } from '../models/BatchImportResponse_ApiAccount_';
import type { BatchRequest_InputApiAccount_ } from '../models/BatchRequest_InputApiAccount_';
import type { InputApiAccount } from '../models/InputApiAccount';
import type { ListResponse_ApiAccount_ } from '../models/ListResponse_ApiAccount_';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AccountService {

    /**
     * List Accounts
     * @param limit
     * @param offset
     * @param host
     * @returns ListResponse_ApiAccount_ Successful Response
     * @throws ApiError
     */
    public static listAccounts(
        limit: number = 10,
        offset?: number,
        host?: string,
    ): CancelablePromise<ListResponse_ApiAccount_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/accounts',
            headers: {
                'host': host,
            },
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Account
     * @param requestBody
     * @param commit
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static addAccount(
        requestBody: InputApiAccount,
        commit: boolean = true,
        host?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/accounts',
            headers: {
                'host': host,
            },
            query: {
                'commit': commit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Bulk Account Create
     * @param requestBody
     * @param host
     * @returns BatchImportResponse_ApiAccount_ Successful Response
     * @throws ApiError
     */
    public static bulkAccountCreate(
        requestBody: BatchRequest_InputApiAccount_,
        host?: string,
    ): CancelablePromise<BatchImportResponse_ApiAccount_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/accounts/bulk/create',
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Account
     * @param uuid
     * @param requestBody
     * @param commit
     * @param host
     * @returns ApiAccount Successful Response
     * @throws ApiError
     */
    public static updateAccount(
        uuid: string,
        requestBody: InputApiAccount,
        commit: boolean = true,
        host?: string,
    ): CancelablePromise<ApiAccount> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/accounts/{uuid}',
            path: {
                'uuid': uuid,
            },
            headers: {
                'host': host,
            },
            query: {
                'commit': commit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Account
     * @param id
     * @param host
     * @returns ApiAccount Successful Response
     * @throws ApiError
     */
    public static getAccount(
        id: string,
        host?: string,
    ): CancelablePromise<ApiAccount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/accounts/{id}',
            path: {
                'id': id,
            },
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
