/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ImportStatus {
    IMPORTED = 'I',
    INPROGRESS = 'P',
    REJECTED = 'R',
    EDITED = 'E',
}
