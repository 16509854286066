import Vue,{VNode} from "vue"
import {Component,Watch,Prop} from "nuxt-property-decorator"
import {
    IFlowActivityEvents,FlowActivity,FlowAction,
    FlowId,ISpanQuery,ToActivityOptions,FlowSpec,
    isActivity,ErrorFunc,FlowClose
} from "~/core/flows"


enum StepStatus {
    Queued=0,Started =1 , Processing = 2, Invalid = 3 , Completed =4
}
/**
 * Event when a step in teh flow cahnged
 */
export interface StepChangeEvent<T> {
    flow:Flow
    on:IFlowActivityEvents<T>
    activity:FlowActivity,
    prev: {
        activity:FlowActivity
    }
}

export interface ChangeEvent<T> {
    step:number
    payload:T
}

type CreateElement = typeof Vue.prototype.createElement
export class FlowError extends Error {}


/**
 * State of a partoculr step
 */
@Component
class StepState extends Vue {
    status:StepStatus = StepStatus.Queued
    /*payload:any|null = null
    withPayload(p:any):StepState {
        this.payload =p;
        return this
    }*/
    get started():boolean { return this.status == StepStatus.Started }
    get processing():boolean { return this.status == StepStatus.Processing }
    get completed():boolean { return this.status == StepStatus.Completed }
}


type VintagePair= [FlowSpec,FlowId]

/**
 * Component that renders a flow
 * Flow element are expected to be components or  slot names
 */
@Component({
    model:{
        prop:'input',
        event:'update'
    }
})
export default class Flow extends Vue {
    @Prop({required:true,type:Object}) readonly spec!:FlowSpec
    @Prop({required:false,type:Boolean}) readonly vertical!:boolean
    @Prop({default:0}) readonly startAt!:number
    @Prop({type:Object,required:true}) readonly input!:any
    @Prop({type:Function}) readonly isEditable?:(act:FlowActivity,flow:Flow)=> boolean;
    @Prop({type:Function}) readonly isComplete?:(act:FlowActivity,flow:Flow)=> boolean;
    @Prop({type:Function}) readonly hasErrors?:ErrorFunc;
    @Prop({type:Number,default:null}) readonly currentStep!:number|null;
    internalStep:number = 1
    stepStates:Record<FlowId,StepState> = { }
    stepperVintage:number = new Date().getTime()

    get step():number {
        return this.currentStep !== null?this.currentStep:this.internalStep;
    }
    set step(x:number){
        this.internalStep = x;
        this.$emit('update:current-step',x);
    }

    get currentActivityId():FlowId {
        return this.spec.idAt(this.step)!
    }
    get currentActivity():FlowActivity|undefined{
        return this.spec.at(this.step)
    }

    //Active State
    get currentStepState():StepState {
        return this.stepStates[this.currentActivityId];
    }

    get vintagePair():VintagePair{
      return  [this.spec,this.currentActivityId]
    }


    @Watch('currentStepState')
    onStepStateChange(newState:StepState|undefined){
        if(newState === undefined){
            this.updateStepState(this.currentActivityId,new StepState())
        }
    }

    @Watch('vintagePair')
    onVintageChange([s1,i1]:VintagePair,[s2,i2]:VintagePair){
      if(s1 != s2){
        if(i1 != i2){
          this.stepperVintage =  this.stepperVintage + 1;
        }
      }
    }

    /**
     * Active Stepper page
     */
    updateStep(nextStep:number){
        //@TODO: run  validation guards
        this.step=nextStep;
        let prev = {
            activity:this.currentActivity
        }
        let nextAct =this.spec.at(nextStep)!
        this.$emit("update:step",nextStep)
        this.$emit("step-change",{
            step:nextStep,
            flow:this,
            on:this.getActivityEvents(nextAct!),
            activity:nextAct,
            prev
        })
    }

    //Merge new object into state of a step
    updateStepState(id:FlowId,newState:StepState):Flow{
        this.stepStates =  Object.assign({},this.stepStates,{[id]:newState})
        return this;
    }

    partialStateUpdate(id:FlowId,upd:Partial<StepState>):Flow{
        if(id in this.stepStates){
            Object.assign(this.stepStates[id],upd )
        }
        return this
    }



    //Does this step have a state
    hasStepState(id:FlowId):boolean{
        return id in this.stepStates
    }

    /**
     * Event And activity handlers
     */
    cancel(...args:any[]){
        this.$emit("cancel",this.currentActivity,this,...args)
    }

    async toActivityByOffset(off:number,opts?:Partial<ToActivityOptions>){
    }

    /**
     * Transition to activity
     *
     */
    async toActivity(id:FlowId|boolean,opts?:Partial<ToActivityOptions>){
        let {spec } = this;
        var nextId:FlowId|undefined = undefined
        var nextStep = this.step;
        let startId!:FlowId
        let currentActivity!:FlowActivity;
        let q!:ISpanQuery
        if(id === true){ //We are going forward
            nextStep = this.step + 1;
            nextId = spec.idAt(nextStep);
            if(!nextId) {
                this.$emit("complete",this.input,this)
                return true;
            }
            startId = this.currentActivityId;
            currentActivity =this.currentActivity!;
            q = { after:startId,until:nextId}
        }else if (id === false){
            if(this.step == 0 ) throw new FlowError("Invalid Operation. No previous Activity")
            nextStep = this.step - 1
            this.updateStep(nextStep)
            return;
        }else {
            //Here they are jumping to a particualr state
            nextStep= spec.indexOf(id);
            if(nextStep){
                this.updateStep(nextStep)
                return;
            }
            //if the state is not an activity, we weill check for an action
            if(!(id in spec.states)) throw new FlowError(`Unable to find activity '${id}'`)
            //There is an logic at that position
            startId = id
            nextId = spec.activityAfter(id)
            if(nextId) nextStep = spec.indexOf(nextId);
            q = {from:startId,until:nextId}
        }
        //Here we
        let payload = this.input
        this.partialStateUpdate(startId,{
            status:StepStatus.Processing
        })

        for(let item of spec.span(q)){
            if(isActivity(item)){
                throw new FlowError(`Unexpected Activity in flow @ '${item.id}'` )
            }
            if(item == FlowClose) {
                this.cancel();
                return;
            }
            //Item is a Flowbranch
            //Run the logic
            try {
                let resp = await item.onEnter(currentActivity!,this, payload)
                if(resp.payload){
                    this.$emit('update',resp.payload);
                    await this.$nextTick();
                }
                if("action" in resp){
                    switch(resp.action){
                        case FlowAction.ACT_OK:
                            continue;
                        case FlowAction.ACT_CXL:
                            this.cancel();
                            break;
                        case FlowAction.ACT_DONE:
                            this.$emit("complete",payload,this)
                            break
                        default:
                            console.error("Unhandle Branch Response",item)
                            break;
                    }
                }else if ("to" in resp){
                    nextId = this.spec.indexOf(resp.to);
                    break;
                }
            }catch(ex){
                console.error("OnEnter Failed for",item,currentActivity,payload)
            }
        }
        this.partialStateUpdate(startId,{
            status:StepStatus.Completed
        })
        //If there is a nextId
        if(nextId && nextStep){
            this.updateStepState(nextId!,new StepState())
            this.updateStep( nextStep)
        }
    }


    /* ========================================================== */

    beforeMount(){
        if(this.startAt){
            this.step = this.startAt
        }
        let first = this.spec.at(this.step)!;
        if(first){
            this.updateStepState(first.id,  new StepState())
        }
    }
    /**
     * Mounted We initialize the first state
     */
    mounted(){
        this.$emit("ready",this)
    }


    /**
     * Updated when data changes
     */
    updated(){
    }



    renderStep(h:CreateElement,item:FlowActivity):VNode {
        let {spec} = this;
        let body:(VNode|string)[] = []

        let slotParams = {
          spec,
          id:item.id,
          activity:item,
          flow:this,
          payload:this.input
        }
        for(let slotId of  [`activity.${item.id}.label`,"activity.label"]){
            if(slotId in this.$scopedSlots){
              let res= this.$scopedSlots[slotId]!(slotParams)
              if(res && res.length > 0 ){
                body.push(...res)
                break;
              }
            }
        }
        if(body.length == 0){
          if(item.title){
            body.push(item.title!)
          }
          if(item.sub_title){
            body.push(h("small",{},item.sub_title))
          }
        }
        let index =spec.stateIndex[item.id]
        let props:any ={step:index ,editable:false}
        if(this.hasErrors) props.rules= [() =>  !this.hasErrors!(item,this)]

        if(this.isComplete) {
            props.complete = this.isComplete(item,this)
            props.color = "success"
        }
        if(this.isEditable) {
            props.editable=this.isEditable(item,this)
            /*props.editIcon ="edit"
            props.complete = props.editable;*/
        }

        return h("v-stepper-step",{
            props,
            key:"step-" + item.id,
        },body)
    }

    renderHeader(h:CreateElement):VNode {
        let {spec} = this;
        let children:VNode[] = []
        for(let x of spec.activities()) {
            if(children.length > 0){
                children.push(h("v-divider"))
            }
            children.push(this.renderStep(h,x))
        }
        return h("v-stepper-header",{ props:{}},children)
    }

    getActivityEvents(act:FlowActivity):IFlowActivityEvents<any>{
        let handler =($event:any) =>{
            //Payload changed
            this.$emit('update',$event);
        }
        let events:IFlowActivityEvents<any> ={
            get activity():FlowActivity {
                return  act
            },
            input:handler,change:handler
        }

        return events;
    }
    /**
     * Render the activity panel and footer
     * 1. Will look for a slot named "activity.{id}" and "activity.{id}.actions"
     * 2. Will look for a component named {id}
     *
     */
    renderActivity(h:CreateElement,step:FlowActivity):VNode[]|undefined {
        let {id,target,props,dynamicProps,on,dynamicOn} = step;
        let tgt = step.payload_target || "payload"
        let stepState = this.stepStates[step.id]
        let index =this.spec.stateIndex[id]


        let  MISSING =h("div",{},"Nothing Here!")
        if(!stepState){
            return MISSING
        }
        let slotId = "activity." + target
        let actionSlotId = `activity.${target}.actions`
        // IF there is a scoped slot with that ide
        let events= this.getActivityEvents(step)

        //Make sure to set default props
        props = Object.assign({},props)
        //We do Assing here because the creator controlled both things
        //and so can do merging
        if(dynamicProps)Object.assign(props,dynamicProps(events,this.input,this))
        let self = this;
        let output:undefined | VNode[] = MISSING
        let slotParams ={
            ...props,
            get stepProps(){
              return props
            },
            get stepOn(){
              let handlers = {}
              if(on) Object.assign(handlers,on)
              if(dynamicOn) Object.assign(handlers,dynamicOn(events,self.input,self))
              return handlers
            },
            step:index,
            activity:step,
            [tgt]:this.input,
            on:events,
            flow:self
        }
        if(slotId in this.$scopedSlots){
            output = this.$scopedSlots[slotId]!(slotParams)
        }else {
            let component = this.$options?.components?.[id]
            component = component ??  step.component
            if(component){
                output = [h(component,{
                    key:'body-' + step.id,
                    props:{
                        ...props,
                        flow: this,
                        [tgt]:this.input
                    } ,on:events
                } ,[])]
            }
        }
        if(actionSlotId in this.$scopedSlots){
            if(!output)  output = []
            let actions =this.$scopedSlots[actionSlotId]!(slotParams)
            if(actions) output.push(...actions)
        }
        return output
    }

    /***
     * Render Panels
     */
    renderPanels(h:CreateElement):VNode[] {
        let nodes:VNode[] = []
        let {step,spec} = this
        //each act
        for (let st of spec.activities()){
            let act =  this.renderActivity(h,st)

            nodes.push(
                h("v-stepper-content",{
                    props:{step:spec.indexOf(st.id)}
                },  act))
        }
        return nodes
    }

    renderVertical(h:CreateElement):VNode[] {
        let nodes:VNode[] = []
        let {step,spec} = this
        //each act
        for (let st of spec.activities()){
            nodes.push( this.renderStep(h,st))
            let empty:VNode[] =h("div",{})
            let activity = (st.id == this.currentActivityId)? this.renderActivity(h,st):empty
            if(activity != empty){
                if(activity && activity.length > 0){
                    activity= [
                        h("div",{
                            directives:[{
                                name:"scroll-into-view",
                                value:{
                                    behavior:"smooth",
                                    block:"center"
                                }
                            }]
                        },activity)]
                }else  activity= []
            }
            const stepKey = `step--${st.id || st.target}`
            nodes.push(h("v-stepper-content",{
                key:stepKey,
                props:{step:spec.indexOf(st.id)}
            },activity))
        }
        return nodes

    }

    render(h:CreateElement):VNode {
        let {spec} = this
        let children: any[] = []
        if(this.vertical){
            children = this.renderVertical(h)
        }else{
            children =[this.renderHeader(h),...this.renderPanels(h)]
        }
        return h("v-stepper",{
            key:"key" + this.stepperVintage,
            props:{
                value:this.step,
                vertical:this.vertical,
            },
            on:{
                change:($event:number) => {
                    this.updateStep($event)
                }
            }

        },
            children)

    }

}
