/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ParentFragment = {
    properties: {
        name: {
            type: 'string',
            isRequired: true,
        },
        uuid: {
            type: 'string',
            isRequired: true,
            format: 'uuid',
        },
        is_folder: {
            type: 'boolean',
            isRequired: true,
        },
    },
} as const;
