/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ImportMappingIdea = {
    properties: {
        spec: {
            type: 'ImportSpec',
            isRequired: true,
        },
        filterText: {
            type: 'string',
        },
        fingerprintColumns: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        explicitHeaders: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        meta: {
            properties: {
            },
        },
        score: {
            type: 'number',
        },
        identifier: {
            type: 'string',
        },
    },
} as const;
