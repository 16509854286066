/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiProfile = {
    description: `User Profile `,
    properties: {
        uuid: {
            type: 'string',
            isRequired: true,
            format: 'uuid',
        },
        updated_time: {
            type: 'string',
            isRequired: true,
            format: 'date-time',
        },
        primary_entity: {
            type: 'ApiEntity',
            isRequired: true,
        },
        default_currency: {
            type: 'InstrumentRef',
            isRequired: true,
        },
        default_timezone: {
            type: 'string',
            isRequired: true,
        },
        inbox_address: {
            type: 'string',
        },
        metadata: {
            type: 'ApiProfileMetaData',
        },
        enabled_roles: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        two_fa_enabled: {
            type: 'string',
            format: 'date-time',
        },
        last_transact_update: {
            type: 'string',
            format: 'date-time',
        },
    },
} as const;
