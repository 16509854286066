/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AuthRefresh = {
    properties: {
        access_token: {
            type: 'string',
        },
        root: {
            type: 'boolean',
        },
    },
} as const;
