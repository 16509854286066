/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ReportGenerationStatus = {
    properties: {
        report_key: {
            type: 'any-of',
            contains: [{
                type: 'string',
            }, {
                type: 'string',
                format: 'uuid',
            }],
            isRequired: true,
        },
        status: {
            type: 'boolean',
            isRequired: true,
        },
        download_url: {
            type: 'string',
        },
    },
} as const;
