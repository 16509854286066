/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $OrClause = {
    properties: {
        _or: {
            type: 'array',
            contains: {
                type: 'any-of',
                contains: [{
                    type: 'ExactDate',
                }, {
                    type: 'DateRange',
                }, {
                    type: 'AndClause',
                }, {
                    type: 'OrClause',
                }, {
                    type: 'ByType',
                }],
            },
            isRequired: true,
        },
    },
} as const;
