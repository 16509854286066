/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $CustomEtlInstance = {
    properties: {
        run: {
            type: 'string',
            pattern: '[0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26}',
        },
        states: {
            type: 'dictionary',
            contains: {
                type: 'EtlStageState',
            },
        },
        config_updated: {
            type: 'string',
            format: 'date-time',
        },
    },
} as const;
