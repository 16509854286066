import Vue,{DirectiveBinding}  from "vue"

interface  IStickyProps{
    class:string
}
/**
 * Given an element produce all its parents
 */
function* withParents(el:HTMLElement){
    let parent = el.parentElement;
    while(parent && parent != document.body){
        yield parent;
        parent = parent.parentElement;
    }
}

/**
 * Directive that will attacha  particular class to all th eparents of the target element 
 * Inspired by 
 *https://www.hamrodev.com/en/tutorials/css-sticky-and-wrapper-containers
 */
Vue.directive("sticky-parent",{
    inserted(el:HTMLElement,binding:DirectiveBinding<IStickyProps>) {
        for(let x  of withParents(el)){
            x.classList.add(binding.value.class)
        }
    },
    bind(el:HTMLElement,binding:DirectiveBinding<IStickyProps>){
        let props = binding.value
    },
    unbind(el:HTMLElement,binding:DirectiveBinding<IStickyProps>){
        for(let x  of withParents(el)){
            x.classList.remove(binding.value.class)
        }
    }
})
