/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiWorkflowInputs = {
    properties: {
        inputs: {
            type: 'array',
            contains: {
                type: 'ApiWorkflowInputStage',
            },
            isRequired: true,
        },
    },
} as const;
