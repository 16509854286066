import type {Context} from "@nuxt/types"
import  Vue from "vue"
import  GtagModule from "vue-gtag"
import type  {VueGtag} from "vue-gtag"

interface HasGtagInstalled {
    __gtag__?:boolean
}

declare module 'vuex/types' {
    interface Store<S>{
        $gtag:Vue['$gtag'];
    }
}


export default (cxt:Context,inject:any) => {
    ///Nothing to do here
    //
    let {app,env} = cxt
    let tgt:HasGtagInstalled =  Vue as HasGtagInstalled
    if(!tgt.__gtag__){
        Vue.use(GtagModule,{
          config: {
            id: env.GA_STREAM_ID  ,
            user_id:''
          }
        },app.router)
        tgt.__gtag__ = true
        inject("gtag",Vue.prototype.$gtag)
    }

}
