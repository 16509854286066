/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccessGrantRequest } from '../models/AccessGrantRequest';
import type { ApiUserAccessGrant } from '../models/ApiUserAccessGrant';
import type { BatchImportResponse_ThirdPartySignup_ } from '../models/BatchImportResponse_ThirdPartySignup_';
import type { BatchRequest_SignupRequest_ } from '../models/BatchRequest_SignupRequest_';
import type { SignupRequest } from '../models/SignupRequest';
import type { ThirdPartySignup } from '../models/ThirdPartySignup';
import type { UniverseInfo } from '../models/UniverseInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AccessService {

    /**
     * Signup User
     * Signup for an account on behalf of someone
     * @param requestBody
     * @returns ThirdPartySignup Successful Response
     * @throws ApiError
     */
    public static signupUser(
        requestBody: SignupRequest,
    ): CancelablePromise<ThirdPartySignup> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/access/signup_user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Batch Signup
     * @param requestBody
     * @returns BatchImportResponse_ThirdPartySignup_ Successful Response
     * @throws ApiError
     */
    public static batchSignup(
        requestBody: BatchRequest_SignupRequest_,
    ): CancelablePromise<BatchImportResponse_ThirdPartySignup_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/access/bulk/signup_user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Grant Access
     * @param requestBody
     * @param host
     * @returns ApiUserAccessGrant Successful Response
     * @throws ApiError
     */
    public static grantAccess(
        requestBody: AccessGrantRequest,
        host?: string,
    ): CancelablePromise<ApiUserAccessGrant> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/access/invite',
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Revoke Access
     * @param grantee
     * @param host
     * @returns void
     * @throws ApiError
     */
    public static revokeAccess(
        grantee: string,
        host?: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/access/revoke',
            headers: {
                'host': host,
            },
            query: {
                'grantee': grantee,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Lookup Universe
     * Lookup the details of a User and Permiossions
     * @param universe
     * @param host
     * @returns UniverseInfo Successful Response
     * @throws ApiError
     */
    public static lookupUniverse(
        universe: string,
        host?: string,
    ): CancelablePromise<UniverseInfo> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/access/switch/',
            headers: {
                'host': host,
            },
            query: {
                'universe': universe,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
