import {Context} from "@nuxt/types"
import {transformSchema} from "~/utils/schema_funcs"
type Axios = Context["$axios"]
type Schema = any 
import {Component,Vue} from "nuxt-property-decorator"
import {ApiInstrument} from "~/schemas/gen"


@Component
class SchemaDataCache extends Vue {
    assetTypes_:any[]|null = null
    currencies_:any[]|null = null
    identifierTypes_:any[]|null = null
    instrumentTypes_:any[]|null = null
    
    get assetTypes():any[]|null {
        getAssetTypes(window.$nuxt.$axios)
        return this.assetTypes_;
    }
    get identifierTypes():any[]|null {
        getIdentifierTypes(window.$nuxt.$axios);
        return this.identifierTypes_
    }
    get instrumentTypes():any[]|null {
        getInstrumentTypes(window.$nuxt.$axios);
        return this.instrumentTypes_
    }
}
export const dataCache = new SchemaDataCache()


export async function getAssetTypes($axios:Axios):Promise<any[]>{
    if(dataCache.assetTypes_ == null){
        dataCache.assetTypes_ = []
        dataCache.assetTypes_.push(...await $axios.$get("/data-types/assets/types") as any[])
    }
    return dataCache.assetTypes_
}


export async function getIdentifierTypes($axios:Axios):Promise<any[]>{
    if(dataCache.identifierTypes_ == null){
        var x = dataCache.identifierTypes_ = [] as any[]
        x.push(... (await $axios.$get("/data-types/identifiers/types") as any[]))
    }
    return dataCache.identifierTypes_
}

export async function getInstrumentTypes($axios:Axios):Promise<any[]>{
    if(dataCache.instrumentTypes_ == null){
        var x = dataCache.instrumentTypes_ = [] as any[];
        x.push(...await $axios.$get("/data-types/instruments/types"))
    }
    return dataCache.instrumentTypes_
}



export async function getCurrencies($axios:Axios):Promise<ApiInstrument[]>{
    if(dataCache.currencies_ == null) {
        var x =  dataCache.currencies_ = [] as any[];
        x.push(... await $axios.$get("/data-types/currencies")  as any[])
    }
    return dataCache.currencies_
}

const CHANGE=Symbol("schema.enrichment.change");

/**
 * Expanded Json Schema
 * THis will contain all the logic for the 
 * alterantions to the application level jsonscchema 
. */
export class ExpandedSchema{
    src!:Schema
    result!:Schema
    $axios:Axios
    $promise?:Promise<Schema>
        constructor(schema:Schema,$axios:Axios){
            this.src =schema
            this.$axios = $axios;
        }
    get value():Promise<Schema>{

        let updates:any[] = []

        if(!this.$promise){
            let promises:Promise<any>[] = []
            this.result = transformSchema(this.src,(path,schema) => {
                let xOpts = schema['x-options']
                let change:Promise<any>|null = null;
                let shape= {}
                if(!xOpts) return schema

                if(xOpts.isCurrency){
                    shape = {oneOf:[]}
                    change = getCurrencies(this.$axios).then(x => {
                        schema.oneOf.splice(0,schema.oneOf.length,
                            ...x.map((x:any) => ({"const":x.id,title:x.id})))
                        console.log("Currencies Update ->",schema,this.result)
                    })
                }else if (xOpts.isAssetType){
                    shape = {oneOf:[]}
                    change = getAssetTypes(this.$axios).then(x => {
                        schema.oneOf.splice(0,schema.oneOf.length,
                            ...x.map((x:any) => ({"const":x.id,title:x.label})))
                    })
                }
                if(change != null){
                    schema = {...schema,...shape}
                    promises.push(change.catch(err => {
                        console.error("Failed to resolve field ",path,err)
                    }))
                    return transformSchema.Just(schema)
                }
                return schema
            })

            this.$promise  = Promise
                .allSettled(promises)
                .then(() =>{
                    //console.log("Entries => ",this.result)
                    return this.result
                })
        }
        return this.$promise;
    }
} 

/*
export async function expandSchema(schema:any,$axios:Context["$axios"]):Promise<any> {

    if(FinalSchema) return FinalSchema
    let ccys = $axios.$get("/data-types/currencies")
    let props = Schema.properties

    FinalSchema={
        ...Schema,
        properties:{
            ...props,
                asset_type: {
                    ...props.asset_type,
                        oneOf: (await asset_types).map((x:any) => ({"const":x.id,title:x.label}))
                },
                price_currency:{
                    ...props.price_currency,
                        oneOf: (await ccys).map((x:any) => ({"const":x.id,title:x.label}))
                }
        }
    }
    return FinalSchema
}*/
