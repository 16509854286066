/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ReportType = {
    description: `Report Type`,
    properties: {
        name: {
            type: 'string',
            isRequired: true,
        },
        report_id: {
            type: 'string',
            isRequired: true,
        },
        headers: {
            type: 'array',
            contains: {
                type: 'string',
            },
            isRequired: true,
        },
        columns: {
            type: 'array',
            contains: {
                type: 'string',
            },
            isRequired: true,
        },
        report_type: {
            type: 'string',
        },
        note: {
            type: 'ReportNoteMeta',
        },
        related: {
            type: 'array',
            contains: {
                type: 'ReportType',
            },
        },
    },
} as const;
