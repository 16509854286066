import {Route} from "vue-router"
import {Instruments_Bool_Exp} from "~/models/graphql";
import { DETAILS_ANNOTATION} from "~/schemas/transaction_schemas"

//Route to the instrument page.
export function routeForInstrumentView(id:string):any{
    return  {name:"instruments-id",params:{id}};
}

export function routeForInstrumentEdit(id:string):any{
    return  {name:"instruments-id-edit",params:{id}};
}


export interface InstrumentSearchOpts {
    systemUserId?:number
    includeSystem?:boolean|"with-search"
}

export function instrumentSearchExp(search:string,opts?:InstrumentSearchOpts):Instruments_Bool_Exp{
    let clauses:any[] = []
    search = search.trim();
    if(search != ""){
        clauses.push({ _or: [
            { display_name:{ _ilike: "%" +  search + "%" } },
            { identifier: { _ilike: "%" + search + "%" } }
        ]})
    }
    if(opts?.systemUserId) {
        let sysWithSearch=opts?.includeSystem == "with-search" 
        if(opts?.includeSystem===true){
        }else if(sysWithSearch && search == ""){  
            clauses.push({ 
                user_id:{ _neq:opts.systemUserId}
            })
        }
    }
    return { 
        _and:[...clauses]
    }
}

export const INSTRUMENT_SCHEMA:Record<string,any> = {
    instrument_type:{
        type:"select",label:"Instrument Type",
        hint:"Instrument/Asset Type",
        [DETAILS_ANNOTATION]:true,
        col:{md:3,xs:6}
    },
    identifier_type:{
        type:"select",label:"Identifier Type",
            hint:"Type of Identifier",
            col:{md:3,xs:6}
    },
    identifier:{
        type:"text",label:"Identifier",
            hint:"Official Identifier for Instrument",
            col:{md:6,xs:6}
    },
    display_name:{
        type:"text",label:"Symbol/Name",
        hint:"User friendly Name for Instrument",
        [DETAILS_ANNOTATION]:{
            itemClass:"important redact-instrument-name"
        },
        col:{md:6,xs:6}
    },
    "start_date":{
        type:"text",label:"Start Date",
        ext:"date",
        [DETAILS_ANNOTATION]:true
    },
    "end_date":{
        type:"text",label:"End Date",
        ext:"date",
        [DETAILS_ANNOTATION]:true
    },
    issuing:{
        type:"wrap",
            title:"Issuing Details",
            class:"title pa-0",
        schema:{
            issuer_id:{
                type:"InputEntity",label:"Issuing Entity",
                hint:"What entity issued this Instrument",
                col:{md:3,xs:6}
            },
            issue_date:{
                type:"text",label:"Issue Date",
                ext:"date",
                hint:'When was this instrument issued?',
                col:{md:3,xs:6},
                [DETAILS_ANNOTATION]:{
                    itemClass:"important"
                }
            }
        }
    },
    settlement_unit_id:{
        type:"InputInstrument",
        label:"Settlement Instrument",
        [DETAILS_ANNOTATION]:true,
    },
    par_amount:{ 
        type:"number",label:"Par Amount ",
        col:{ md:2,xs:3},
        [DETAILS_ANNOTATION]:true
    },
    price_multiplier:{
        type:"number",
        label:"Price Multiplier",
        col: {md:2, xs: 3 },
        [DETAILS_ANNOTATION]:true
    }
}
