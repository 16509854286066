/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum UserSummarySection {
    CORE = 'core',
    ESTI = 'esti',
    CLOSE = 'close',
    UNEXPLAINED = 'unexpl',
}
