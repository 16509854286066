/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $EventSummaryResponse = {
    properties: {
        num_events: {
            type: 'number',
            isRequired: true,
        },
        num_documents: {
            type: 'number',
            isRequired: true,
        },
        linked_events: {
            type: 'array',
            contains: {
                type: 'string',
                format: 'uuid',
            },
            isRequired: true,
        },
        linked_documents: {
            type: 'array',
            contains: {
                type: 'string',
                format: 'uuid',
            },
            isRequired: true,
        },
    },
} as const;
