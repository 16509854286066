/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AddTransactionRequest = {
    description: `Add Transaction Request`,
    properties: {
        name: {
            type: 'string',
        },
        notes: {
            type: 'string',
        },
        asset_type: {
            type: 'InstrumentType',
            isRequired: true,
        },
        what: {
            type: 'InstrumentRef',
            isRequired: true,
        },
        direction: {
            type: 'Direction',
            isRequired: true,
        },
        target_account: {
            type: 'AccountRef',
        },
        counterparty_account: {
            type: 'AccountRef',
        },
        units: {
            type: 'number',
            isRequired: true,
        },
        price: {
            type: 'number',
        },
        price_unit: {
            type: 'InstrumentRef',
        },
        fair_market_value: {
            type: 'number',
            isRequired: true,
        },
        fair_market_value_unit: {
            type: 'InstrumentRef',
            isRequired: true,
        },
        amendment_time: {
            type: 'string',
            format: 'date-time',
        },
        last_update_token: {
            type: 'string',
        },
        when: {
            type: 'one-of',
            contains: [{
                type: 'string',
                format: 'date-time',
            }, {
                type: 'string',
                format: 'date',
            }],
        },
        settlement: {
            type: 'SettlementDetails',
        },
        advisor_note: {
            type: 'string',
        },
    },
} as const;
