/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum AccountDisplayStyle {
    FULL = 'F',
    NUMBER_ONLY = 'N',
}
