/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $NetWorthLine = {
    description: `Network Line item`,
    properties: {
        assets: {
            type: 'number',
        },
        liabilities: {
            type: 'number',
        },
        position_count: {
            type: 'number',
        },
        total_paid_capital: {
            type: 'number',
        },
        total_position_value: {
            type: 'number',
        },
    },
} as const;
