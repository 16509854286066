
import {Component,Vue,Prop,Watch } from "nuxt-property-decorator"
import {nestedSetter} from "~/utils/schema_funcs"
import isPlainObject from "lodash/isPlainObject"
import merge  from "lodash/merge"
import { isValidNameError } from "graphql"
import {schema2Groups,SchemaGroup,SchemaItem,ISchemaHooks } from "~/core/schemas/grouping"


type SchemaGroupWithKey =  SchemaGroup & {key:string}
export interface InputCompleteEvent<T>{
    $event:T
    $raw:any
    group:SchemaGroup
    key: keyof T
}

interface IDefaultProps {
    title?:Record<string,any>;
    self?:Record<string,any>;
    body?:Record<string,any>;
}
const DEF_GROUP_PROPS:Record<string,IDefaultProps> = {
    "panel": {
        self: {elevation:0},
        title:{ class: ["px-0","py-2"] },
        body: { class: ["px-0"] }
    }
}






@Component({
           model:{
               prop:"value",
               event:"input"
           }
})
export default class SchemaForm extends Vue {
    @Prop(Object) readonly value!:any
    @Prop(Object) readonly schema!:any
    @Prop(Object) readonly row!:any
    @Prop({type:Object,default(){
        return {}
    }}) readonly hooks!:ISchemaHooks
    groupSelections:Record<string,number> = {}
    //Return if group is collapsed
    groupCollapsed:Record<string,boolean> = {}
    get schemaGroups(){
        let self = this
        let groups = schema2Groups(this.schema, this.hooks)
        return groups
            .map(x => ({
                       ...x,
                       get key(){
                           return self.getGroupKey(this as SchemaGroup)
                       }
            })).map(x => merge(x,this.hooks?.groupPropsFor?.(x)))
        
    }

    mounted(){
        this.isGroupCollapsed.bind(this)
    }

    

    pickValue(group:SchemaGroup):any{
        let {hooks } = this;
        let value = group.path.reduce((obj,path) => {
            return obj?.[path]
        },this.value)
        return value
    }
    //Actually update  a deeply nested value 
    updateValue(group:SchemaGroup,$event:any){
        //Start with a copy of the whole value
        let {key,data:value} = $event;
        var fullUpdate =  {...(this.value|| {})} 
        //Find the nested Node this belongs to
        let keyParts = key.split(".");
        let path = group.path
        if(keyParts.length  >1){
            key =keyParts.pop();
            path = path.concat(keyParts)
        }
        var target = nestedSetter(fullUpdate,path); 
        target[key] = $event.value;
        /*
        // merge the items into the leaf element
        Object.entries(value).forEach(([name,value]) => {
            let dotPos = name.indexOf(".")
            if(dotPos == -1)  target[name] = value
            let parts=name.split(".")
            let nestedTarget = nestedSetter(target,parts.slice(0,-1))
            nestedTarget[parts[parts.length-1]]= value
            return false;
        })*/
        key = path.concat(key).join(".")
        this.$emit("input-complete",{$event:fullUpdate,$raw:$event,group:group,key})
        this.$emit("input",fullUpdate)
    }
    // Group methods ===========================
    getGroupItems(group:SchemaGroup):string[] {
        return Object.entries(group.items).map(([name,value]) => name)
    }
    getGroupItemKey(item:string,group:SchemaGroup):string{
        return  item
    }
    getGroupItemTitle(item:string,group:SchemaGroup):string{
        let elt =group.items[item]
        return elt.title || elt.label || " ??? "
    }
    getGroupSchema(item:string,group:SchemaGroup):Record<string,any>{
        let data = group.items[item].schema
        return data
    }
    getGroupSel(group:SchemaGroup):number{
        let {path} = group
        let key =path.join(".");
        let current = this.groupSelections[key]
        if(current) return current
        return 0
    }
    updateGroupSel(group:SchemaGroup,sel:number){
        let key=group.path.join(".")
        this.groupSelections = { 
            ...this.groupSelections,
            [key]:sel
        }
    }

    getGroupBind(group:SchemaGroup):Record<string,any> {
        return {
            row:this.row,
            model:this.pickValue(group),
            schema:group.items,
            col:12
        }
    }
    getGroupKey(group:SchemaGroup):string{
        return (group.schemaPath ?? group.path).join(".")
    }
    getGroupProps(group:SchemaGroup,section?:string):Record<string,any> {
        let defKey = section ?? "self";
        let userKey = (typeof section == "undefined")?"props":(section + "Props");
        let props =merge({},(DEF_GROUP_PROPS as any)?.[group.type]?.[defKey],(group as any)[userKey])
        //console.log("Props ",group.type,section,props)
        return props
    }
    toggleGroupCollapsed(group:SchemaGroupWithKey){
        this.groupCollapsed = {...this.groupCollapsed,
            [group.key]:!this.isGroupCollapsed(group)
        }
    }
    isGroupCollapsed(group:SchemaGroupWithKey):boolean {
        let key = group.key
        let {props } = group;
        let gpData = group as any
        let collapsed = this.groupCollapsed[key] ?? gpData?.defaultCollapsed ?? group?.collapsible;
        return !!collapsed
    }
   
}
