/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $InstrumentUpdates = {
    properties: {
        rename: {
            type: 'InstrumentRename',
        },
    },
} as const;
