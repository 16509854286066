/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Data Table Column Formatting
 */
export type TableStyle = {
    type: TableStyle.type;
    props: any;
};

export namespace TableStyle {

    export enum type {
        DATA_TABLE = 'data-table',
    }


}

