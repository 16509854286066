/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $EquityDetails = {
    properties: {
        detail_type: {
            type: 'Enum',
        },
        dividend_amount: {
            type: 'number',
        },
        dividend_unit_id: {
            type: 'string',
            format: 'uuid',
        },
        periodicity: {
            type: 'Periodicity',
        },
        preference_price: {
            type: 'number',
        },
        preference_multiple: {
            type: 'number',
        },
        participation_cap_multiple: {
            type: 'number',
        },
        preference_info: {
            properties: {
            },
        },
        conversion_ratio: {
            type: 'number',
        },
        conversion_cost_per_share: {
            type: 'number',
        },
        conversion_info: {
            properties: {
            },
        },
    },
} as const;
