/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ShortExpenseDeclaration = {
    description: `This Object represents the expense declaration for a CapitalCommitment
    This will expand into some future object that captures the nuances of
    expences for an investment.`,
    properties: {
        cap_type: {
            type: 'FixedOrPercentage',
        },
        cap: {
            type: 'number',
        },
        cap_target: {
            type: 'FundNotionalTarget',
        },
        payable_unit_id: {
            type: 'string',
            format: 'uuid',
        },
    },
} as const;
