/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $WaitlistRequest = {
    description: `Waitlist joining message`,
    properties: {
        email: {
            type: 'string',
            isRequired: true,
            format: 'email',
        },
        first_name: {
            type: 'string',
            isRequired: true,
            maxLength: 150,
            minLength: 1,
        },
        comment: {
            type: 'string',
            maxLength: 150,
        },
        company: {
            type: 'string',
        },
        token: {
            type: 'string',
        },
        waitlist: {
            type: 'boolean',
        },
    },
} as const;
