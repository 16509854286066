/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StaticEnrollResponse } from '../models/StaticEnrollResponse';
import type { TotpDeviceModel } from '../models/TotpDeviceModel';
import type { TotpEnrollRequest } from '../models/TotpEnrollRequest';
import type { TwoFactorDevice } from '../models/TwoFactorDevice';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TwofactorService {

    /**
     * Get 2Fa Devices
     * @param host
     * @returns TwoFactorDevice Successful Response
     * @throws ApiError
     */
    public static get2FaDevices(
        host?: string,
    ): CancelablePromise<Array<TwoFactorDevice>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/auth/2fa/devices',
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Totp Enroll Start
     * @param host
     * @returns TotpDeviceModel Successful Response
     * @throws ApiError
     */
    public static totpEnrollStart(
        host?: string,
    ): CancelablePromise<TotpDeviceModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/2fa/totp/start',
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Static Enroll Complete
     * Confuirm and activate a static TOTP Device
     * and Attach it to a user account
     * @param host
     * @returns StaticEnrollResponse Successful Response
     * @throws ApiError
     */
    public static staticEnrollComplete(
        host?: string,
    ): CancelablePromise<StaticEnrollResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/2fa/static/enroll',
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Totp Enroll Complete
     * Given a set of totp codes and device key
     * Confirm thta the codes match the device we are holding on to
     * and if so confirm the device and enable it
     * @param requestBody
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static totpEnrollComplete(
        requestBody: TotpEnrollRequest,
        host?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/2fa/totp/enroll',
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Device
     * Disable a 2fa device from the users account
     * Additionally if there are only recovery codes left we will disable them
     * @param deviceId
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static removeDevice(
        deviceId: string,
        host?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/auth/2fa/remove',
            headers: {
                'host': host,
            },
            query: {
                'device_id': deviceId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
