/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $BatchImportResponse_ThirdPartySignup_ = {
    properties: {
        results: {
            type: 'array',
            contains: {
                type: 'any-of',
                contains: [{
                    type: 'array',
                    contains: {
                        type: 'ErrorEntry',
                    },
                }, {
                    type: 'ThirdPartySignup',
                }],
            },
            isRequired: true,
        },
        errors: {
            type: 'number',
            isRequired: true,
        },
    },
} as const;
