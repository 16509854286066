/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum EventType {
    NEW_TRANSACTION = 'NEW',
    CAPITAL_CALL = 'LPCC',
    CAPITAL_DISTRIBUTION = 'LPCD',
    CAPITAL_ACTIVITY = 'CACT',
    NOTE = 'NOTE',
    ACCOUNT_UPDATE = 'UPDA',
    ENTITY_UPDATE = 'UPDE',
}
