/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SignupDetailRequest = {
    description: `Signup Details Reqeust`,
    properties: {
        last_name: {
            type: 'string',
        },
        country: {
            type: 'string',
        },
        company: {
            type: 'string',
        },
        zip_code: {
            type: 'string',
        },
        state: {
            type: 'string',
        },
        city: {
            type: 'string',
        },
        street_line1: {
            type: 'string',
        },
        street_line2: {
            type: 'string',
        },
        default_ccy: {
            type: 'InstrumentRef',
        },
    },
} as const;
