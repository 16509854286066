import type {Context} from "@nuxt/types"
import isPlainObject from "lodash/isPlainObject"


type notificationFunction = (msg:string,title?:string,opts?:Record<string,any>) => void;
interface INotificationCmd {
    id:string
    status:"error"|"info"|"success"|"warn"
    message:string
    [x:string]:string
} 

type messageFunction = (cmd:INotificationCmd) => void ;

export interface INotifier  {
    message:messageFunction
    success:notificationFunction
    error:notificationFunction
    warn:notificationFunction
    info:notificationFunction
}

declare module 'vue/types/vue' {
    interface Vue {
        $notifier:INotifier
    }
}
declare module 'vuex/types' {
    interface Store<S> {
        $notifier:INotifier
    }
}

declare module '@nuxt/types' {
    interface Context {
        $notifier:INotifier
    }
}

let notifier!:INotifier


export function useNotifier():INotifier{
    return notifier
}

export default ({app,store}:Context,inject:any) => {

    function submit(color:string,body:any,title:any,opts:any){
        if(isPlainObject(title)) opts = title;
        store.commit('snackbar/message',{body,title:'',props:{color,...(opts||{})}})
    }


    notifier  = {
        message(cmd:INotificationCmd){
            submit(cmd.status,cmd.message,cmd.title || "",cmd)
        },
        success(msg:string,title?:string,opts?:any){
            submit('success',msg,title,opts);
        },
        error(msg:string,title?:string,opts?:any) {
            submit('error',msg,title,opts);
        },
        warn(msg:string,title?:string,opts?:any) {
            submit('warning',msg,title,opts);
        },
        info(msg:string,title?:string,opts?:any) {
            submit('info',msg,title,opts);
        }
    };


    inject("notifier",notifier);
}
