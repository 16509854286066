/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiInstrument } from '../models/ApiInstrument';
import type { GetInstrumentArgs } from '../models/GetInstrumentArgs';
import type { InputApiInstrument } from '../models/InputApiInstrument';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InstrumentService {

    /**
     * Get Instrument
     * @param id
     * @param host
     * @returns ApiInstrument Successful Response
     * @throws ApiError
     */
    public static getInstrument(
        id: string,
        host?: string,
    ): CancelablePromise<ApiInstrument> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/instruments/{id}',
            path: {
                'id': id,
            },
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Instrument Full
     * @param id
     * @param requestBody
     * @param host
     * @returns ApiInstrument Successful Response
     * @throws ApiError
     */
    public static getInstrumentFull(
        id: string,
        requestBody: GetInstrumentArgs,
        host?: string,
    ): CancelablePromise<ApiInstrument> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/instruments/{id}/full',
            path: {
                'id': id,
            },
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Instrument
     * @param requestBody
     * @param commit
     * @param host
     * @returns ApiInstrument Successful Response
     * @throws ApiError
     */
    public static addInstrument(
        requestBody: InputApiInstrument,
        commit: boolean = false,
        host?: string,
    ): CancelablePromise<ApiInstrument> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/instruments',
            headers: {
                'host': host,
            },
            query: {
                'commit': commit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Instrument
     * @param uuid
     * @param requestBody
     * @param commit
     * @param host
     * @returns ApiInstrument Successful Response
     * @throws ApiError
     */
    public static updateInstrument(
        uuid: string,
        requestBody: InputApiInstrument,
        commit: boolean = false,
        host?: string,
    ): CancelablePromise<ApiInstrument> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/instruments/{uuid}',
            path: {
                'uuid': uuid,
            },
            headers: {
                'host': host,
            },
            query: {
                'commit': commit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
