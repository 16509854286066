import Vue from 'vue'
import VFormBase from 'vuetify-form-base'
import InputInstrument from "~/components/input/Instrument.vue"
import InputEntity from "~/components/input/Entity.vue"
import InputAccount from "~/components/input/Account.vue"
import InputDocument from "~/components/input/Document.vue"
import InputEvent from "~/components/input/Event.vue"
import InputDate from "~/components/input/Date.vue"
import InputAdvisorClient from "~/components/input/AdvisorClient.vue"
import {VTextarea} from "vuetify/lib"

const components = { InputInstrument,VFormBase,InputEntity,InputAccount,
    InputDocument,InputEvent,InputDate,InputAdvisorClient,
    "v-textarea":VTextarea}

Object.entries(components).forEach(([name,component]) => {
    let comp = Vue.component(name,component)
    //window.thatComponents = Vue.options.components
    //window.thatOptions =  Vue.options
    //typeToComponent[name]= comp;
    //Vue.component("v-" + name,component)
});









