/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ImportFacet = {
    properties: {
        mapping_id: {
            type: 'any-of',
            contains: [{
                type: 'string',
                format: 'uuid',
            }, {
                type: 'string',
            }],
        },
        row: {
            type: 'number',
        },
        document_id: {
            type: 'string',
            format: 'uuid',
        },
        fields: {
            type: 'dictionary',
            contains: {
                type: 'array',
                contains: {
                    type: 'PagedBoundingBox',
                },
            },
        },
    },
} as const;
