/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $WaitlistResponse = {
    description: `Waitlist reesponse`,
    properties: {
        waitlisted: {
            type: 'boolean',
        },
        existing: {
            type: 'boolean',
        },
        approved: {
            type: 'boolean',
        },
    },
} as const;
