/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiProfileMetaData = {
    description: `Profile Metadata`,
    properties: {
        debug_dashboard: {
            type: 'boolean',
        },
        private_client: {
            type: 'boolean',
        },
        workflows: {
            type: 'dictionary',
            contains: {
                type: 'boolean',
            },
        },
        workflows_enabled: {
            type: 'boolean',
        },
        display: {
            type: 'ProfileDisplaySettings',
        },
    },
} as const;
