/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ObservableField {
    STMT_START_DATE = 'DT_START',
    STMT_END_DATE = 'DT_END',
    SYM_ALT_NAME = 'SYM_ALT_NAME',
    SYM_NAME_ONLY = 'SYM_NAME_ONLY',
    SYM_EXCHANGE = 'SYM_EXCHANGE',
    SYM_URL = 'SYM_URL',
    SYM_SOURCE = 'SYM_SOURCE',
    SYM_SUGGESTION_TYPE = '_SYM_SUG_TYPE',
    SRC_LABEL = 'SRC_LABEL',
}
