/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $StorageUploadSpec = {
    properties: {
        url: {
            type: 'string',
            isRequired: true,
        },
        fields: {
            properties: {
            },
            isRequired: true,
        },
        redirect_url: {
            type: 'string',
        },
    },
} as const;
