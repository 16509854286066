/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContactRequest } from '../models/ContactRequest';
import type { ContactResponse } from '../models/ContactResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SiteService {

    /**
     * Contact Request
     * @param requestBody
     * @returns ContactResponse Successful Response
     * @throws ApiError
     */
    public static contactRequest(
        requestBody: ContactRequest,
    ): CancelablePromise<ContactResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/site/contact',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
