/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $AccessGrantRequest = {
    description: `Request to grant {role} level access to {grantee} `,
    properties: {
        grantee: {
            type: 'string',
            isRequired: true,
            format: 'email',
        },
        first_name: {
            type: 'string',
            isRequired: true,
            minLength: 1,
        },
        last_name: {
            type: 'string',
            isRequired: true,
        },
        role: {
            type: 'AccessGrantRole',
            isRequired: true,
        },
    },
} as const;
