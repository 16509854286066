/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $HeldAwayReconciliation = {
    properties: {
        origin_document_id: {
            type: 'string',
            format: 'uuid',
        },
        origin_date: {
            type: 'string',
            format: 'date',
        },
        origin_label: {
            type: 'string',
        },
        report_id: {
            type: 'string',
        },
        direct: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        mapping: {
            type: 'dictionary',
            contains: {
                type: 'string',
                pattern: '[0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26}',
            },
        },
        pricing: {
            type: 'dictionary',
            contains: {
                type: 'SymbolPx',
            },
        },
        render_state: {
            properties: {
            },
        },
        last_report_id: {
            type: 'string',
            format: 'uuid',
        },
    },
} as const;
