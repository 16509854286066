/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum IdentifierType {
    CCY = 'CCY',
    ISIN = 'ISIN',
    CUSIP = 'CSIP',
    TICKER = 'TCKR',
    USER = 'USER',
    INTERNAL = 'INTR',
    TICKER_PLANT_ID = 'TPLT',
}
