/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SymbolMappingUpdate = {
    description: `Update to proxy mappings for a Workflow`,
    properties: {
        mapping: {
            type: 'dictionary',
            contains: {
                type: 'any-of',
                contains: [{
                    type: 'InputApiObservable',
                }, {
                    type: 'boolean',
                }, {
                    type: 'string',
                    pattern: '[0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26}',
                }],
            },
            isRequired: true,
        },
        direct: {
            type: 'array',
            contains: {
                type: 'string',
            },
        },
        facet: {
            type: 'string',
        },
        period: {
            type: 'InputApiObservable',
        },
        pricing: {
            type: 'dictionary',
            contains: {
                type: 'SymbolPx',
            },
        },
    },
} as const;
