import {setAxios} from "~/schemas/gen/core/request"
import {OpenAPI} from "~/schemas/gen/core/OpenAPI"
import {Context} from "@nuxt/types/app"

//This is set in initialization so can be trusted
let BASE_URL!:string
export function getBaseUrl():string {
    return BASE_URL
}
export default function (cxt:Context){
    const {$axios} = cxt
    //Set the Open Api Url
    setAxios($axios)
    let {baseURL} =  $axios.defaults
    let domainOnly =new URL(baseURL!,document.baseURI)
    BASE_URL = baseURL!
    domainOnly.pathname = ""
    let domainStr:string = domainOnly.toString()
    if(domainStr.endsWith("/"))domainStr = domainStr.slice(0,-1)
    OpenAPI.BASE =   domainStr
}
