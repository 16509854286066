/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $InputApiCapitalDistroEvent = {
    properties: {
        payable_unit_id: {
            type: 'string',
            isRequired: true,
            format: 'uuid',
        },
        expected_date: {
            type: 'string',
            format: 'date',
        },
        income_amount: {
            type: 'number',
        },
        interest_amount: {
            type: 'number',
        },
        ordinary_dividend_amount: {
            type: 'number',
        },
        qualified_dividend_amount: {
            type: 'number',
        },
        capital_gains_amount: {
            type: 'number',
        },
        return_of_capital_amount: {
            type: 'number',
        },
        amount: {
            type: 'number',
        },
        principal_unit_id: {
            type: 'string',
            format: 'uuid',
        },
        principal_account_id: {
            type: 'string',
            format: 'uuid',
        },
        redeemed_principal: {
            type: 'number',
        },
        lp_entity_id: {
            type: 'string',
            format: 'uuid',
        },
    },
} as const;
