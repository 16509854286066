/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $RealEstateDetails = {
    properties: {
        real_estate_tax: {
            type: 'number',
            isRequired: true,
        },
        real_estate_tax_frequency: {
            type: 'Periodicity',
            isRequired: true,
        },
        detail_type: {
            type: 'Enum',
        },
        entry_brokerage_fee: {
            type: 'number',
        },
        exit_brokerage_fee: {
            type: 'number',
        },
        other_fees: {
            type: 'number',
        },
        association_fees: {
            type: 'number',
        },
        bedrooms: {
            type: 'number',
        },
        bathrooms: {
            type: 'number',
        },
        interior_sq_ft: {
            type: 'number',
        },
        exterior_sq_ft: {
            type: 'number',
        },
        postal_code: {
            type: 'string',
        },
        settlement_unit_id: {
            type: 'string',
            format: 'uuid',
        },
    },
} as const;
