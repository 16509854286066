/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImportMappingIdea } from '../models/ImportMappingIdea';
import type { ImportSuggestionRequest } from '../models/ImportSuggestionRequest';
import type { ImportSuggestionResponse } from '../models/ImportSuggestionResponse';
import type { SessionUpdateRequest } from '../models/SessionUpdateRequest';
import type { TemplateRenderRequest } from '../models/TemplateRenderRequest';
import type { TemplateRenderResponse } from '../models/TemplateRenderResponse';
import type { UtilityResponse } from '../models/UtilityResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UtilityService {

    /**
     * Check Utility
     * @param host
     * @returns UtilityResponse Successful Response
     * @throws ApiError
     */
    public static checkUtility(
        host?: string,
    ): CancelablePromise<UtilityResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/utility/check',
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Watch Templates
     * @returns any Successful Response
     * @throws ApiError
     */
    public static watchTemplates(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/utility/templates/watch',
        });
    }

    /**
     * Render Template
     * @param requestBody
     * @returns TemplateRenderResponse Successful Response
     * @throws ApiError
     */
    public static renderTemplate(
        requestBody: TemplateRenderRequest,
    ): CancelablePromise<TemplateRenderResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/utility/templates/render',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Save Named Mapping
     * @param target
     * @param requestBody
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static saveNamedMapping(
        target: string,
        requestBody: ImportMappingIdea,
        host?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/utility/import/save',
            headers: {
                'host': host,
            },
            query: {
                'target': target,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Import Session
     * @param requestBody
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateImportSession(
        requestBody: SessionUpdateRequest,
        host?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/utility/import/session',
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Suggest Import Mapping
     * @param requestBody
     * @param host
     * @returns ImportSuggestionResponse Successful Response
     * @throws ApiError
     */
    public static suggestImportMapping(
        requestBody: ImportSuggestionRequest,
        host?: string,
    ): CancelablePromise<ImportSuggestionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/utility/import/suggestions',
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
