/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddTransactionRequest } from '../models/AddTransactionRequest';
import type { AddTransactionRequestFragment } from '../models/AddTransactionRequestFragment';
import type { ApiEvent } from '../models/ApiEvent';
import type { DeleteTransactionRequest } from '../models/DeleteTransactionRequest';
import type { PipelineSetup } from '../models/PipelineSetup';
import type { PipelineUpdate } from '../models/PipelineUpdate';
import type { UserSummaryRequest } from '../models/UserSummaryRequest';
import type { UserSummaryResponse } from '../models/UserSummaryResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TransactionService {

    /**
     * Get Transaction
     * @param id
     * @param host
     * @returns AddTransactionRequest Successful Response
     * @throws ApiError
     */
    public static getTransaction(
        id: string,
        host?: string,
    ): CancelablePromise<AddTransactionRequest> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/transactions/{id}',
            path: {
                'id': id,
            },
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Transaction
     * @param id
     * @param requestBody
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateTransaction(
        id: string,
        requestBody: AddTransactionRequest,
        host?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/transactions/{id}',
            path: {
                'id': id,
            },
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Transaction
     * @param id
     * @param requestBody
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteTransaction(
        id: string,
        requestBody: DeleteTransactionRequest,
        host?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/transactions/{id}',
            path: {
                'id': id,
            },
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Source Event
     * @param id
     * @param host
     * @returns ApiEvent Successful Response
     * @throws ApiError
     */
    public static getSourceEvent(
        id: string,
        host?: string,
    ): CancelablePromise<ApiEvent> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/transactions/{id}/source-event',
            path: {
                'id': id,
            },
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Transactions
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getTransactions(
        host?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/transactions/dashboard',
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Transaction Summary
     * @param requestBody
     * @param host
     * @returns UserSummaryResponse Successful Response
     * @throws ApiError
     */
    public static transactionSummary(
        requestBody: UserSummaryRequest,
        host?: string,
    ): CancelablePromise<UserSummaryResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/transactions/summary',
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                304: `Not Modified`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Transaction Explain
     * @param key
     * @param step
     * @param format
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static transactionExplain(
        key: string,
        step?: string,
        format?: 'csv',
        host?: string,
    ): CancelablePromise<(PipelineSetup | PipelineUpdate)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/transactions/summary/explain',
            headers: {
                'host': host,
            },
            query: {
                'key': key,
                'step': step,
                'format': format,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Transaction Fragment
     * @param id
     * @param requestBody
     * @param host
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateTransactionFragment(
        id: string,
        requestBody: AddTransactionRequestFragment,
        host?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/transactions/{id}/fragment',
            path: {
                'id': id,
            },
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
