
import GlobalSnackbar from "~/components/global/Snackbar.vue"
const HELP_SCOUT_URL = "https://distributary.helpscoutdocs.com/"
const DEFAULT_TITLE = 'DISTRIBUTARY'

export default {
    name:"PopupLayout",
    auth:false,
    components:{
        GlobalSnackbar
    },
    data () {
        return {
            DEFAULT_TITLE,
            drawerOpen:false,
            
        }
    },
    computed:{
        title(){
            let {user} = this.$auth;
            if(user){
                return user.username.toUpperCase()
            }else {
                return DEFAULT_TITLE;
            }

        },
        impersonator(){
            return this.$store.getters["user/impersonator"]
        }
    },
    methods:{
        onTitleClick(){
            if(this.$auth.loggedIn){
                this.$router.push("/user/");
            }else{
                this.$router.push("/");
            }
        }
    }
}
