/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiFirmMember } from '../models/ApiFirmMember';
import type { FirmInviteRequest } from '../models/FirmInviteRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FirmService {

    /**
     * Invite Member
     * @param requestBody
     * @param host
     * @returns ApiFirmMember Successful Response
     * @throws ApiError
     */
    public static inviteMember(
        requestBody: FirmInviteRequest,
        host?: string,
    ): CancelablePromise<ApiFirmMember> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/firm/invite',
            headers: {
                'host': host,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Member
     * Remove Member from a firm
     * @param firmId
     * @param memberId
     * @param host
     * @returns void
     * @throws ApiError
     */
    public static removeMember(
        firmId: any,
        memberId: string,
        host?: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/firm/{firm_id}/member/{member_id}/remove',
            path: {
                'firm_id': firmId,
                'member_id': memberId,
            },
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Enable Member
     * @param firmId
     * @param memberId
     * @param host
     * @returns void
     * @throws ApiError
     */
    public static enableMember(
        firmId: any,
        memberId: string,
        host?: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/firm/{firm_id}/member/{member_id}/enable',
            path: {
                'firm_id': firmId,
                'member_id': memberId,
            },
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Disable Member
     * @param firmId
     * @param memberId
     * @param host
     * @returns void
     * @throws ApiError
     */
    public static disableMember(
        firmId: any,
        memberId: string,
        host?: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/firm/{firm_id}/member/{member_id}/disable',
            path: {
                'firm_id': firmId,
                'member_id': memberId,
            },
            headers: {
                'host': host,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
