/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $TotpEnrollRequest = {
    properties: {
        name: {
            type: 'string',
            isRequired: true,
        },
        key: {
            type: 'string',
            isRequired: true,
        },
        code1: {
            type: 'string',
            isRequired: true,
        },
        code2: {
            type: 'string',
            isRequired: true,
        },
    },
} as const;
