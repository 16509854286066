

type Handler = (e:MessageEvent) => void;

let channel:BroadcastChannel|null = null;



let handlers:Record<string,Handler> = {

}

function broadcastLoop(e:MessageEvent){
    let type = e.data?.type
    if(type in handlers){
        try{
            handlers[type](e)
        }catch(err){
            console.error("Broadcast Event Error",err)
        }
    }
}


function ensureChannel():BroadcastChannel|null{
    if(channel == null && "BroadcastChannel" in window){
        channel = new BroadcastChannel("distributary.bus")
    }
    channel?.addEventListener("message",broadcastLoop)
    return channel
}

export function registerHandler(name:string,handler:Handler){
    handlers[name] = handler
}

export function getChannel():BroadcastChannel|null {
    ensureChannel()
    return channel
}
