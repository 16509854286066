import Vue,{VNode,RenderContext,CreateElement} from "vue"
import {Map} from "immutable"


//@TODO look for reactive way to do nested tree
export type TreeLevelState<T> = T |  Map<string,TreeLevelState<T>>
export type GenericTreeState<T> = Map<string,TreeLevelState<T>>

export interface TreeNodeProps<T> {
    treeState:(item:T) => boolean
    noExpand?:boolean
    item:T
    title?:string
    size?:string
};

const TreeNode = 
    Vue.component("grid-tree-node",{
        functional:true,
        render<T>(h:CreateElement,context:RenderContext<TreeNodeProps<T>>):VNode{
            let {noExpand,item,treeState,title,size} = context.props
            let  node = treeState(item)
            let open = node ?? false
            size = size || "small";
            if(noExpand){
                return h("div",{class:"tree-node"}, [
                    h("v-icon",{props:{[size]:true}},"far minus-square")
                ])
            }
            return  h("v-btn",{ props:{icon:true,small:true}, on: context.listeners,},
                      [h("v-icon",{ 
                          props:{[size]:true},
                          attrs:{title:title ?? "Double Click to Expand All"}
                      },open?"far caret-square-down":"far caret-square-right")])
        }
    })

export default TreeNode
