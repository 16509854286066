/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiPermissions = {
    properties: {
        read: {
            type: 'boolean',
            isRequired: true,
        },
        write: {
            type: 'boolean',
            isRequired: true,
        },
        listing: {
            type: 'boolean',
        },
        manage: {
            type: 'boolean',
        },
        book: {
            type: 'boolean',
        },
        all: {
            type: 'boolean',
            isRequired: true,
        },
    },
} as const;
