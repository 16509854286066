/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UserSummaryRequest = {
    properties: {
        sections: {
            type: 'one-of',
            contains: [{
                type: 'array',
                contains: {
                    type: 'UserSummarySection',
                },
            }, {
                type: 'Enum',
            }],
        },
        primaryIndex: {
            type: 'InstrumentRef',
            isRequired: true,
        },
        accounts: {
            type: 'array',
            contains: {
                type: 'any-of',
                contains: [{
                    type: 'AccountRef',
                }, {
                    type: 'string',
                    format: 'uuid',
                }],
            },
        },
        instrument_types: {
            type: 'array',
            contains: {
                type: 'InstrumentType',
            },
        },
        leg_category: {
            type: 'array',
            contains: {
                type: 'Enum',
            },
        },
        grouping: {
            type: 'array',
            contains: {
                type: 'any-of',
                contains: [{
                    type: 'Enum',
                }, {
                    type: 'string',
                    pattern: '#((?P<name>[A-Za-z][A-Za-z0-9-]{2,})(((?P<star>/\\*?)|(?P<rest>(/[A-Za-z][A-Za-z0-9-]{2,})*)))?(?=([^A-Za-z0-9-]|$)))',
                }, {
                    type: 'string',
                }],
            },
        },
        includeComponents: {
            type: 'boolean',
        },
        since: {
            type: 'one-of',
            contains: [{
                type: 'Enum',
            }, {
                type: 'string',
                format: 'date-time',
            }, {
                type: 'string',
                format: 'date',
            }],
        },
        at: {
            type: 'one-of',
            contains: [{
                type: 'Enum',
            }, {
                type: 'string',
                format: 'date-time',
            }, {
                type: 'string',
                format: 'date',
            }],
        },
        include_basis: {
            type: 'boolean',
        },
        custom_columns: {
            type: 'array',
            contains: {
                type: 'any-of',
                contains: [{
                    type: 'Enum',
                }, {
                    type: 'string',
                    pattern: '=([A-Za-z0-9._]+\\s+is\\s+)?[A-Za-z][A-Za-z0-9_]*\\(.*\\)',
                }, {
                    type: 'string',
                    pattern: '[a-z0-9-_]+(\\.[a-z0-9-_]+)+',
                }, {
                    type: 'string',
                    pattern: '#((?P<name>[A-Za-z][A-Za-z0-9-]{2,})(((?P<star>/\\*?)|(?P<rest>(/[A-Za-z][A-Za-z0-9-]{2,})*)))?(?=([^A-Za-z0-9-]|$)))',
                }],
            },
        },
        networth_features: {
            type: 'array',
            contains: {
                type: 'NetWorthFeatureId',
            },
        },
        perspective: {
            type: 'EntityRef',
        },
        transaction_window: {
            type: 'array',
            contains: {
                properties: {
                },
            },
        },
        explain: {
            type: 'boolean',
        },
        if_modified_since: {
            type: 'string',
            format: 'date-time',
        },
        allow_cached: {
            type: 'boolean',
        },
    },
} as const;
