/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiInstrumentReference = {
    description: `Instrument  Primarily  to contain enough information for a Label`,
    properties: {
        uuid: {
            type: 'string',
            isRequired: true,
            format: 'uuid',
        },
        identifier: {
            type: 'string',
            isRequired: true,
            maxLength: 100,
            minLength: 1,
        },
        instrument_type: {
            type: 'InstrumentType',
            isRequired: true,
        },
        identifier_type: {
            type: 'IdentifierType',
            isRequired: true,
        },
        display_name: {
            type: 'string',
            isRequired: true,
            maxLength: 100,
            minLength: 1,
        },
        issuer_id: {
            type: 'string',
            format: 'uuid',
        },
    },
} as const;
