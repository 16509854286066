
import {Component,Vue,Prop,Watch,ModelSync} from "nuxt-property-decorator"
import {VNode} from "vue"
import {VChip,VIcon} from "vuetify/lib"
import {ApiEntity,ApiAccount,AccountRef,AccountRefType}  from "~/schemas/gen"
import gql from "graphql-tag"
import {
    DEFAULT_ACCOUNT,ApiAccountNode,
    renderAccount,fragApiAccountFields,
    AccountSearchQuery
} from "~/core/account"
import uniqBy from "lodash/uniqBy"
import * as routing from "~/core/routing"
import {PartialQuery, itemExplorationLink} from "~/core/common/input"
export * from "~/core/account"




export function nodeForAccountRef(ref:AccountRef):ApiAccountNode {
    if(ref.type == AccountRefType.ID){
        if(ref.details){

        }
        return { name:"???",uuid:ref.id as string,id_only:true }
    }
    throw new Error("Unable to create Api Node for AccountRef")
}

/**
 * AccountRef From ApiAccountNode
 */
export function accountRefFromNode(node:ApiAccountNode):AccountRef {
    return {id:node.uuid,type:AccountRefType.ID}
}

export function  isApiAccountNode(item:ApiAccount|ApiAccountNode) : item is ApiAccountNode {
    return (item as ApiAccountNode).entity !== undefined;
}




const currentAccount = gql`
query currentAccount($accountUuid:uuid!){
accounts(
    where: { uuid:{_eq: $accountUuid} }
    ) {
    ...ApiAccountFields
    }
}
${fragApiAccountFields}
`



@Component({
    components:{VChip,VIcon}
})
export class AccountItem extends Vue {
    /**
     * AccountItem -
     */
    @Prop() item!:ApiAccountNode
    @Prop({default:false,type:Boolean}) extended!:boolean

    render(h:typeof Vue.prototype.$createElement){
        let {item,extended} = this;
        let children:(VNode|string)[] = [ renderAccount(item) ," " ]
        function addChip(...chipChildren:(VNode|string)[]){
            children.push(h("v-chip", {
                props:{small:true,label:true,outlined:true}
            }, chipChildren))
        }
        if(item == DEFAULT_ACCOUNT){
            addChip("Default")
        }else if((item as any).owner?.is_system_entity){
            children.push(h("v-icon",{
                props:{small:true},
                attrs:{title:"Global Beneficiary"}
            },"far question-circle"))
        }else if((item as any).entity?.is_system_entity){
            children.push(h("v-icon",{
                props:{small:true},
                attrs:{title:"Global Custodian"}
            },"fas university"))
        }

        children.push(" ")

        let lines =[
            h("div",{ class:["text-no-wrap"]},children)
        ]
        if(extended && isApiAccountNode(item) && item.entity?.name){
           lines.push(h("h6",{staticClass:"mt-n1"},item.entity?.name))
        }
        let body = h("div",{
            attrs:this.$attrs,on:this.$listeners,
            class:{"account-input-select":true}
        },
            lines)
        return itemExplorationLink(h,routing.forAccount(item.uuid),body)

    }


}

/**
 * Instrument Input component with combobox and autocomplete functionality
 */
@Component({
           inheritAttrs:true,
           components:{AccountItem},
           apollo:{
               $client:(function (this:AccountInput){
                   return this.dataSource?.clientId ??  this.clientId ?? "default"
               } as any),
               searchAccounts:{
                   query(){
                       let {dataSource:ds} = this
                       if(ds?.query) return ds.query
                       return AccountSearchQuery
                   },  variables():any {
                       let {dataSource:ds} = this
                       if(ds?.variables) return ds.variables(this.search)
                       var $query =  this.search.trim() + "%"
                       let where:any = { _and:[
                           { _or:[
                               { name : {_ilike: $query} },
                               { entity: { name: { _ilike: $query} } }]
                           }
                       ]}

                       if(!this.allowSystem){
                           where._and.push( { is_system_account: { _eq: false } })
                       }
                       return {
                           conditions:where,
                           offset:0,
                           limit:25
                       }
                   },
                   update(data){
                       if(this.dataSource) {
                           return this.dataSource.update(data)
                       }
                       return data.accounts
                   },
                   skip() {
                       let {search} = this
                       return !search || search.trim() == ""
                   },
                   context(){
                       return this.dataSource?.context ?? {}
                   },
                   debounce:300
               },
                   currentAccount:{
                       //This is an event that will load
                       //the current selection as an ApiAccountNode if need be
                       query:currentAccount,
                           variables():any {
                               let uuid =typeof(this.value) === "string"?this.value:this.value.uuid;
                               return {accountUuid:uuid}
                           },
                           update: data => data.accounts[0],
                           skip(){
                               if(!this.value) return true;
                               if(typeof this.value == "string") return false;
                               let {uuid} = this.value
                               return this.value == null
                                   || uuid === undefined
                                   || this.value == DEFAULT_ACCOUNT
                                   || this.items.find((x:ApiAccountNode) => {
                                       return  x.uuid == uuid && !x.id_only

                                   })!== undefined
                           }
                   },
                   recentAccounts:{
                       query:gql`
                       query recent($query:accounts_bool_exp) {
                       accounts(where:$query,limit:7,order_by:{updated_time:desc}){
                        ...ApiAccountFields
                       }
                       }

                       ${fragApiAccountFields}
                       `,
                       update: data => data.accounts,
                       variables(){
                           let {universeUuid} = this
                           if(!universeUuid) return {}
                           return {
                               query:{
                                   user:{profile:{uuid:{_eq: universeUuid} } }
                               }
                           }
                       },
                       debounce:300
                   }
           }
})
export default class AccountInput extends Vue {
    @ModelSync("value","input") input!:string|ApiAccount|undefined;
    //@Prop({default: "name"}) outType!:InstrumentRefType
    @Prop({}) vjsfContext!:any; //Optional Vjsf slot context
    @Prop() label!:string|null;
    @Prop() errorMessages!:any[]
    @Prop({default:true})  allowSystem!:boolean
    @Prop({default:false}) objectMode!:boolean;
    @Prop({default:false}) includeDefault!:boolean;
    @Prop({type:Object,default:null}) dataSource!:PartialQuery|null
    @Prop({type:String,default:null}) universeUuid!:string|null
    @Prop({type:String,default:null}) clientId?:string
    @Prop({type:String,default:"Type account name to search"}) placeholder!:string


    search:string =  ""
    loading:boolean = false
    searchAccounts:ApiAccountNode[] = []
    currentAccount:ApiAccountNode|null = null
    recentAccounts:ApiAccountNode[]|null = null
    initValue:ApiAccount|string|null = null
    itemError:Error|any|null = null
    fixed:boolean = false
    showSystem:boolean = false

    get passedListeners():object{
        let {input,...captured} = this.$listeners
        return   captured;
    }


    async mounted(){
        let {input} = this
        this.initValue = input || null;
    }

    get displayLabel():string{
        return this.label || this.vjsfContext?.label
    }

    get items():ApiAccountNode[]{
        let items:ApiAccountNode[] = []
        if(this.searchAccounts.length > 0 && this.search){
            items.push(...this.searchAccounts)
        }
        if(this.currentAccount) items.push(this.currentAccount)
        if(this.recentAccounts) items.push(...this.recentAccounts)
        if(this.includeDefault) items.push(DEFAULT_ACCOUNT as ApiAccountNode)
        return uniqBy(items,(x:any) => x.uuid)
    }
    get visibleItems(){
        if(this.showSystem) return this.items
        return this.items.filter(x => x.user_defined || x == this.currentAccount)
    }

    get selectInput():string|null {
        if(!this.input) return null;
        if(typeof(this.input) == "string") return this.input;
        return  this.input.uuid!
    }

    get numSystem():number {
        return this.items.filter(x => !x.user_defined).length
    }

    @Watch("universeUuid")
    @Watch("dataSource")
    refreshItems(value:any){
        /*
         console.log("Refresh Items ->",value)
        this.$apollo.queries.recentAccounts.refetch()
        this.$apollo.queries.searchAccounts.refetch()
         */
    }

    onItemSelected(x:ApiAccount|null){
        if( x == null) {
            this.$emit("input",null)
            return
        }
        let ret!:any
        if(this.objectMode){
            ret =  x;
        }else ret = x.uuid!

        this.$emit("input",ret)
    }

    compareAccount(x:ApiAccount|string,y:ApiAccount|string):boolean {
        if(x === y ||  x == y  ) return true
        if(x == null || y == null) return false;
        if(typeof(x) == "string"){
            if(typeof(y) == "object") return x == y.uuid
            return false
        }
        if(typeof(y) == "string") return y === x.uuid
        return false;
    }
    getItemText(item:ApiAccountNode){
        if(typeof item === "string") return item
        return renderAccount(item)
    }

    getItemValue(x:ApiAccount ){
        return x.uuid
    }
}

