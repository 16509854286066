/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiTransactionProspect } from '../models/ApiTransactionProspect';
import type { InputApiTransactionProspect } from '../models/InputApiTransactionProspect';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProspectService {

    /**
     * Add Prospect
     * @param requestBody
     * @param commit
     * @param host
     * @returns ApiTransactionProspect Successful Response
     * @throws ApiError
     */
    public static addProspect(
        requestBody: InputApiTransactionProspect,
        commit: boolean = true,
        host?: string,
    ): CancelablePromise<ApiTransactionProspect> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/prospect/',
            headers: {
                'host': host,
            },
            query: {
                'commit': commit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Prospect
     * @param uuid
     * @param requestBody
     * @param commit
     * @param host
     * @returns ApiTransactionProspect Successful Response
     * @throws ApiError
     */
    public static updateProspect(
        uuid: string,
        requestBody: InputApiTransactionProspect,
        commit: boolean = true,
        host?: string,
    ): CancelablePromise<ApiTransactionProspect> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/prospect/{uuid}',
            path: {
                'uuid': uuid,
            },
            headers: {
                'host': host,
            },
            query: {
                'commit': commit,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Prospect
     * @param id
     * @param host
     * @returns void
     * @throws ApiError
     */
    public static deleteProspect(
        id: string,
        host?: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/prospect/{uuid}',
            headers: {
                'host': host,
            },
            query: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
