/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Direction {
    BUY = 'B',
    SELL = 'S',
    PAY = 'P',
    RECEIVE = 'R',
}
