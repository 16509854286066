




function ensureDownloadLink():HTMLAnchorElement{
    let downloadLink:HTMLAnchorElement = document.createElement("a");
    (downloadLink as any).style = "display: none"
    document.body.appendChild(downloadLink)
    return downloadLink
}

function click (node:HTMLElement) {
  try {
    node.dispatchEvent(new MouseEvent('click'))
  } catch (e) {
    var evt = document.createEvent('MouseEvents')
    evt.initMouseEvent('click', true, true, window, 0, 0, 0, 80,
                          20, false, false, false, false, 0, null)
    node.dispatchEvent(evt)
  }
}

export function saveFile(data:any,fileName:string,wnd?:Window){
  var json = JSON.stringify(data),
    blob = new Blob([json], {type: "application/json"})
  return saveRawFile(blob, fileName)
}

export function saveRawFile(blob:Blob,fileName:string,wnd?:Window){
      let blobUrl= window.URL.createObjectURL(blob);
    try{
        if(wnd){
            wnd.location.href = blobUrl
            return
        }
        const a = ensureDownloadLink()
        a.href = blobUrl;
        a.download = fileName;
        a.rel = 'noopener'
        let res= click(a)
    }finally {
        setTimeout(() => {
            window.URL.revokeObjectURL(blobUrl);
        },60000)
    }

}

let mobile:boolean|null =null;
export function isMobile():boolean {
    if(mobile === null){
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];

        mobile = toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    }
    return mobile
}
