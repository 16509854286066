/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiLegReference = {
    properties: {
        id: {
            type: 'string',
            isRequired: true,
        },
        transaction: {
            type: 'string',
            isRequired: true,
        },
        transaction_id: {
            type: 'string',
        },
        event: {
            type: 'string',
        },
    },
} as const;
