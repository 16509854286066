/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $SignupRequest = {
    description: `Signup Request`,
    properties: {
        email: {
            type: 'string',
            format: 'email',
        },
        first_name: {
            type: 'string',
            isRequired: true,
            maxLength: 150,
            minLength: 1,
        },
        no_login: {
            type: 'boolean',
        },
        private_client: {
            type: 'boolean',
        },
        password: {
            type: 'string',
            isRequired: true,
            format: 'password',
        },
        token: {
            type: 'string',
        },
        details: {
            type: 'SignupDetailRequest',
        },
        entity: {
            type: 'InputApiEntity',
        },
        waitlist_id: {
            type: 'string',
        },
        confirm_duplicate: {
            type: 'boolean',
        },
        client_identifier: {
            type: 'string',
        },
    },
} as const;
