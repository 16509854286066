/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiObservableType = {
    properties: {
        name: {
            type: 'string',
            isRequired: true,
        },
        identifier: {
            type: 'any-of',
            contains: [{
                type: 'string',
            }, {
                type: 'ObservableTypes',
            }],
            isRequired: true,
        },
        unique_by: {
            type: 'array',
            contains: {
                type: 'string',
            },
            isRequired: true,
        },
        info: {
            type: 'ApiObservableTypeInfo',
        },
    },
} as const;
