/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $FileInputStageState = {
    properties: {
        files: {
            type: 'array',
            contains: {
                type: 'FileInputEntry',
            },
            isRequired: true,
        },
    },
} as const;
