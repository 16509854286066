/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ContactResponse = {
    properties: {
        success: {
            type: 'boolean',
            isRequired: true,
        },
        message: {
            type: 'string',
            isRequired: true,
        },
    },
} as const;
