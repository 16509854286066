/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $PipelineUpdate = {
    properties: {
        entry: {
            type: 'PipelineStepEntry',
            isRequired: true,
        },
        added: {
            type: 'array',
            contains: {
                properties: {
                },
            },
            isRequired: true,
        },
        removed: {
            type: 'array',
            contains: {
                properties: {
                },
            },
            isRequired: true,
        },
        added_index: {
            type: 'array',
            contains: {
                properties: {
                },
            },
            isRequired: true,
        },
        removed_index: {
            type: 'array',
            contains: {
                properties: {
                },
            },
            isRequired: true,
        },
        data: {
            properties: {
            },
        },
        columns: {
            properties: {
            },
        },
        indexes: {
            properties: {
            },
        },
        event_type: {
            type: 'Enum',
        },
        meta: {
            properties: {
            },
        },
    },
} as const;
