/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $UtilityResponse = {
    properties: {
        client_ip: {
            type: 'string',
        },
        headers: {
            type: 'array',
            contains: {
                type: 'array',
                contains: {
                    properties: {
                    },
                },
            },
            isRequired: true,
        },
    },
} as const;
