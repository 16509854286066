/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum DayCountConvention {
    ACR_30_360 = 'T36',
    ACR_ACT_360 = 'A36',
    ACR_ACT_365 = 'F36',
}
