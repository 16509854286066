/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $InputApiDocumentLink = {
    description: `ApiDocumentLink input `,
    properties: {
        uuid: {
            type: 'string',
            format: 'uuid',
        },
        attributes: {
            properties: {
            },
        },
        entity: {
            type: 'string',
            format: 'uuid',
        },
        account: {
            type: 'string',
            format: 'uuid',
        },
        transaction: {
            type: 'string',
            format: 'uuid',
        },
        prospect: {
            type: 'string',
            format: 'uuid',
        },
        event: {
            type: 'string',
            format: 'uuid',
        },
        instrument: {
            type: 'string',
            format: 'uuid',
        },
        workflow: {
            type: 'string',
            pattern: '[0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26}',
        },
        observable: {
            type: 'string',
            pattern: '[0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26}',
        },
    },
} as const;
