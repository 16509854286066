/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $LineItem = {
    description: `Parts of line item that represent row Identity`,
    properties: {
        position: {
            type: 'number',
        },
        value: {
            type: 'number',
        },
        price: {
            type: 'number',
        },
        components: {
            type: 'dictionary',
            contains: {
                type: 'LineItemPart',
            },
        },
        analytics: {
            type: 'array',
            contains: {
                properties: {
                },
            },
        },
        labels: {
            type: 'dictionary',
            contains: {
                type: 'any-of',
                contains: [{
                    type: 'string',
                }, {
                    type: 'boolean',
                }, {
                    type: 'number',
                }, {
                    type: 'number',
                }, {
                    type: 'ApiTransaction',
                }, {
                    type: 'ApiLegReference',
                }, {
                    type: 'ApiInstrumentReference',
                }, {
                    type: 'string',
                    pattern: 'r#[0-9]+',
                }],
            },
            isRequired: true,
        },
        category: {
            type: 'all-of',
            contains: [{
                type: 'LineItemCategory',
            }],
        },
        type_label: {
            type: 'string',
        },
        stock_designation: {
            type: 'StockDesignation',
        },
        is_aggregate: {
            type: 'number',
        },
        errors: {
            type: 'number',
        },
        is_total: {
            type: 'boolean',
        },
        since: {
            type: 'LineItemData',
        },
        diff: {
            type: 'LineItemData',
        },
        meta: {
            properties: {
            },
        },
    },
} as const;
