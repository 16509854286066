/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Access permissions class for objects
 */
export enum AccessPermissions {
    '_1' = 1,
    '_2' = 2,
    '_4' = 4,
    '_8' = 8,
    '_16' = 16,
}
