/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiCustomEtlWorkflow = {
    properties: {
        inputs: {
            type: 'ApiWorkflowInputs',
            isRequired: true,
        },
        stages: {
            type: 'ApiWorkflowStages',
            isRequired: true,
        },
    },
} as const;
