/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $BatchGetRequest = {
    properties: {
        ids: {
            type: 'array',
            contains: {
                type: 'any-of',
                contains: [{
                    type: 'string',
                }, {
                    type: 'string',
                    pattern: '[0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26}',
                }],
            },
            isRequired: true,
        },
        meta: {
            properties: {
            },
        },
    },
} as const;
