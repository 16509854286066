import type {Context} from "@nuxt/types"
import type { AxiosRequestConfig } from "axios";

/**
* This plugin is a monkey patch to allow access to the
* whole response object in auth requests  against self
* Additionally we will request a root access token for requests
* to the self endpoing when the universe is empty
 */
export default function(cxt:Context){
    let {$axios} = cxt;
  
    $axios.interceptors.request.use(function(config:AxiosRequestConfig){
        let {$universe} = cxt;
        if($universe?.root == null){
            config.params = { 
                ...(config.params || {}),
                need_root:true
            }
        }
        return  config
    },function(error){
        return Promise.reject(error)
    })

    $axios.interceptors.response.use(function(response){
        let req = response.request as AxiosRequestConfig;
        if(response.status == 200 && response?.config?.url?.endsWith?.("auth/self")){
            if(response.data){
                // We will attch araw resposne method to user to access the raw response object 
                // for self
                response.data.user  = {
                    ...response.data.user,
                    rawResponse: function(){
                            return response
                        }
                    }
            }
        }
        return response
    })
}
