/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiEntity = {
    description: `Entity(id, notes, user_attributes, user_tags, user_tags_vector, user, uuid, name, created_time, updated_time, description, legal_name, first_name, middle_name, last_name, parent, birth_date, local_type, state_type, national_type, email, phone, ceo, market_segment, manager, website, info, settlement_account, is_archived)`,
    properties: {
        notes: {
            type: 'string',
            description: `Notes from user, attributes and tags are extracted from this`,
        },
        user_attributes: {
            type: 'any-of',
            description: `Attributes Extracted from notes`,
            contains: [{
                type: 'string',
                format: 'json-string',
            }],
        },
        user_tags: {
            type: 'array',
            contains: {
                properties: {
                },
            },
        },
        uuid: {
            type: 'string',
            format: 'uuid',
        },
        name: {
            type: 'string',
            description: `nickname`,
            isRequired: true,
            maxLength: 128,
        },
        created_time: {
            type: 'string',
            description: `creation time`,
            format: 'date-time',
        },
        updated_time: {
            type: 'string',
            description: `system update time`,
            format: 'date-time',
        },
        legal_name: {
            type: 'string',
            description: `legal_name`,
            isRequired: true,
            maxLength: 128,
        },
        first_name: {
            type: 'string',
            description: `Individuals first name`,
            maxLength: 50,
        },
        middle_name: {
            type: 'string',
            description: `Individuals middle name or initial`,
            maxLength: 50,
        },
        last_name: {
            type: 'string',
            description: `Individuals last name`,
            maxLength: 50,
        },
        birth_date: {
            type: 'string',
            description: `when was the entity created`,
            format: 'date',
        },
        local_type: {
            type: 'string',
            description: `for legal entities, a city level entity designation`,
            maxLength: 4,
        },
        state_type: {
            type: 'string',
            description: `for legal entities, a state level entity designation`,
            maxLength: 4,
        },
        national_type: {
            type: 'string',
            description: `for legal entities, a state level entity designation`,
            maxLength: 4,
        },
        email: {
            type: 'string',
            description: `email`,
            maxLength: 128,
        },
        phone: {
            type: 'string',
            description: `phone`,
            maxLength: 50,
        },
        market_segment: {
            type: 'string',
            description: `market_segment`,
            maxLength: 50,
        },
        info: {
            type: 'any-of',
            description: `info`,
            contains: [{
                type: 'string',
                format: 'json-string',
            }],
        },
        parent_id: {
            type: 'string',
            format: 'uuid',
        },
        ceo_id: {
            type: 'string',
            format: 'uuid',
        },
        manager_id: {
            type: 'string',
            format: 'uuid',
        },
        website: {
            type: 'string',
            format: 'uri',
            maxLength: 2083,
            minLength: 1,
        },
        acl: {
            type: 'ApiPermissions',
        },
        settlement_account_id: {
            type: 'string',
            format: 'uuid',
        },
    },
} as const;
