/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiCustomMailbox } from '../models/ApiCustomMailbox';
import type { ListResponse_ApiCustomMailbox_ } from '../models/ListResponse_ApiCustomMailbox_';
import type { MailboxRequest } from '../models/MailboxRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProfileService {

    /**
     * Get Mailboxes
     * @returns ListResponse_ApiCustomMailbox_ Successful Response
     * @throws ApiError
     */
    public static getMailboxes(): CancelablePromise<ListResponse_ApiCustomMailbox_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/profile/mailboxes',
        });
    }

    /**
     * Request Mailbox
     * @param requestBody
     * @returns ApiCustomMailbox Successful Response
     * @throws ApiError
     */
    public static requestMailbox(
        requestBody: MailboxRequest,
    ): CancelablePromise<ApiCustomMailbox> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/profile/mailboxes',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
