/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $TimeSeriesSelect = {
    description: `Time series Select statement`,
    properties: {
        key: {
            type: 'any-of',
            contains: [{
                type: 'string',
                format: 'uuid',
            }, {
                type: 'InstrumentTriple',
            }],
            isRequired: true,
        },
        columns: {
            type: 'array',
            contains: {
                type: 'string',
            },
            isRequired: true,
        },
        tick_type: {
            type: 'TickType',
        },
        query: {
            type: 'TimeSeriesQuery',
        },
        hints: {
            properties: {
            },
        },
    },
} as const;
