/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $PipelineStepEntry = {
    properties: {
        name: {
            type: 'string',
            isRequired: true,
        },
        run_time_ms: {
            type: 'number',
        },
        at: {
            type: 'any-of',
            contains: [{
                type: 'Enum',
            }, {
                type: 'string',
                format: 'date-time',
            }, {
                type: 'string',
                format: 'date',
            }],
        },
        query_count: {
            type: 'number',
        },
        key: {
            type: 'string',
        },
    },
} as const;
