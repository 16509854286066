/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $PagedBoundingBox = {
    properties: {
        page: {
            type: 'number',
            isRequired: true,
        },
        bbox: {
            type: 'array',
            contains: {
                properties: {
                },
            },
            isRequired: true,
        },
        id: {
            type: 'string',
        },
        coords: {
            type: 'string',
        },
    },
} as const;
