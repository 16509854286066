/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Identifier for the various accounts that settlement accounts can be provided for
 * designated during event entry
 */
export enum EventSettlementTargetAccount {
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
    '_4' = 4,
    '_5' = 5,
    '_1000' = 1000,
    '_1001' = 1001,
    '_1002' = 1002,
    '_1003' = 1003,
}
