/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ListResponse_ObservableSummaryItem_ = {
    properties: {
        results: {
            type: 'array',
            contains: {
                type: 'ObservableSummaryItem',
            },
            isRequired: true,
        },
        limit: {
            type: 'number',
        },
        offset: {
            type: 'number',
        },
        count: {
            type: 'number',
        },
        count_relation: {
            type: 'string',
        },
        meta: {
            properties: {
            },
        },
    },
} as const;
