/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PipelineStepEntry } from './PipelineStepEntry';

export type PipelineUpdate = {
    entry: PipelineStepEntry;
    added: Array<any>;
    removed: Array<any>;
    added_index: Array<any>;
    removed_index: Array<any>;
    data?: any;
    columns?: any;
    indexes?: any;
    event_type?: PipelineUpdate.event_type;
    meta?: any;
};

export namespace PipelineUpdate {

    export enum event_type {
        UPDATE = 'update',
    }


}

