/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Tabular = {
    description: `Representation of Tabular information
    NOTE: This is seen/sent to clients so structure should be
    json compatible and light `,
    properties: {
        headers: {
            type: 'array',
            contains: {
                type: 'string',
            },
            isRequired: true,
        },
        dimensions: {
            type: 'array',
            contains: {
                properties: {
                },
            },
            isRequired: true,
        },
        preview_bytes: {
            type: 'number',
        },
        state: {
            type: 'ImportState',
        },
        report_id: {
            type: 'string',
        },
        provider: {
            type: 'string',
        },
    },
} as const;
