/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $InputApiObservable = {
    description: `ApiObservable used for Input`,
    properties: {
        identifier: {
            type: 'string',
            maxLength: 128,
            minLength: 1,
        },
        type_id: {
            type: 'string',
            isRequired: true,
        },
        description: {
            type: 'string',
        },
        instant: {
            type: 'any-of',
            contains: [{
                type: 'string',
                format: 'date-time',
            }, {
                type: 'string',
                format: 'date',
            }],
        },
        date_of: {
            type: 'string',
            format: 'date',
        },
        price: {
            type: 'number',
        },
        value: {
            type: 'number',
        },
        quantity: {
            type: 'number',
        },
        units_id: {
            type: 'any-of',
            contains: [{
                type: 'string',
                format: 'uuid',
            }, {
                type: 'InstrumentTriple',
            }],
        },
        instrument_id: {
            type: 'string',
            format: 'uuid',
        },
        account_id: {
            type: 'string',
            format: 'uuid',
        },
        document_id: {
            type: 'string',
            format: 'uuid',
        },
        fields: {
            properties: {
            },
        },
        notes: {
            type: 'string',
        },
        import_facet: {
            type: 'ImportFacet',
        },
        unused__: {
            type: 'ObservableField',
        },
        unused2__: {
            type: 'ObservableSuggestionType',
        },
        id: {
            type: 'string',
            pattern: '[0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26}',
        },
        new_id: {
            type: 'string',
            pattern: '[0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26}',
        },
        client_identifier: {
            type: 'string',
        },
    },
} as const;
