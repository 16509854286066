/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum StockDesignation {
    ASSET = 'A',
    LIABILITY = 'L',
    CAPITAL = 'c',
    TOTAL = 't',
    DYNAMIC = 'D',
}
