/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
import {AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import type Axios from "axios"
import FormData from 'form-data';

import { ApiError } from './ApiError';
import type { ApiRequestOptions } from './ApiRequestOptions';
import type { ApiResult } from './ApiResult';
import { CancelablePromise } from './CancelablePromise';
import type { OnCancel } from './CancelablePromise';
import type { OpenApiConfig } from "./OpenAPI"
import {Map } from "immutable"


let axios!:typeof Axios;
let baseUrl:string = ""

export function setAxios(_axios:typeof Axios){
    axios= _axios
}


function isDefined<T>(value: T | null | undefined): value is Exclude<T, null | undefined> {
    return value !== undefined && value !== null;
}

function isString(value: any): value is string {
    return typeof value === 'string';
}

function isStringWithValue(value: any): value is string {
    return isString(value) && value !== '';
}

function isBlob(value: any): value is Blob {
    return (
        typeof value === 'object' &&
        typeof value.type === 'string' &&
        typeof value.stream === 'function' &&
        typeof value.arrayBuffer === 'function' &&
        typeof value.constructor === 'function' &&
        typeof value.constructor.name === 'string' &&
        /^(Blob|File)$/.test(value.constructor.name) &&
        /^(Blob|File)$/.test(value[Symbol.toStringTag])
    );
}

function isSuccess(status: number): boolean {
    return status >= 200 && status < 300;
}

function base64(str: string): string {
    try {
        return btoa(str);
    } catch (err) {
        // @ts-ignore
        return Buffer.from(str).toString('base64');
    }
}

function getQueryString(params: Record<string, any>): string {
    const qs: string[] = [];

    const append = (key: string, value: any) => {
        qs.push(`${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);
    };

    Object.entries(params)
        .filter(([_, value]) => isDefined(value))
        .forEach(([key, value]) => {
            if (Array.isArray(value)) {
                value.forEach(v => append(key, v));
            } else {
                append(key, value);
            }
        });

    if (qs.length > 0) {
        return `?${qs.join('&')}`;
    }

    return '';
}



const getUrl = (config: OpenAPIConfig, options: ApiRequestOptions): string => {
    const encoder = config.ENCODE_PATH || encodeURI;

    const path = options.url
        .replace('{api-version}', config.VERSION)
        .replace(/{(.*?)}/g, (substring: string, group: string) => {
            if (options.path?.hasOwnProperty(group)) {
                return encoder(String(options.path[group]));
            }
            return substring;
        });

    const url = `${config.BASE}${path}`;
    if (options.query) {
        return `${url}${getQueryString(options.query)}`;
    }
    return url;
};

function getFormData(options: ApiRequestOptions): FormData | undefined {
    if (options.formData) {
        const formData = new FormData();

        const append = (key: string, value: any) => {
            if (isString(value) || isBlob(value)) {
                formData.append(key, value);
            } else {
                formData.append(key, JSON.stringify(value));
            }
        };

        Object.entries(options.formData)
            .filter(([_, value]) => isDefined(value))
            .forEach(([key, value]) => {
                if (Array.isArray(value)) {
                    value.forEach(v => append(key, v));
                } else {
                    append(key, value);
                }
            });

        return formData;
    }
    return;
}

type Resolver<T> = (options: ApiRequestOptions) => Promise<T>;

async function resolve<T>(options: ApiRequestOptions, resolver?: T | Resolver<T>): Promise<T | undefined> {
    if (typeof resolver === 'function') {
        return (resolver as Resolver<T>)(options);
    }
    return resolver;
}

async function getHeaders(config:OpenAPIConfig , options: ApiRequestOptions, formData?: FormData): Promise<Record<string, string>> {
    const [token , username , password , additionalHeaders ] =
        await Promise.all( [ resolve(options, config.TOKEN),
                          resolve(options, config.USERNAME),
                          resolve(options, config.PASSWORD),
                          resolve(options, config.HEADERS)]);
    const formHeaders = typeof formData?.getHeaders === 'function' && formData?.getHeaders() || {}

    const headers = Object.entries({
        Accept: 'application/json',
        ...additionalHeaders,
        ...options.headers,
        ...formHeaders,
    })
    .filter(([_, value]) => isDefined(value))
    .reduce((headers, [key, value]) => ({
        ...headers,
        [key]: String(value),
    }), {} as Record<string, string>);

    if (isStringWithValue(token)) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    if (isStringWithValue(username) && isStringWithValue(password)) {
        const credentials = base64(`${username}:${password}`);
        headers['Authorization'] = `Basic ${credentials}`;
    }

    return headers;
}

function getRequestBody(options: ApiRequestOptions): any {
    if (options.body) {
        return options.body;
    }
    return;
}

async function sendRequest(
    openApiConfig:OpenAPIConfig,
    options: ApiRequestOptions,
    url: string,
    formData: FormData | undefined,
    body: any,
    headers: Record<string, string>,
    onCancel: OnCancel,
    context?:ContextType
): Promise<AxiosResponse<any>> {
    const source = axios.CancelToken.source();

    const config: AxiosRequestConfig = {
        url,
        headers,
        data: body ?? formData,
        method: options.method,
        withCredentials:openApiConfig.WITH_CREDENTIALS,
        cancelToken: source.token,
        openapi:{
            config: openApiConfig
        }
    };
    if(context?.get?.(KeyBinaryResponse) == true){
      config.responseType = "blob"
    }

    onCancel(() => source.cancel('The user aborted a request.'));

    try {
        return await axios.request(config);
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
            return axiosError.response;
        }
        throw error;
    }
}

function getResponseHeader(response: AxiosResponse<any>, responseHeader?: string): string | undefined {
    if (responseHeader) {
        const content = response.headers[responseHeader];
        if (isString(content)) {
            return content;
        }
    }
    return;
}

function getResponseBody(response: AxiosResponse<any>): any {
    if (response.status !== 204) {
        return response.data;
    }
    return;
}

function catchErrors(options: ApiRequestOptions, result: ApiResult): void {
    const errors: Record<number, string> = {
        400: 'Bad Request',
        401: 'Unauthorized',
        403: 'Forbidden',
        404: 'Not Found',
        500: 'Internal Server Error',
        502: 'Bad Gateway',
        503: 'Service Unavailable',
        ...options.errors,
    }

    const error = errors[result.status];
    if (error) {
        throw new ApiError(options,result, error);
    }

    if (!result.ok) {
        throw new ApiError(options,result, 'Generic Error');
    }
}

type ContextType = Map<string|Symbol,any>
type ResponseProcessor = (resp:AxiosResponse<any>,config:OpenAPIConfig) => any
const KeyResponseProcessor = Symbol()
const KeyBinaryResponse = Symbol()


export type RequestData  ={
    config:OpenApiConfig
    options:ApiRequestOptions
    url:string
    headers:Record<string,any>
    body:any
    formData:any
    //Request context at the time of the request
    context?:ContextType
}
export type Interceptor = (rd:RequestData)=> void|Promise<void>
const INTERCEPTORS:Interceptor[] = []

// A request context that last till the end of a microtask
let requestContext:ContextType|null = null

//Add data to the request context
export function addContextData(upd:(x:ContextType) => ContextType){
    if(requestContext == null){
        queueMicrotask(() => {
            queueMicrotask(() => {
                requestContext = null
            })
        })
    }
    //Update the request context
    requestContext = upd(requestContext || new Map())
}

export function addRequestInterceptor(x:Interceptor ){
    INTERCEPTORS.push(x)
}
//Apply Interceptros
async function applyInterceptors(rd:RequestData){
    for(let x of INTERCEPTORS){
        const lr = x(rd)
        if(lr) await lr
    }
}



export function withResponseHandler(handler:ResponseProcessor){
  addContextData(x => x.set(KeyResponseProcessor,handler))
}
//Ask for a binary response
export function withBinaryResponse(){
  addContextData(x => x.set(KeyBinaryResponse,true))
}
/**
 * Request using axios client
 * @param options The request options from the the service
 * @returns CancelablePromise<T>
 * @throws ApiError
 */
export function request<T>(config:OpenAPIConfig,options: ApiRequestOptions): CancelablePromise<T> {
    let submitContext = requestContext
    return new CancelablePromise(async (resolve:any, reject:any, onCancel:any) => {
        try {
            const url = getUrl(config,options);
            const formData = getFormData(options);
            const body = getRequestBody(options);
            const headers = await getHeaders(config,options,formData);

            if (!onCancel.isCancelled) {
                let rd:RequestData ={config,options,url,formData,body,headers,
                context:submitContext}
                await applyInterceptors(rd)
                const response = await sendRequest(config,options, url, formData, body, headers, onCancel,submitContext);
                let proc = submitContext?.get(KeyResponseProcessor) as ResponseProcessor
                if(proc) resolve(proc(response,config))

                const responseBody = getResponseBody(response);
                const responseHeader = getResponseHeader(response, options.responseHeader);

                const result: ApiResult = {
                    url,
                    ok: isSuccess(response.status),
                    status: response.status,
                    statusText: response.statusText,
                    body: responseHeader || responseBody,
                };

                catchErrors(options, result);

                resolve(result.body);
            }
        } catch (error) {
            reject(error);
        }
    });
}
