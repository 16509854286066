import {AxiosResponse} from "axios"

/**
 * Generic Http Error Response
 */
export class HttpGenericError extends Error {
    code:string
    response:AxiosResponse
    constructor(resp:AxiosResponse<any>){
        let {message,code} =resp.data;
        super(message)
        this.code = code;
        this.response = resp
    }
    withDefaultMessage(msg:string):HttpGenericError{
        if(!this.message){
            this.message = msg
        }
        return this;
    }

    static is(x:any):x is HttpGenericError {
        return x instanceof HttpGenericError
    }
}
