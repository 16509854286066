import {argumentPlaceholder} from "@babel/types"
import type {Context} from "@nuxt/types"


let appAuth!:Context['$auth']
let appStore!:Context['store']

export default function ({app,store,$auth}:Context){
    appAuth = $auth
    appStore = store
}


export function useAuth():Context['$auth'] {
    return appAuth
}

export function useStore():Context['store'] {
    return appStore
}
