/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum Periodicity {
    DAILY = 'D',
    WEEKLY = 'W',
    BI_WEEKLY = 'B',
    MONTHLY = 'M',
    QUARTERLY = 'Q',
    SEMI_ANNUALLY = 'S',
    ANNUALLY = 'A',
}
