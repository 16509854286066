
import {Vue,Component,Prop,namespace,Watch,Emit} from "nuxt-property-decorator"
import {ApiDocumentNode,pickDocumentFile,fragDocumentFields,
    UploadFlowInput} from "~/core/documents"
import Flow,{FlowSpec} from "~/core/flows"
import {StepChangeEvent} from "../flow"
import {UploadJob} from "~/store/documents"
import {InputApiDocument} from "~/schemas/gen"
import {Editor } from "./editing"
import type {INotifier} from "~/plugins/notifier.client"

const documentNs = namespace('documents')



export const shortUploadFlow = new FlowSpec([]) .withEntries( {
    "id":"basic-info",
    target:"basic-info",
    title:"Basic Info"
},
{
    id:"upload",
    target:"upload",
    title:"Uploading"
});

export const singleUploadFlow = shortUploadFlow .clone().withEntries(
    {
        "id":"links",
        target:"links",
        title:"Links"
    }
);

export const uploadOnlyFlow = new FlowSpec([])
    .withEntries( {
        id:"upload",
        target:"document-upload",
        title:"Uploading"
    })

@Component
export class UploadSubModel extends  Editor<any>{
    uploading:boolean =false
    input:UploadFlowInput|null =null
    jobId:number|undefined = undefined
    get running():boolean{
        return this.input != null
    }
    set running(x){
        this.input = null
    }
    /**
     * This is to handle the injected values that Editor migh twant
     */
    withParent(parent:Vue):UploadSubModel {
        return this;
    }
    get $notifier():INotifier{
        return this.$nuxt.$notifier
    }
    /**
     * Begin an upload sequence
     */
    begin(file:File,input?:Partial<InputApiDocument>):UploadSubModel {
        let fle!:UploadFlowInput
        if(input) fle = {
            ...input,
            file, name:input.name || file.name,
            storage_size :file.size}
        else {
            fle = {
                name :file.name,
                flat_tags:[],
                file,
                storage_size :file.size
            }
        }

        this.input=fle;
        return this;
    }
    end(){
        this.input = null
    }
    start(){ this.uploading=true; }
    stop(){ this.uploading =false; }
}


@Component
export default class DocumentUploadDialog extends Vue {
    @Prop({required:true}) readonly upload!:UploadSubModel;
    @Prop({type:Boolean,default:false}) readonly uploadOnly!:boolean;
    @documentNs.Action('startUpload') startUpload!:any
    @documentNs.Action('waitForJob') waitForJob!:(id:number) => Promise<UploadJob>;
    @documentNs.Action('getJob') getUploadJob!:(id:number) => Promise<UploadJob>;
    @Prop({type:Boolean}) short?:boolean;
    @Prop({type:Function}) flowSpecTransform?:(flow:FlowSpec) => FlowSpec
    @Prop({type:Function}) isEditable?:Function
    @Prop({type:Function}) isComplete?:Function
    uploadFlow = singleUploadFlow
    flowComplete:boolean = false
    uploadError:boolean = false

    // Whena  component is mointed
    beforeMount(){
      if (this.short){
        this.uploadFlow = shortUploadFlow;
      } else if(this.uploadOnly){
        this.uploadFlow =  uploadOnlyFlow
      }
        if(this.flowSpecTransform) this.uploadFlow = this.flowSpecTransform(this.uploadFlow)
    }




    get uploadRunning(){
        return this.upload.running && !this.flowComplete
    }
    set uploadRunning(x){ this.upload.running =x }


    onStepChange($event:StepChangeEvent<UploadFlowInput>){
        let {activity,flow,on} = $event;
        switch(activity.id){
            case "upload":
                this.trackUpload(flow,on);

        }
    }
    //Actually run the upload page
    async trackUpload(flow:Flow,on:any){
        let {jobId} = this.upload
        this.uploadError = false;
        let job = await this.waitForJob(jobId!)
        if(job.error){
            let skip = false;
            function preventDefault() { skip = true }
            this.$emit("upload-fail", { job, flow, preventDefault })
            if (skip) return
            this.$notifier.error("Document upload failed!");
            this.uploadError = true;
            flow.toActivity("basic-info")
            return
        }
        //Fire change event
        on.change(job.document!)
        flow.toActivity(true)

    }
    async startUploadDirectly(flow:Flow,payload:UploadFlowInput):Promise<UploadJob>{
        let {upload} = this;
        let {file,universe,...inputOnly} = payload
        this.upload.start()
        let resp =await this.startUpload({input:inputOnly,universe,file:upload.input!.file})
        upload.jobId = resp
        return  await this.getUploadJob(resp);
    }

    async validateAndStartUpload(flow:Flow,on:any,_:UploadFlowInput){

            let skip= false;
            function preventDefault(){skip = true }
            let run = async () => {
                skip=false;
                try {
                    let job = await this.startUploadDirectly(flow,this.upload.input!);
                    if(job.error){
                        this.$emit("upload-fail",{job,flow,preventDefault})
                        if(skip) return
                        this.upload.maybeHandleError(job.error)
                        flow.toActivity("basic-info")
                        this.upload.stop();
                        return
                    }
                    await flow.toActivity(true)
                }catch(e){
                    let skip= false;
                    function preventDefault(){skip = true }
                    this.$emit("upload-fail",{flow,preventDefault})
                    if(skip) return;
                    console.log("Upload Start Failed",e)
                    this.$notifier.error("Unable to start upload");
                }
            }
            this.$emit("upload-begin",{
                flow, upload:this.upload.input,
                preventDefault,run
            })
            if(!skip) return run()
    }



    /**
     * Run the Upload only process
     */
    async runUploadOnly(flow:Flow){
        let job:UploadJob|undefined = undefined
        let handleFailure=(job:UploadJob|null,err?:Error) => {
            let skip = false;
            function preventDefault(){skip=true; }
            this.$emit("upload-fail",{job,flow,preventDefault,error:err})
            if(skip) return
            if(job && job.error) this.upload.maybeHandleError(job.error)
            this.$notifier.error("Upload failed");
        }
        try {
            let job = await this.startUploadDirectly(flow,this.upload!.input!)
            if(job.error){
                handleFailure(job)
                return
            }
            this.flowComplete = true;
            await this.$nextTick()
            this.onComplete(job);
        }catch(err){
            handleFailure(null,err as any)
        }
    }


    async updateDocumentLinks(flow:Flow,on:any,_:UploadFlowInput){
        let {upload} = this
        //UploadSubModel is an Editor so we will update the mdoel in it
        upload.model = upload.input;
        let resp =await upload.doUpdate(upload.input!.uuid!).catch(err => err)
        if(resp instanceof Error){
            //TODO: errors  should be handled via "editro" mixin of upload object
            return
        }
        await flow.toActivity(true)
    }

    /**
     * In Upload only we will start the upload as soon as the flow renders
     */
    onReady(flow:Flow){
        if(this.uploadOnly){
           this.runUploadOnly(flow)
        }

    }

    async triggerComplete(){
      let job:UploadJob|undefined = undefined
      if(this.upload && this.upload.jobId){
        job = await this.getUploadJob(this.upload.jobId)
      }
      this.onComplete(job)
    }


    @Emit('complete')
    onComplete(job:UploadJob|undefined){ }

    @Emit('cancel')
    onCancel(){ }

    @Emit('cancel')
    onClickOut(){}


}
