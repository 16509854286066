/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $FileInlineInput = {
    description: `Input data imported to a file `,
    properties: {
        name: {
            type: 'string',
            isRequired: true,
        },
        size: {
            type: 'number',
            isRequired: true,
        },
        id: {
            type: 'string',
            isRequired: true,
            pattern: '[0123456789ABCDEFGHJKMNPQRSTVWXYZ]{26}',
        },
    },
} as const;
