/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ValuationResponse = {
    properties: {
        instrument_id: {
            type: 'string',
            isRequired: true,
            format: 'uuid',
        },
        amount: {
            type: 'any-of',
            contains: [{
                type: 'number',
            }, {
                type: 'number',
            }],
            isRequired: true,
        },
        price: {
            type: 'any-of',
            contains: [{
                type: 'number',
            }, {
                type: 'number',
            }],
            isRequired: true,
        },
        exact_date: {
            type: 'string',
            isRequired: true,
            format: 'date-time',
        },
    },
} as const;
