/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum TickType {
    _ = '*',
    BID = 'BID',
    ASK = 'ASK',
    TRD = 'TRD',
    MID = 'MID',
    COMP = 'COMP',
    LAST = 'LAST',
    OBS = 'OBS',
}
