/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $MailboxRequest = {
    description: `Mailbox Request `,
    properties: {
        email: {
            type: 'string',
            isRequired: true,
            format: 'email',
        },
    },
} as const;
