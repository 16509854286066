import {PartialSchemaSet} from "~/utils/schema_funcs"
import { ObservableTypes } from "./gen"
export const FlatSchemaKey = Symbol("transaction_schema.flat_schema")


export const FLOW_ANNOTATION='x-flow'
export const DETAILS_ANNOTATION = 'x-detail'
export const IMPORT_ANNOTATION ="x-import"


export interface ImportAnnotation {
    required?:boolean
    disabled?:boolean
    //This field is only avaialble for an advisor
    advisor?:boolean
    defaultValue?:any|(() => Promise<any>)
    //Alternate lable for the import context 
    label?:string
    props?:Record<string,any>
    overrides?:PartialSchemaSet
    //THis is a date
    localDate?:boolean
    // This is a mapping taht should be evaluated when we are dealing with a 
    // "working" expression
    preWorking:boolean
}

export interface ObservableImportAnnotation extends ImportAnnotation {
    allowed_types?:ObservableTypes[]
}
