/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ApiFirmMember = {
    properties: {
        uuid: {
            properties: {
            },
            isRequired: true,
        },
        email: {
            type: 'string',
            isRequired: true,
        },
        role: {
            type: 'AccessGrantRole',
            isRequired: true,
        },
        meta: {
            type: 'ApiFirmMemberMeta',
        },
        user_id: {
            type: 'string',
            format: 'uuid',
        },
        enabled: {
            type: 'boolean',
            isRequired: true,
        },
    },
} as const;
