/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FundFlowClauseDetails } from './FundFlowClauseDetails';
import type { ShortExpenseDeclaration } from './ShortExpenseDeclaration';
import type { ShortFeeDeclaration } from './ShortFeeDeclaration';

export type CapitalCommitmentDetails = {
    detail_type?: CapitalCommitmentDetails.detail_type;
    payable_unit_id?: string;
    fund_id?: string;
    fees_short?: ShortFeeDeclaration;
    expenses_short?: ShortExpenseDeclaration;
    waterfall?: Array<FundFlowClauseDetails>;
    underlying_equity_id?: string;
    committed_capital?: number;
};

export namespace CapitalCommitmentDetails {

    export enum detail_type {
        CAPC = 'CAPC',
    }


}

