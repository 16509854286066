/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $InstrumentTriple = {
    description: `Instrument Triple is the unique parts of an
    instrument name.
    The Parts are <identifier_type>:<instrument_type>:<identifier>`,
    properties: {
        instrument_type: {
            type: 'any-of',
            contains: [{
                type: 'InstrumentType',
            }, {
                type: 'Enum',
            }],
            isRequired: true,
        },
        identifier_type: {
            type: 'any-of',
            contains: [{
                type: 'IdentifierType',
            }, {
                type: 'Enum',
            }],
            isRequired: true,
        },
        identifier: {
            type: 'string',
            isRequired: true,
        },
    },
} as const;
