/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ObservableSuggestionType {
    ORIGIN = 1,
    ONLINE = 2,
    EXISTING = 3,
}
