/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ReportAggrCol = {
    properties: {
        func: {
            type: 'string',
            isRequired: true,
        },
        column: {
            type: 'string',
            isRequired: true,
        },
        if: {
            type: 'string',
        },
        args: {
            type: 'array',
            contains: {
                properties: {
                },
            },
        },
        kwargs: {
            properties: {
            },
        },
    },
} as const;
